import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TableSchema } from '../../../utils/table-schema';
import { DataService } from '../../../data.service';
import { ApiService } from '../../../shared/api.service';
import { DefaultService } from '../../../utils/default.service';
import { CcassService } from '../../ccass.service';
import { SIDE_NAVE_TITLES } from '../../utils/util-constants';
import { PrivilegeService } from '../../../shared/privilege.service';

@Component({
  selector: 'app-sales-team',
  templateUrl: './sales-team.component.html',
  styleUrls: ['./sales-team.component.scss']
})
export class SalesTeamComponent implements OnInit {

  pageTitle: string = SIDE_NAVE_TITLES.salesTeam;

  @Output() titleHeading = new EventEmitter<string>();
  @Output() shouldDisplayTitle = new EventEmitter<boolean>();

  tableColumns = TableSchema['sales-team'];
  loading = false;
  salesTeamData: any = [];
  selectedRow: any;
  setDialogName: string;
  btnName: string;
  viewAddSalesTeam: boolean;
  headerSelectOptions: any = {}
  onClickDelete: boolean;
  setIndex: any;
  currentRow: any;

  showAlertPopup = false
  message: string;

  canAddSalesTeam = { 'screenName': 'CCASS', 'subModule': 'canAddSalesTeam' };

  

  constructor(
    private ccassService: CcassService,
    private apiService: ApiService,
    private defaultService: DefaultService,
    private messageService: MessageService,
    private dataService: DataService,
    public privilegeService: PrivilegeService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.titleHeading.emit(this.pageTitle);
    this.shouldDisplayTitle.emit(false);
    this.getSalesTeamData()
  }


  getSalesTeamData() {
    const response = this.dataService.getSalesTeamData()
    const url = 'v1/teams'
    this.ccassService.ccassfindOne('', url).then(response => {
      if (response['status'] && response['status'] === 'success') {
        if(response['teams'] && response['teams'] !== null) {
        this.salesTeamData = response['teams'];
        this.setHeaderSelectOptions(response['teams']) 
        } else {
          this.salesTeamData = []
          this.setHeaderSelectOptions([]) 
        }
      } else {
        this.messageService.add({ severity: 'error', detail: response['status'] });
        this.loading = false;
        // handle failure
      }
    }).catch(err => {
      if (err.status == 500) {
        this.messageService.add({ severity: 'error', detail: err['statusText'] ? err['statusText'] : 'Internal server error' });
      } else if (err.status == 502) {
        this.messageService.add({ severity: 'error', detail: err.status ? err.status + 'Bad Gateway' : '502 Bad Gateway' });
      } else {
        this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
      }
      this.loading = false;
      // handle error
    });
  }

  onAddSalesTeam() {
    this.selectedRow = undefined;
    this.setDialogName = 'ADD';
    this.btnName = 'SAVE';
    this.viewAddSalesTeam = true;
  }

  setHeaderSelectOptions(data: any) {
    let nameOptions = [{ label: 'All', value: null }]
    if (data.length > 0) {
      data?.forEach((d: any) => {
        if (d.teamName) { nameOptions.push({ label: d.teamName, value: d.teamName }); }
      })
      const uniqueFilenameOptions = [...new Set(nameOptions.map(item => item.label))];
      nameOptions = [];
      uniqueFilenameOptions?.forEach((element: any) => nameOptions.push({ label: element, value: element == 'All' ? null : element }));
      this.headerSelectOptions['teamName'] = nameOptions;
      this.loading = false
    } else {
      this.headerSelectOptions['teamName'] = nameOptions;
      this.loading = false
    }
  }

  editSalesTeam(event: any) {
    this.selectedRow = event['row'];
    this.setDialogName = 'EDIT';
    this.btnName = 'UPDATE';
    this.viewAddSalesTeam = true;
  }

  deleteSalesTeam(event: any) {
    this.currentRow = event.row;
    this.onClickDelete = true;
    this.selectedRow = event.row;
    this.setIndex = event.index;
  }

  onClickYes() {
    const url = 'v1/teams/delete'
    this.ccassService.delete(this.selectedRow['id'], url).then(data => {
      if (data['status'] === 'success') {
        const message = data['message']
        this.shoeerrorpopup({message})
      } else {
        this.messageService.add({ severity: 'error', detail: data['message'] ? data['message'] : data['status'] });
        this.loading = false;
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
      this.loading = false;
    })
  }

  shoeerrorpopup(event:any) {
    this.showAlertPopup = true
    this.message = event.message
  }

  onClickok() {
    this.getSalesTeamData()
  }

}
