import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DataService } from '../../../../app/data.service';
import { ApiService } from '../../../shared/api.service';
import { DefaultService } from '../../../utils/default.service';
import { noSpace } from '../../../utils/utilities.function';
import { CcassService } from '../../ccass.service';

@Component({
  selector: 'app-add-sales-team',
  templateUrl: './add-sales-team.component.html',
  styleUrls: ['./add-sales-team.component.scss']
})
export class AddSalesTeamComponent implements OnInit {

  noSpace = noSpace

  @Input() isDisplay = false;

  @Input() selectedRow: any;
  @Input() dialogName: any;
  @Input() btnName: any;
  @Output() isDisplayChange = new EventEmitter<boolean>()
  @Output() getSalesTeamData = new EventEmitter();
  @Output() shoepopupInParent = new EventEmitter();
  salesTeamForm: FormGroup;

  loading: boolean;

  salesTeam:any = {}


  constructor(
    private ccassService: CcassService,
    private apiService: ApiService,
    private defaultService: DefaultService,
    private messageService: MessageService,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.loadsalesTeamForm()
    if(this.selectedRow) {
      this.salesTeam['team'] = this.selectedRow['teamName']
    }
  }


  loadsalesTeamForm() {
    this.salesTeamForm = new FormGroup({
      "team": new FormControl('',Validators.required),
    })
  }

  resetNclose() {
    this.isDisplay = false;
    // this.getSalesTeamData.emit();
    this.isDisplayChange.emit(this.isDisplay); 
  }

  resetNcloseFRSuccess(message:any) {
    this.isDisplay = false;
    this.shoepopupInParent.emit({message});
    this.isDisplayChange.emit(this.isDisplay); 
  }

  addSalesTeam() {
    const formData = { ...this.salesTeamForm.value };
    const form = {}
    form['teamName'] = formData['team']
    if (this.btnName === 'SAVE') {
      const url = 'v1/teams/create'
      this.ccassService.ccassPost(form,url).then(data => {
          if(data['status'] === 'success') {
            this.resetNcloseFRSuccess(data['message']);
          } else {
            this.messageService.add({ severity: 'error', detail: data['message'] ? data['message'] : data['status'] });
            this.loading = false;
          }
        }).catch(err => {
          this.resetNclose();
          this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
          this.loading = false;
        })
    } else {
      const url = 'v1/teams/update'
      form['id'] =  this.selectedRow['id']
      this.ccassService.ccassUpdateTeam(form,url).then(data => {
        if(data['status'] == 'success') {
          this.resetNcloseFRSuccess(data['message']);
        } else {
          this.messageService.add({ severity: 'error', detail: data['message'] ? data['message'] : data['status'] });
          this.loading = false;
        }
      }).catch(err => {
        // this.resetNclose();
        this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
        this.loading = false;
      })
    }
  }
}
