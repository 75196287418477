import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SIDE_NAVE_TITLES } from '../../utils/util-constants';
import { convertToSnakeCase } from '../../utils/util-functions';
import { salesPersonMappingObject, teamObject, salesPersonObject } from '../../utils/util-interfaces';
import { CcassService } from '../../../ccass/ccass.service';

@Component({
  selector: 'app-sales-person-mapping',
  templateUrl: './sales-person-mapping.component.html',
  styleUrls: ['./sales-person-mapping.component.scss']
})
export class SalesPersonMappingComponent implements OnInit {

  @Input() listOfTeams: teamObject;
  @Input() listOfSalesperson: salesPersonObject[];

  @Output() titleHeading = new EventEmitter<string>();
  @Output() shouldDisplayTitle = new EventEmitter<boolean>();

  pageTitle: string = SIDE_NAVE_TITLES.salespersonMapping;
  loading = false;
  showAlertPopup = false;
  message: string;

  listOfSalespersonMapping: salesPersonMappingObject;

  salespersonMappingKey = convertToSnakeCase(SIDE_NAVE_TITLES.salespersonMapping);

  constructor(private ccassService: CcassService) { }

  ngOnInit() {
    this.titleHeading.emit(this.pageTitle);
    this.shouldDisplayTitle.emit(false);
    this.getSalespersonData()
    this.getSalesTeamData()
    this.getSalespersonMapping();
  }

  // api function call to add new record
  addSalespersonMapping(data) {
    this.loading = true;
    const query = {};
    query['teamId'] = data.teamId;
    query['salespersonName'] = data.salespersonName;
    query['salespersonId'] = data.salesPersonId;
    this.ccassService.ccassPost(query, 'v1/salesperson-mapping').then(response => {
      if (response['status'] && response['status'] === 'success') {
        this.loading = false;
        this.showWarningPopup(response['message'] );
        this.getSalespersonMapping();
      } else {
        this.loading = false;
        this.showWarningPopup(response['status'] );
      }
    }).catch(err => {
      this.loading = false;
      const error = JSON.parse(err._body);
      // TODO: Change the way error msg is displayed
      if (error['status'] && error['status'] === 'salesperson mapping already exists' ) {
        this.showWarningPopup(error['status']);
      } else {
        this.showWarningPopup(error['status']);
      }
    });
  }

  // api function call to get list of mapping records
  getSalespersonMapping() {
    this.loading = true;
    this.ccassService.ccassfindOne('', 'v1/salesperson-mapping').then(response => {
      if (response['status'] && response['status'] === 'success') {
        this.listOfSalespersonMapping = response['salespersonMapping'];
        this.loading = false;
      } else {
        this.showWarningPopup('Data not found');
      }
    }).catch(err => {
      this.loading = false;
      this.showWarningPopup('Data not found');
    });
  }

  // api function call to remove salesperon from list
  removeSalespersonMapping(data) {
    this.loading = true;
    this.ccassService.delete(data.mappingId, 'v1/salesperson-mapping').then(response => {
      if (response['status'] && response['status'] === 'success') {
        this.loading = false;
        this.showWarningPopup(response['message']);
        this.getSalespersonMapping();
      } else {
        this.loading = false;
        this.showWarningPopup(response['status']);
      }
    }).catch(e => {
      this.loading = false;
      const error = JSON.parse(e._body);
      // TODO: Change the way error msg is displayed
      this.showWarningPopup(error['status']);
    });
  }

  // api function call to update records
  updateSalespersonMapping(data) {
    this.loading = true;
    const query = {};
    query['mappingId'] = data.mappingId;
    query['teamId'] = data.teamId,
    query['teamName'] = data.teamName,
    query['salespersonName'] = data.salespersonName,
    query['salespersonId'] = parseInt(data.salesPersonId);
    this.ccassService.ccassPost(query, 'v1/salesperson-mapping/update').then(response => {
      if (response['status'] && response['status'] === 'success') {
        this.loading = false;
        this.showWarningPopup(response['message']);
        this.getSalespersonMapping();
      } else {
        this.loading = false;
        this.showWarningPopup(response['status']);
      }
    }).catch(err => {
      this.loading = false;
      const error = JSON.parse(err._body);
      // TODO: Change the way error msg is displayed
      this.showWarningPopup(error['status']);
    });
  }

  saveSalesperson(value) {
    this.addSalespersonMapping(value);
  }

  updateSalesperson(value) {
    this.updateSalespersonMapping(value);
  }

  selectedRecordToDelete(value) {
    this.removeSalespersonMapping(value);
  }

  showWarningPopup(message) {
    this.showAlertPopup = true;
    this.message = message;
  }

  getSalespersonData() {
    this.ccassService.ccassfindOne('', 'v1/salespersons').then(response => {
      if (response['status'] && response['status'] === 'success') {
        if(response['salesPersons'] && response['salesPersons'] !== null) {
          this.listOfSalesperson = response['salesPersons'];
        } else {
        }
      } else {
        // handle failure
      }
    }).catch(err => {
      // handle error
    });
  }

  getSalesTeamData() {
    const url = 'v1/teams'
    this.ccassService.ccassfindOne('', url).then(response => {
      if (response['status'] && response['status'] === 'success') {
        if(response['teams'] && response['teams'] !== null) {
        this.listOfTeams = response['teams'];
        } else {

        }
      } else {
        // this.loading = false;
        // handle failure
      }
    }).catch(err => {
      // handle error
    });
  }
}
