import { clientObject, salesPersonObject, rollingPeriodType, teamObject, tickerObject, issuerObj } from './util-interfaces';

// Shareholding Analysis
const DEFAULT_TICKER_OBJECT: tickerObject = {
    currencyName: 'HKD',
    fundId: 33,
    id: 13,
    tickerName: '3033',
    issuerId: 26
};
const DEFAULT_COMPARING_OBJECT: tickerObject[] = [
    {currencyName: 'HKD', fundId: 84, id: 7, issuerId: 15, tickerName: '2823'},
    {currencyName: 'HKD', fundId: 79, id: 82, issuerId: 12, tickerName: '2828'},
    {currencyName: 'HKD', fundId: 21, id: 26, issuerId: 27, tickerName: '3188'},
    {currencyName: 'HKD', fundId: 91, id: 16, issuerId: 15, tickerName: '3067'},
    {currencyName: 'HKD', fundId: 80, id: 12, issuerId: 12, tickerName: '3032'}
];
const INIT_TICKER_OBJECT: tickerObject = {
    currencyName: '',
    fundId: 0,
    id: 0,
    tickerName: 'All',
    issuerId: 0
};

const DEFAULT_PARTICIPANT_OBJECT: clientObject = {
    id: 0,
    pId: 'ALL',
    participantName: 'All'
};
const DEFAULT_SALESPERSON_OBJECT: salesPersonObject = {
    id: 0,
    salespersonName: 'All'
};

const DEFAULT_TEAM_OBJECT: teamObject = {
    id: 0,
    teamName: 'All'
};

const DEFAULT_ISSUER_OBJECT: issuerObj = {
    id: 0,
    issuerName: 'All'
};

const TICKER_TITLE = {
    baseTicker: 'BASE TICKER',
    compareTicker1: 'COMPARING TICKER 1',
    compareTicker2: 'COMPARING TICKER 2'
};

const ROLLING_PERIOD_TYPES: rollingPeriodType[] = [
    { id: 0, name: 'Custom'},
    { id: 1, name: 'Month'},
    { id: 2, name: 'Year'}
];

const SIDE_NAVE_TITLES = {
    shareholdingAnalysis: 'Shareholding Analysis',
    salesOverview: 'Sales Overview',
    aumAnalysis: 'AUM Analysis',
    topCreatorsReedemers: 'Top Creators and Redeemers',
    clientMapping: 'Client Mapping',
    salespersonMapping: 'Salesperson Mapping',
    uploadShareHoling: 'Upload Shareholdings',
    salesPerson: 'Salesperson',
    salesTeam: 'Sales Team',
    participantDealer: 'Participant Dealer',
    funds:'Funds'
};

const NULL_VALUE_STRING = '--';

const SHAREHOLDING_CHART_LABELS = {
    base: 'Shareholdings (In Million)',
    compare: 'Comparison (In %)'
};

const ERROR_MSG = {
    emptyRecords: 'Records not found',
    errorRecords: 'Unable to fetch records'
};

// top creator redeemers
const DEFAULT_TCR_OBJECT = {
    heading_shareholdings: 'Shareholdings',
    heading_aum: 'Assets Under Management'
};

const TCR_KEY = {
    creator: 'creator',
    redeemer: 'redeemer'
};

export {
    DEFAULT_TICKER_OBJECT,
    INIT_TICKER_OBJECT,
    TICKER_TITLE,
    ROLLING_PERIOD_TYPES,
    SIDE_NAVE_TITLES,
    NULL_VALUE_STRING,
    DEFAULT_PARTICIPANT_OBJECT,
    DEFAULT_SALESPERSON_OBJECT,
    DEFAULT_TEAM_OBJECT,
    DEFAULT_ISSUER_OBJECT,
    SHAREHOLDING_CHART_LABELS,
    ERROR_MSG,
    DEFAULT_COMPARING_OBJECT,
    DEFAULT_TCR_OBJECT,
    TCR_KEY
};
