import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DataService } from '../../../data.service';
import { ApiService } from '../../../shared/api.service';
import { DefaultService } from '../../../utils/default.service';
import { noSpace } from '../../../utils/utilities.function';
import { CcassService } from '../../ccass.service';

@Component({
  selector: 'app-add-client-mapping',
  templateUrl: './add-client-mapping.component.html',
  styleUrls: ['./add-client-mapping.component.scss']
})
export class AddClientMappingComponent implements OnInit {

  noSpace = noSpace

  @Input() isDisplay = false;

  @Input() selectedRow: any;
  @Input() dialogName: any;
  @Input() btnName: any;

  @Output() isDisplayChange = new EventEmitter<boolean>()
  @Output() shoepopupInParent = new EventEmitter();
  @Output() getClientMappingData = new EventEmitter();

  loading: boolean;
  mappingForm: FormGroup;
  mapping: any = {}
  listOfClients: any = []
  clientlist: any = []
  clients : any = []
  salespersonsList: any = []
  listOfsalesperson: any = []
  salespersons : any = []

  constructor(
    private ccassService: CcassService,
    private apiService: ApiService,
    private defaultService: DefaultService,
    private messageService: MessageService,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.loadMappingForm()
    this.getClientList()
    this.getClienSalesPersonList()
    if(this.selectedRow) {
      this.mapping['name'] = this.selectedRow.participantName
      this.mapping['participant'] = this.selectedRow.pId
      this.mapping['salesperson'] = this.selectedRow.salespersonName
      this.mapping['team'] = this.selectedRow.teamName
      this.mapping['participantId'] = this.selectedRow.participantId
      this.mapping['salespersonId'] = this.selectedRow.salespersonId
    }
  }

  loadMappingForm() {
    this.mappingForm = new FormGroup({
      "name": new FormControl('', Validators.required),
      "participant": new FormControl(''),
      "salesperson": new FormControl('', Validators.required),
      "team": new FormControl(''),
    })
  }

  getClientList() {
    const url = 'v1/participants'
    this.ccassService.ccassfindOne('', url).then(response => {
      if (response['status'] && response['status'] === 'success') {
        // this.listOfClients = response['participants'];
        if (response['participants'] && response['participants'].length > 0) {
          this.clients = response['participants']
          response['participants'].forEach((obj: any) => {
            this.listOfClients.push({ label: obj['participantName'], value: obj['participantName'] });
            this.clientlist = this.listOfClients
          });
        }
      } else {
        // handle failure
      }
    }).catch(err => {
      // handle error
    });
  }

  getClienSalesPersonList() {
    const url = 'v1/salesperson-mapping/salespersonlist'
    this.ccassService.ccassfindOne('', url).then(response => {
      if (response['status'] && response['status'] === 'success') {
        // this.salespersonsList = response['data'];
        if(response['data'] && response['data'].length > 0) {
          this.salespersons = response['data']
          response['data'].forEach((obj: any) => {
            this.listOfsalesperson.push({ label: obj['salespersonName'], value: obj['salespersonName'] });
            this.salespersonsList = this.listOfsalesperson
          });
        }
      } else {
        // handle failure
      }
    }).catch(err => {
      // handle error
    });
  }

  resetNclose() {
    this.isDisplay = false;
    this.isDisplayChange.emit(this.isDisplay);
  }

  resetNcloseFRSuccess(message: any) {
    this.isDisplay = false;
    this.shoepopupInParent.emit({ message });
    this.isDisplayChange.emit(this.isDisplay);
  }

  onChangeName(event: any) {
    this.clients.forEach((obj: any) => {
      if(obj.participantName == event.value) {
        this.mapping.participant = obj.pId
        this.mapping.participantId = obj.id
      }
    })
  }
// teamName
  onChangeSalesperson(event: any) {
    this.salespersons.forEach((obj: any) => {
      if(obj.salespersonName== event.value) {
        this.mapping.team = obj.teamName
        this.mapping.salespersonId = obj.id
      }
    })
  }

  addMapping() {
    const formData = { ...this.mappingForm.value };
    const form = {}
    
    form['participantName'] = formData['name']
    form['participantId'] = this.mapping['participantId']
    form['salespersonId'] = this.mapping['salespersonId']

    if (this.btnName === 'SAVE') {
      const url = 'v1/client-mapping'
      this.ccassService.ccassPost(form,url).then(data => {
        if(data['status'] === 'success') {
          this.resetNcloseFRSuccess(data['message']);
        } else {
          this.messageService.add({ severity: 'error', detail: data['message'] ? data['message'] : data['status'] });
          this.loading = false;
        }
      }).catch(err => {
        this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
        this.resetNclose();
        this.loading = false;
      })
    } else if(this.btnName === 'UPDATE') {
      const url = 'v1/client-mapping/update'
      form['mappingId'] = this.selectedRow.mappingId
      this.ccassService.ccassUpdateTeam(form,url).then(data => {
        if(data['status'] == 'success') {
          this.resetNcloseFRSuccess(data['message']);
        } else {
          this.messageService.add({ severity: 'error', detail: data['message'] ? data['message'] : data['status'] });
          this.loading = false;
        }
      }).catch(err => {
        this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
        this.resetNclose();
        this.loading = false;
      })
    }
  }

}
