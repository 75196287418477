import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FilterMatchMode, PrimeNGConfig } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { PrivilegeService } from '../../../shared/privilege.service';
import { thousands_separators, thousands_separatorsd } from '../../../utils/utilities.function';


@Component({
  selector: 'app-liquidity-table',
  templateUrl: './liquidity-table.component.html',
  styleUrls: ['./liquidity-table.component.scss']
})
export class LiquidityTableComponent implements OnInit {

  date: any;
	tableType: any;

	@Input() tableSchema: any;
	@Input() selectedRows: any;

	@Input() emptyMessage: any;
	@Input() cols: any;
	@Input() value: any;
	@Input() scroll: any;
	@Input() resizableColumns: any;
	@Input() filterDropdown: any;
	@Input() inlineEdit: any;
	@Input() themes: any;
	@Input() disableDelete: any;
	@Input() headerSelectOptions: any;
	@Input() isLoading: boolean;
	@Input() disableDlt: any
   @Output() viewMore = new EventEmitter()

	@Input() fundList: any;
	@Input() canDisplay: any;
	@Input() canExport: any;
	@Input() canHideExportPdf: any;
	@Input() exportCSVFileName: any;
	copyTableData: any = [];
	rowGroupMetadata: any = {};
	audio: any;
	clickPlay: boolean;
	audioEnd: boolean;
	playIndex: string;
	@ViewChild(Calendar, { static: false }) calendar: Calendar;
	checked: boolean;
	@Input() canEdit: boolean;
	@Input() type: any

	
	checkBoxSelection: any;
	pageIndex: any;
	isChecked: boolean
	target: any;
	check: any;
	customers: any = []

  constructor(
    public privilegeService: PrivilegeService,
    private config: PrimeNGConfig
  ) { }

  ngOnInit(): void {
    this.tableSchema['rowsCount'] = this.tableSchema?.rowsCount || 10;
    if (this.canDisplay === undefined) {
      this.canDisplay = true;
    }
    this.isChecked = false
    this.checked = false
    this.copyTableData = [...this.value];
      if (this.value?.length > 0) {
         this.updateRowGroupMetaData(this.value);
      }
  }

  updateRowGroupMetaData(data: any) {
    if(this.type == 'liquidation_cost') {
      this.rowGroupMetadata = {};
       if (data) {
          for (let i = 0; i < data.length; i++) {
             const rowData = data[i];
             const typeVal = rowData.securityClassification;
             if (i == 0) {
                this.rowGroupMetadata[typeVal] = { index: 0, size: 1 };
             } else {
                const previousRowTypeVal = data[i - 1];
                const previousRowGroupTypeVal = previousRowTypeVal.securityClassification;
                if (typeVal === previousRowGroupTypeVal) {
                   this.rowGroupMetadata[typeVal].size++;
                } else {
                   this.rowGroupMetadata[typeVal] = { index: i, size: 1 };
                }
             }
          }
       }
    } else if(this.type == 'liquidity_profile_nonmmf') {
      this.rowGroupMetadata = {};
       if (data) {
          for (let i = 0; i < data.length; i++) {
             const rowData = data[i];
             const typeVal = rowData.securityClassification;
             if (i == 0) {
                this.rowGroupMetadata[typeVal] = { index: 0, size: 1 };
             } else {
                const previousRowTypeVal = data[i - 1];
                const previousRowGroupTypeVal = previousRowTypeVal.securityClassification;
                if (typeVal === previousRowGroupTypeVal) {
                   this.rowGroupMetadata[typeVal].size++;
                } else {
                   this.rowGroupMetadata[typeVal] = { index: i, size: 1 };
                }
             }
          }
       }
    } else if(this.type == 'liquidity_profile_mmf') {
      this.rowGroupMetadata = {};
       if (data) {
          for (let i = 0; i < data.length; i++) {
             const rowData = data[i];
             const typeVal = rowData.country;
             if (i == 0) {
                this.rowGroupMetadata[typeVal] = { index: 0, size: 1 };
             } else {
                const previousRowTypeVal = data[i - 1];
                const previousRowGroupTypeVal = previousRowTypeVal.country;
                if (typeVal === previousRowGroupTypeVal) {
                   this.rowGroupMetadata[typeVal].size++;
                } else {
                   this.rowGroupMetadata[typeVal] = { index: i, size: 1 };
                }
             }
          }
       }
    }
    this.isLoading = false;
 }

  

  getStyleClass(col, rowData) {
    let className;
    col.styleProps.forEach(element => {
      if (element.checkValue === rowData[col.field]) {
        className = element.class;
      }
    });
    return className;
  }

 

 

  onSelectRow() {
    
  }

  thousandSeparators(value) {
    return thousands_separators(value);
  }
  onRowUnselect(row) {
   
  }
  selectAllRows() {
   
  }

  onEditInit(event): void {
    
  }

  onEditCancel(): void {

  }

  onEditComplete(event: any) {
    
  }

  onClickViewMore(row, index, field) {
   this.viewMore.emit({ row: row, index: index });
  }

  getAbsValue(value) {
   const x = value  ? value * 100 : 0
   return x;
}
}
