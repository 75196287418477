export const PageConfig = {
	'pages': [
		// A
		{
			'label': 'ACCOUNT CONFIG',
			'routerLink': '/account-config',
			'priv': 'ACCOUNT_CONFIG'
		},
		{
			'label': 'ACCOUNT MAPPING',
			'routerLink': '/accountmapping',
			'priv': 'ACCOUNT_MAPPING'
		},
		{
			'label': 'ASSET CLASS CONFIG',
			'routerLink': '/Asset-Class-config',
			'priv': 'ASSET_CLASS_CONFIG'
		},
		{
			'label': 'AUDIT TRAIL',
			'routerLink': '/audittrail',
			'icon': 'fa fa-user',
			'priv': 'AUDIT_TRAIL'
		},
		// C
		{
			'label': 'CA CONFIG',
			'routerLink': '/caconfig',
			'priv': 'CA_CONFIG'
		},
		{
			'label': 'CASE DETAILS',
			'routerLink': '/case/:id',
			'icon': 'fa fa-users',
			'priv': 'CASE_DETAILS_VIEW'
		},
		{
			'label': 'CODE CONFIG',
			'routerLink': '/code-config',
			'priv': 'CODE_CONFIG'
		},
		// D
		{
			'label': 'Dashboard',
			'routerLink': '/ccass',
			'priv': 'DASHBOARD_CC_IFRAME'
		},
		{
			'label': 'D&C CONFIG',
			'routerLink': '/dc-config',
			'priv': 'DC_CONFIG'
		},
		{
			'label': 'DATA MARKET PLACE',
			'routerLink': '/datamarketplace',
			'priv': 'DATA_MARKET_PLACE'
		},
		{
			'label': 'DAILY C/R ORDERS MANAGEMENT',
			'routerLink': '/dailycrordrsmgmt',
			'priv': 'DAILY_CR_ORDERS_MGMT'
		},
		// E
		{
			'label': 'EMAIL GENERATION',
			'routerLink': '/emailgeneration',
			'priv': 'EMAIL_GENERATION'
		},
		{
			'label': 'ENUM CONFIG',
			'routerLink': '/enum-config',
			'priv': 'ENUM_CONFIG'
		},
		// F
		{
			'label': 'FINANCE AGGREGATE',
			'routerLink': '/finance',
			'priv': 'FINANCE_AGGREGATE'
		},
		{
			'label': 'FIRM LEVEL LIQUIDITY',
			'routerLink': '/firmliquidation',
			'priv': 'FIRM_LEVEL_LIQUIDITY'
		},
		{
			'label': 'FIRM LEVEL SUSPENDED STOCKS',
			'routerLink': '/suspendedstocks',
			'priv': 'FIRM_LEVEL_SUSPENDED_STOCK'
		},
		{
			'label': 'FLEX SYSTEM',
			'routerLink': '/flexfiles',
			'priv': 'FLEX_FILES'
		},
		{
			'label': 'FUNDS',
			'routerLink': '/managefunds',
			'icon': 'fa fa-user',
			'priv': 'MANAGE_FUNDS'
		},
		{
			'label': 'FX RATES',
			'routerLink': '/opsfxrate',
			'priv': 'OPS_FX_RATES'
		},
		// H
		{
			'label': 'HOLIDAY CALENDAR',
			'routerLink': '/holidaycalender',
			'priv': 'HOLIDAY_CALENDER'
		},
		// J
		{
			'label': 'JOBS',
			'routerLink': '/jobs',
			'icon': 'fa fa-users',
			'priv': 'MANAGE_JOBS',
		},
		{
			'label': 'JOBS(New)',
			'routerLink': '/jobsConfig',
			'icon': 'fa fa-users',
			'priv': 'MANAGE_JOBS',
		},	
		// K
		{
			'label': 'KYC SUMMARY',
			'routerLink': '/kycdashboard',
			'icon': 'fa fa-users',
			'priv': 'KYC_DASHBOARD'
		},
		// L
		// {
		// 	'label': 'Liquidation Cost Threshold Config',
		// 	'routerLink': '/liquidationcostthresholdconfig',
		// 	'priv': 'LIQ_COST_CONFIG'
		// },
		// {
		// 	'label': 'Liquidity Profile Threshold Config(Mmf)',
		// 	'routerLink': '/liquidityprofilemmfthresholdconfig',
		// 	'priv': 'LIQ_PROF_MMF'
		// },
		// {
		// 	'label': 'Liquidity Profile Threshold Config(Non-mmf)',
		// 	'routerLink': '/liquidityprofilenommmfthresholdconfig',
		// 	'priv': 'LIQ_PROF_NMMF'
		// },
		// M
		{
			'label': 'MAILS CONFIG',
			'routerLink': '/emailconfig',
			'priv': 'MAIL_CONFIG'
		},
		// P
		{
			'label': 'PARTIES',
			'routerLink': '/parties',
			'priv': 'PARTIES'
		},
		{
			'label': 'PORTFOLIO CODES',
			'routerLink': '/portfoliocodes',
			'priv': 'PORTFOLIOCODE_MAPPING'
		},
		{
			'label': 'PRICES',
			'routerLink': '/pricemaintainance',
			'priv': 'PRICES'
		},
		{
			'label': 'PEER FUNDS',
			'routerLink': '/peerfunds',
			'priv': 'MANAGE_PEER_FUND'
		},
		{
			'label': 'PREMIUM DISCOUNT',
			'routerLink': '/premiumdiscount',
			'priv': 'PREMIUM_DISCOUNT'
		},
		{
			'label': 'PREMIUM DISCOUNT SUMMARY',
			'routerLink': '/pdsummary',
			'priv': 'PREMIUM_DISCOUNT_SUMMARY'
		},
		// Q
		{
			'label': 'Quant FX RATES',
			'routerLink': '/fxrate',
			'priv': 'FX_RATE'
		},
		// R
		{
			'label': 'RECON CONFIGURATION',
			'routerLink': '/reconconfig',
			'priv': 'RECON_CONFIGURATION'
		},
		{
			'label': 'Reset Password',
			'routerLink': '/resetpassword',
			'icon': 'fa fa-user',
			'priv': 'RESET_PWD'
		},
		{
			'label': 'ROLES',
			'routerLink': '/roles',
			'icon': 'fa fa-users',
			'priv': 'MANAGE_ROLES',
		},
		// S
		{
			'label': 'SCHEDULER CONFIG',
			'routerLink': '/schedule',
			'icon': 'fa fa-users',
			'priv': 'SCHEDULER',
		},
		// T
		{
			'label': 'TD THRESHOLD',
			'routerLink': '/tdthreshold',
			'priv': 'TD_THRESHOLD'
		},
		// {
		// 	'label': 'TEMPLATE CONFIG',
		// 	'routerLink': '/newsletterconfig',
		// 	'priv': 'NEWSLETTER_CONFIG'
			
		// },
		{
			'label': 'TEMPLATE DESIGN',
			'routerLink': '/newsletters',
			'priv': 'NEWSLETTER_CREATE'
		},
		{
			'label': 'TEMPLATE MAPPING',
			'routerLink': '/newslettermapping',
			'priv': 'NEWSLETTER_MAPPING'
		},
		{
			'label': 'TEMPLATES FOR APPROVAL',
			'routerLink': '/requestedtemplates',
			'priv': 'NEWSLETTER_APPROVE'
		},
		{
			'label': 'TEMPLATE REPORTS',
			'routerLink': '/newsletterreport',
			'priv': 'NEWSLETTER_REPORTS'
		},
		{
			'label': 'TRANSACTIONS',
			'routerLink': '/quantdashboard',
			'priv': 'TRANSACTION_SUMMARY'
		},
		{
			'label': 'Transaction Cost CONFIG',
			'routerLink': '/transactionCostConfig',
			'priv': 'TRANSACTION_COST_CONFIG'
		},
		{
			'label': 'TRANSLATION DEFINITION',
			'routerLink': '/translation',
			'priv': 'TRANSLATION_DEFINITION'
		},
		// U
		{
			'label': 'USERS',
			'routerLink': '/users',
			'icon': 'fa fa-users',
			'priv': 'MANAGE_USERS',
		}, 
		{
			'label': 'USER GROUPS',
			'routerLink': '/groups',
			'icon': 'fa fa-user',
			'priv': 'MANAGE_GROUPS'
		},
		{
			'label': 'USER STATISTICS',
			'routerLink': '/userstats',
			'priv': 'USER_STATS'
		},
		{
			'label': 'UPLOADED FILES',
			'routerLink': '/uploadedfileslog',
			'priv': 'UPLOADED_FILES_LOG'
		},
		{
			'label': 'CREDIT RATING',
			'routerLink': '/credit-rating-capture',
			'priv': 'CREDIT_RATING'
		},
		

		
		
		
		

		// {
		// 	'label': 'HOLIDAY CALENDAR',
		// 	'routerLink': '/holidaycalender',
		// 	'priv': 'HOLIDAY_CALENDER'
		// },
		
		
		
		

		

		
		

		

		

		
		
		
		// {
		// 	'label': 'PARTIES',
		// 	'routerLink': '/marketmaker',
		// 	'priv': 'PARTIES'
		// },


		



		

		// {
		// 	'label': 'FUND DETAILS',
		// 	'routerLink': '/opsfunddetails',
		// 	'priv': 'FLEX_FILES'
		// }

			// New Screens

			
	]
};