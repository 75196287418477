import { Injectable } from '@angular/core';
import { Message } from './message';
import { FormField } from './formfield';
import { OCRField } from './ocrfield';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DefaultService } from './default.service';
import { FormsUtilitiesService } from './forms-utilities.service';
import { PrivilegeService } from '../shared/privilege.service';

@Injectable()
export class UtilitiesService {

  constructor() { }

  static signaturesMap = UtilitiesService.setNReturnSignatures();
  static ocrFieldsMapDetails = UtilitiesService.getOCRFields();
  static messageMap = UtilitiesService.getMessageMap();
  static formGroupMap = UtilitiesService.getFormGroupMap();
  static formFieldsToClearOnInpChng = UtilitiesService.getFormFieldsToClearOnInpChng();
  static assetClassTypeMap = UtilitiesService.getAssetClassTypes();
  static cashAndOthersMap = UtilitiesService.getCashAndOthersMap();
  static privilegeService = new PrivilegeService();

  static getAssetClassTypes(): Map<string, string> {
    const assetClassTypeMap = new Map<string, string>();
    assetClassTypeMap.set('EE', 'Equity');
    assetClassTypeMap.set('EM', 'Equity');
    assetClassTypeMap.set('BE', 'Fixed Income');
    assetClassTypeMap.set('BM', 'Fixed Income');
    assetClassTypeMap.set('LE', 'Sythetic Equity');
    assetClassTypeMap.set('LM', 'Sythetic Equity');
    assetClassTypeMap.set('CE', 'Commodity');
    return assetClassTypeMap;
  }
  static getCashAndOthersMap(): Map<string, boolean> {
    const cashAndOthersMap = new Map<string, boolean>();
    cashAndOthersMap.set('3053 HK Equity', true);
    return cashAndOthersMap;
  }

  static setNReturnSignatures(): Map<string, Map<string, string>> {
    const signaturesMap = new Map<string, Map<string, string>>();
    const individual = new Map<string, string>();
    const coroporate = new Map<string, string>();
    individual.set('Client Signature', 'CLIENT_SIGN');
    individual.set('Authorized Signature for CRS', 'AUTHORIZED_SIGN_CRS');
    individual.set('Primary account holder', 'PRI_ACC_HOlDER_SIGN');
    individual.set('Secondary account holder', 'SEC_ACC_HOLDER_SIGN');
    individual.set('RM Signature', 'RM_SIGN');

    coroporate.set('Authorized Signature', 'AUTHORIZED_SIGN');
    coroporate.set('Authorized Signature for CRS', 'AUTHORIZED_SIGN_CRS');
    coroporate.set('Chairman Signature', 'CHAIRMAN_SIGN');
    coroporate.set('RM Signature', 'RM_SIGN');
    signaturesMap.set('INDIVIDUAL', individual);
    signaturesMap.set('CORPORATE', coroporate);
    return signaturesMap;
  }

  static getTheMeRespectiveSigns(profileType: string) {
    return this.signaturesMap.get(profileType);
  }

  static getOCRFields(): OCRField[] {
    const ocrFields = [] as OCRField[];
    ocrFields.push({ name: 'PSPRT_NUM', dataFields: ['CLNT_INF_P2_A_PSPRT', 'CLNT_CRP_P3_B_S1_PSPRT'] } as OCRField);
    ocrFields.push({
      name: 'NTNLTY', dataFields: ['CLNT_CRP_P3_B_S1_NTNLTY', 'CLNT_CRP_P3_B_S1_POB_CNTRY',
        'CLNT_INF_P2_A_NTNLTY']
    } as OCRField);
    ocrFields.push({ name: 'DOB', dataFields: ['CLNT_INF_P2_A_DOB'] } as OCRField);
    ocrFields.push({ name: 'POB', dataFields: ['CLNT_INF_P2_A_POB', 'CLNT_CRP_P3_B_S1_POB_TOWN'] } as OCRField);
    ocrFields.push({ name: 'DOE', dataFields: ['CLNT_INF_P2_A_PSPRT_EXP_DATE'] } as OCRField);
    ocrFields.push({ name: 'POI', dataFields: ['CLNT_INF_P2_A_PSPRT_CNTRY'] } as OCRField);
    ocrFields.push({ name: 'ID', dataFields: ['CLNT_CRP_P3_B_S1_HK_PID'] } as OCRField);
    ocrFields.push({ name: 'MOBILE', dataFields: ['CLNT_INF_P2_A_MOB_NUM'] } as OCRField);
    ocrFields.push({
      name: 'ADD', dataFields: ['DISC_MAND_ADD', 'DISC_MAND_INV_MDL_COMM',
        'INV_ADV_ADD', 'INV_ADV_INV_MDL_COMM', 'CLNT_INF_P2_A_RES_ADD'],
      // eslint-disable-next-line max-len
      oprType: 'ADDRESS', dataFiledsWithOpr: ['CLNT_CRP_P3_B_S1_CURR_ADD_FLAT,CLNT_CRP_P3_B_S1_CURR_ADD_STREET,CLNT_CRP_P3_B_S1_CURR_ADD_CITY,CLNT_CRP_P3_B_S1_CURR_ADD_STATE,CLNT_CRP_P3_B_S1_CURR_ADD_CNTRY,CLNT_CRP_P3_B_S1_CURR_ADD_ZIP',
        // eslint-disable-next-line max-len
        'CLNT_CRP_P3_B_S1_MAIL_ADD_FLAT,CLNT_CRP_P3_B_S1_MAIL_ADD_STREET,CLNT_CRP_P3_B_S1_MAIL_ADD_CITY,CLNT_CRP_P3_B_S1_MAIL_ADD_STATE,CLNT_CRP_P3_B_S1_MAIL_ADD_CNTRY,CLNT_CRP_P3_B_S1_MAIL_ADD_ZIP']
    } as OCRField);
    ocrFields.push({ name: 'SUR_NAME', dataFields: ['CLNT_CRP_P3_B_S1_ACC_NAME_LAST'] } as OCRField);
    ocrFields.push({
      name: 'GIVEN_NAME', oprType: 'CONCAT', dataFields: ['CLNT_CRP_P3_B_S1_ACC_NAME_FIRST'],
      oprFields: ['SUR_NAME'], dataFiledsWithOpr: ['CLNT_INF_P2_A_NAME_ENG', 'CLNT_INF_P4_PRMRY_NAME',
        'CLNT_INF_P7_PRMRY_NAME', 'EAM_CLNT_CLNT_NAME']
    } as OCRField);
    return ocrFields;
  }

  static getFormFieldsToClearOnInpChng(): Map<string, Map<string, Array<String>>> {
    const formFieldsToClearOnInpChng = new Map<string, Map<string, Array<String>>>();
    let valMap = new Map<string, Array<String>>();

    // EAM Business Client Confirmation
    valMap.set('OTHRS', ['EAM_PROD_INTEREST_OTHERS']);
    valMap.set('MUT_FND', ['EAM_PROD_INTEREST_MUT_FND_NAME']);
    formFieldsToClearOnInpChng.set('EAM_PROD_INTEREST', valMap);

    // INVESTMENT ADVISORY MANDATE AGREEMENT
    valMap = new Map<string, Array<String>>();
    valMap.set('OTHR', ['INV_ADV_REF_CCY_OTHR']);
    formFieldsToClearOnInpChng.set('INV_ADV_REF_CCY', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('OTHR', ['INV_ADV_INV_MDL_REF_CCY_OTHR']);
    formFieldsToClearOnInpChng.set('INV_ADV_INV_MDL_REF_CCY', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('OTHR', ['INV_ADV_INV_MDL_COMM_OTHRS']);
    valMap.set('FAX', ['INV_ADV_INV_MDL_COMM_FAX']);
    valMap.set('POST', ['INV_ADV_INV_MDL_COMM_POST']);
    valMap.set('EMAIL', ['INV_ADV_INV_MDL_COMM_EMAIL']);
    valMap.set('PHN', ['INV_ADV_INV_MDL_COMM_PHONE']);
    formFieldsToClearOnInpChng.set('INV_ADV_INV_MDL_COMM', valMap);

    // DISCRETIONARY ADVISORY MANDATE AGREEMENT
    valMap = new Map<string, Array<String>>();
    valMap.set('OTHRS', ['DISC_MAND_INV_MDL_REF_CCY_OTHRS']);
    formFieldsToClearOnInpChng.set('DISC_MAND_INV_MDL_REF_CCY', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('OTHR', ['DISC_MAND_INV_MDL_COMM_OTHRS']);
    valMap.set('FAX', ['DISC_MAND_INV_MDL_COMM_FAX']);
    valMap.set('POST', ['DISC_MAND_INV_MDL_COMM_POST']);
    valMap.set('EMAIL', ['DISC_MAND_INV_MDL_COMM_EMAIL']);
    valMap.set('PHN', ['DISC_MAND_INV_MDL_COMM_PHONE']);
    formFieldsToClearOnInpChng.set('DISC_MAND_INV_MDL_COMM', valMap);

    // Client Profiling form
    // Part(2)
    valMap = new Map<string, Array<String>>();
    valMap.set('THEABOVE', ['CLNT_PROF_P2_SC_STRTGY']);
    formFieldsToClearOnInpChng.set('CLNT_PROF_P2_SC_DEC', valMap);

    // Part(5)
    valMap = new Map<string, Array<String>>();

    valMap.set('', ['CLNT_PROF_P5_CSOP_DEC_NAME', 'CLNT_PROF_P5_CSOP_DEC_CE_NUM',
      'CLNT_PROF_P5_CSOP_DEC_DEPT', 'CLNT_PROF_P5_CSOP_DEC_DATE']);
    formFieldsToClearOnInpChng.set('CLNT_PROF_P5_CSOP_DEC_CONFIRM', valMap);


    // Corportate Client Information Form
    // Part (1)
    valMap = new Map<string, Array<String>>();
    valMap.set('HK', ['CLNT_CRP_P1_SP_HK_CHK']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P1_SP_HK', valMap);
    valMap = new Map<string, Array<String>>();
    valMap.set('HK', ['CLNT_CRP_P1_CI_HK_CHK']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P1_CI_HK', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('OVRS', ['CLNT_CRP_P1_SP_OVRS_CHK']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P1_SP_OVRS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('OVRS', ['CLNT_CRP_P1_OVRS_CHK']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P1_OVRS', valMap);

    // Part (2)
    valMap = new Map<string, Array<String>>();
    valMap.set('Y', ['CLNT_CRP_P2_A_REG_ENTY_CMP']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P2_A_REG_ENTY', valMap);
    valMap = new Map<string, Array<String>>();
    valMap.set('O6', ['CLNT_CRP_P2_B_PROF_LOSS_OTHRS']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P2_B_PROF_LOSS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('O6', ['CLNT_CRP_P2_B_PROF_LOSS_OTHRS']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P2_B_PROF_LOSS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('O5', ['CLNT_CRP_P2_B_SRC_WLTH_OTHRS']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P2_B_SRC_WLTH', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('O5', ['CLNT_CRP_P2_B_SRC_FND_OTHRS']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P2_B_SRC_FND', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('O4', ['CLNT_CRP_P2_B_ORG_FND_OTHRS']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P2_B_ORG_FND', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('O2', ['CLNT_CRP_P2_B_CHK1_YES_CMP_NAME']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P2_B_CHK1', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('O2', ['CLNT_CRP_P2_B_CHK3_YES_EMP_NAME', 'CLNT_CRP_P2_B_CHK3_YES_RLTN']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P2_B_CHK3', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('O2', ['CLNT_CRP_P2_B_CHK4_YES_NAME_POS', 'CLNT_CRP_P2_B_CHK4_YES_CNTRY_PEP', 'CLNT_CRP_P2_B_CHK4_YES_RLTN_POS']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P2_B_CHK4', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('ADDR', ['CLNT_CRP_P2_C_REG_ADDRESS']);
    valMap.set('OTHER', ['CLNT_CRP_P2_C_CHQ_RES_ADD']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P2_C_CHQ_RES', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('OTHER', ['CLNT_CRP_P2_C_CHQ_OTHRS']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P2_C_CHQ_CCY', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('B', ['CLNT_CRP_P2_C_CHQ_RES', 'CLNT_CRP_P2_C_CHQ_CCY', 'CLNT_CRP_P2_C_CHQ_OTHRS',
      'CLNT_CRP_P2_C_REG_ADDRESS', 'CLNT_CRP_P2_C_CHQ_RES_ADD']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P2_C_CHQ', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('A', ['CLNT_CRP_P2_C_CRD_BK_ACC_NUM', 'CLNT_CRP_P2_C_CRD_BK_ACC_NAME', 'CLNT_CRP_P2_C_CRD_CCY', 'CLNT_CRP_P2_C_CRD_OTHRS',
      'CLNT_CRP_P2_C_CRD_SWFT_CD', 'CLNT_CRP_P2_C_CRD_BK_NAME']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P2_C_CRD', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('OTHER', ['CLNT_CRP_P2_C_CRD_OTHRS']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P2_C_CRD_CCY', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_CRP_P2_C_CRD_BK_ACC_NUM', 'CLNT_CRP_P2_C_CRD_BK_ACC_NAME', 'CLNT_CRP_P2_C_CRD_CCY', 'CLNT_CRP_P2_C_CRD_OTHRS',
      'CLNT_CRP_P2_C_CRD_SWFT_CD', 'CLNT_CRP_P2_C_CRD_BK_NAME', 'CLNT_CRP_P2_C_CRD', 'CLNT_CRP_P2_C_CHQ', 'CLNT_CRP_P2_C_CHQ_RES',
      'CLNT_CRP_P2_C_CHQ_CCY', 'CLNT_CRP_P2_C_CHQ_OTHRS', 'CLNT_CRP_P2_C_REG_ADDRESS', 'CLNT_CRP_P2_C_CHQ_RES_ADD']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P2_C_NA', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_CRP_P2_D_DVD_CHQ', 'CLNT_CRP_P2_D_DVD_CRD_DVD_CCY', 'CLNT_CRP_P2_D_DVD_CRD_DVD_OTHRS',
      'CLNT_CRP_P2_D_DVD_CRD_DVD_BK_NAME', 'CLNT_CRP_P2_D_DVD_CRD_DVD_SWFT_CD', 'CLNT_CRP_P2_D_DVD_CRD_DVD_BK_ACC_NAME',
      'CLNT_CRP_P2_D_DVD_CHQ_OTHRS', 'CLNT_CRP_P2_D_DVD_CHQ_CCY', 'CLNT_CRP_P2_D_DVD_CHQ_RES',
      'CLNT_CRP_P2_D_DVD_CHQ_REG_ADDRESS', 'CLNT_CRP_P2_D_DVD_CHQ_RES_ADD', 'CLNT_CRP_P2_D_DVD_CRD_DVD_BK_ACC_NUM']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P2_D_DVD_NA', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('OTHER', ['CLNT_CRP_P2_D_DVD_CRD_DVD_OTHRS']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P2_D_DVD_CRD_DVD_CCY', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('B', ['CLNT_CRP_P2_D_DVD_CRD_DVD_CCY', 'CLNT_CRP_P2_D_DVD_CRD_DVD_OTHRS', 'CLNT_CRP_P2_D_DVD_CRD_DVD_BK_NAME',
      'CLNT_CRP_P2_D_DVD_CRD_DVD_SWFT_CD', 'CLNT_CRP_P2_D_DVD_CRD_DVD_BK_ACC_NAME', 'CLNT_CRP_P2_D_DVD_CRD_DVD_BK_ACC_NUM']);
    valMap.set('C', ['CLNT_CRP_P2_D_DVD_CHQ_OTHRS', 'CLNT_CRP_P2_D_DVD_CHQ_CCY', 'CLNT_CRP_P2_D_DVD_CHQ_RES',
      'CLNT_CRP_P2_D_DVD_CHQ_REG_ADDRESS', 'CLNT_CRP_P2_D_DVD_CHQ_RES_ADD']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P2_D_DVD_CHQ', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('ADDR', ['CLNT_CRP_P2_D_DVD_CHQ_REG_ADDRESS']);
    valMap.set('OTHER', ['CLNT_CRP_P2_D_DVD_CHQ_RES_ADD']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P2_D_DVD_CHQ_RES', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('OTHER', ['CLNT_CRP_P2_D_CHQ_OTHRS']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P2_D_DVD_CHQ_CCY', valMap);

    // Part (3)
    valMap = new Map<string, Array<String>>();
    valMap.set('A', ['CLNT_CRP_P3_A_S1_US_TIN']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P3_A_S1_US_CTZN', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('D', ['CLNT_CRP_P3_A_S2_FATCA_TRST_NAME', 'CLNT_CRP_P3_A_S2_FATCA_GIIN']);
    valMap.set('H', ['CLNT_CRP_P3_A_S2_FATCA_OTHRS']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P3_A_S2_FATCA_CHK', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('C', ['CLNT_CRP_P3_A_S2_CRS_C_EST_NAM', 'CLNT_CRP_P3_A_S2_CRS_C_REG_T_NAM']);
    valMap.set('E', ['CLNT_CRP_P3_A_S2_CRS_OTHRS']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P3_A_S2_CRS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('HK', ['CLNT_CRP_P3_B_S1_HK_PID']);
    valMap.set('PASSPORT', ['CLNT_CRP_P3_B_S1_PSPRT']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P3_B_S1_CHK', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('Y', ['CLNT_CRP_P3_B_S2_CHK_YES']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P3_B_S2_CHK', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_CRP_P3_B_S4_LGL_HAS_CNTRL_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P3_B_S4_LGL_HAS_CNTRL', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_CRP_P3_B_S4_LGL_EXRC_CNTRL_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P3_B_S4_LGL_EXRC_CNTRL', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_CRP_P3_B_S4_LGL_HLDS_POS_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P3_B_S4_LGL_HLDS_POS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_CRP_P3_B_S4_TRST_STLR_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P3_B_S4_TRST_STLR_POS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_CRP_P3_B_S4_TRST_TRSTE_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P3_B_S4_TRST_TRSTE_POS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_CRP_P3_B_S4_TRST_PRTCTR_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P3_B_S4_TRST_PRTCTR_POS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_CRP_P3_B_S4_TRST_BNFCRY_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P3_B_S4_TRST_BNFCRY_POS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_CRP_P3_B_S4_TRST_OTHR_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P3_B_S4_TRST_OTHR_POS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_CRP_P3_B_S4_LGL_ARNGMT_STLR_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P3_B_S4_LGL_ARNGMT_STLR_POS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_CRP_P3_B_S4_LGL_ARNGMT_TRSTE_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P3_B_S4_LGL_ARNGMT_TRSTE_POS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_CRP_P3_B_S4_LGL_ARNGMT_PRTCTR_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P3_B_S4_LGL_ARNGMT_PRTCTR_POS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_CRP_P3_B_S4_LGL_ARNGMT_BNFCRY_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P3_B_S4_LGL_ARNGMT_BNFCRY_POS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_CRP_P3_B_S4_LGL_ARNGMT_OTHR_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P3_B_S4_LGL_ARNGMT_OTHR_POS', valMap);

    //  Part (5)
    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_CRP_P5_ADQCY_TYPE1', 'CLNT_CRP_P5_ADQCY_TYPE1_DOC', 'CLNT_CRP_P5_ADQCY_TYPE1', 'CLNT_CRP_P5_ADQCY_TYPE1_DOC',
      'CLNT_CRP_P5_ADQCY_TYPE2_DOC', 'CLNT_CRP_P5_ADQCY_TYPE2_DOC', 'CLNT_CRP_P5_ADQCY_TYPE2_DOC', 'CLNT_CRP_P5_ADQCY_TYPE2_DOC',
      'CLNT_CRP_P5_ADQCY_TYPE2_DOC', 'CLNT_CRP_P5_ADQCY_TYPE3_DOC', 'CLNT_CRP_P5_ADQCY_TYPE1', 'CLNT_CRP_P5_CRP_CHQ',
      'CLNT_CRP_P5_CRP_EQTY_RMRK', 'CLNT_CRP_P5_CRP_FUT_OPT_RMRK', 'CLNT_CRP_P5_CRP_WRRNT_RMRK', 'CLNT_CRP_P5_CRP_MUT_FND_RMRK',
      'CLNT_CRP_P5_CRP_STRCT_PROD_RMRK', 'CLNT_CRP_P5_CRP_OTHRS_RMRK', 'CLNT_CRP_P5_CRP_OTHRS', 'CLNT_CRP_P5_Q1_ANS',
      'CLNT_CRP_P5_Q1_ANS_OTHRS', 'CLNT_CRP_P5_Q2_ANS', 'CLNT_CRP_P5_Q2_ANS_YES', 'CLNT_CRP_P5_Q2_ANS_TRNS', 'CLNT_CRP_P5_Q2_ANS_OTHRS',
      'CLNT_CRP_P5_Q3', 'CLNT_CRP_P5_CSOP_DEC_CHK', 'CLNT_CRP_P5_CSOP_CHQ', 'CLNT_CRP_P5_CSOP_EQTY_RMRK', 'CLNT_CRP_P5_CSOP_FUT_OPT_RMRK',
      'CLNT_CRP_P5_CSOP_WRRNT_RMRK', 'CLNT_CRP_P5_CSOP_FIX_INC_RMRK', 'CLNT_CRP_P5_CSOP_MUT_FND_RMRK', 'CLNT_CRP_P5_CSOP_STRCT_PROD_RMRK',
      'CLNT_CRP_P5_CSOP_OTHRS_RMRK', 'CLNT_CRP_P5_CSOP_OTHRS', 'CLNT_CRP_P5_CSOP_NAME',
      'CLNT_CRP_P5_CSOP_CE_NUM', 'CLNT_CRP_P5_CSOP_DATE']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P5_ADQCY_NA', valMap);


    valMap = new Map<string, Array<String>>();
    valMap.set('O1', ['CLNT_CRP_P5_CRP_EQTY_RMRK']);
    valMap.set('O2', ['CLNT_CRP_P5_CRP_FUT_OPT_RMRK']);
    valMap.set('O3', ['CLNT_CRP_P5_CRP_WRRNT_RMRK']);
    valMap.set('O4', ['CLNT_CRP_P5_CRP_MUT_FND_RMRK']);
    valMap.set('O5', ['CLNT_CRP_P5_CRP_STRCT_PROD_RMRK']);
    valMap.set('O6', ['CLNT_CRP_P5_CRP_OTHRS_RMRK', 'CLNT_CRP_P5_CRP_OTHRS']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P5_CRP_CHQ', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_CRP_P5_Q1_ANS_OTHRS']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P5_Q1_ANS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('Y', ['CLNT_CRP_P5_Q2_ANS_YES', 'CLNT_CRP_P5_Q2_ANS_OTHRS', 'CLNT_CRP_P5_Q2_ANS_TRNS', 'CLNT_CRP_P5_Q2_ANS_OTHRS']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P5_Q2_ANS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('O1', ['CLNT_CRP_P5_Q2_ANS_TRNS']);
    valMap.set('O4', ['CLNT_CRP_P5_Q2_ANS_OTHRS']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P5_Q2_ANS_YES', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('O1', ['CLNT_CRP_P5_CSOP_EQTY_RMRK']);
    valMap.set('O2', ['CLNT_CRP_P5_CSOP_FUT_OPT_RMRK']);
    valMap.set('O3', ['CLNT_CRP_P5_CSOP_WRRNT_RMRK']);
    valMap.set('O4', ['CLNT_CRP_P5_CSOP_FIX_INC_RMRK']);
    valMap.set('O5', ['CLNT_CRP_P5_CSOP_MUT_FND_RMRK']);
    valMap.set('O6', ['CLNT_CRP_P5_CSOP_STRCT_PROD_RMRK']);
    valMap.set('O7', ['CLNT_CRP_P5_CSOP_OTHRS', 'CLNT_CRP_P5_CSOP_OTHRS_RMRK']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P5_CSOP_CHQ', valMap);

    // Part (6)
    valMap = new Map<string, Array<String>>();
    valMap.set('a', ['CLNT_CRP_P6_A_DATE', 'CLNT_CRP_P6_A_DATE2', 'CLNT_CRP_P6_A_DAT3', 'CLNT_CRP_P6_A_DAT4', 'CLNT_CRP_P6_A_DAT5']);
    valMap.set('b', ['CLNT_CRP_P6_B_DATE', 'CLNT_CRP_P6_B_DATE2', 'CLNT_CRP_P6_B_DATE3', 'CLNT_CRP_P6_B_DATE4', 'CLNT_CRP_P6_B_DATE5']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P6_A_NA', valMap);

    // Individual Client Information Form
    // Part(2)
    valMap = new Map<string, Array<String>>();
    valMap.set('05', ['CLNT_INF_P2_A_SRC_WLTH_SPECIFY']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_A_SRC_WLTH', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('05', ['CLNT_INF_P2_A_SRC_FNDS_SPECIFY']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_A_SRC_FNDS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('05', ['CLNT_INF_P2_A_ORG_FND_SPECIFY']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_A_ORG_FND_SRC', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('Y', ['CLNT_INF_P2_A_RUL_CMP']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_A_RUL', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('Y', ['CLNT_INF_P2_A_RURE_RLTN', 'CLNT_INF_P2_A_RURE_EMP']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_A_RURE', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('Y', ['CLNT_INF_P2_A_RUEPC_N_P', 'CLNT_INF_P2_A_RUEPC_CNTRY', 'CLNT_INF_P2_A_RUEPC_PEP_RLTN']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_A_RUEPC', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('O5', ['CLNT_INF_P2_B_SRC_WLTH_SPECIFY']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_B_SRC_WLTH', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('O5', ['CLNT_INF_P2_B_SRC_FNDS_SPECIFY']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_B_SRC_FNDS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('O4', ['CLNT_INF_P2_B_ORG_FND_SPECIFY']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_B_ORG_FND_SRC', valMap);


    valMap = new Map<string, Array<String>>();
    valMap.set('Y', ['CLNT_INF_P2_B_RUL_CMP']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_B_RUL', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('Y', ['CLNT_INF_P2_B_RURE_EMP', 'CLNT_INF_P2_B_RURE_RLTN']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_B_RURE', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('Y', ['CLNT_INF_P2_B_RUEPC_N_P', 'CLNT_INF_P2_B_RUEPC_CNTRY', 'CLNT_INF_P2_B_RUEPC_PEP_RLTN']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_B_RUEPC', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_INF_P2_D_PAY', 'CLNT_INF_P2_D_CRD_CCY', 'CLNT_INF_P2_D_CRD_OTHRS',
      'CLNT_INF_P2_D_CRD_BK_NAME', 'CLNT_INF_P2_D_CRD_SWFT_CD', 'CLNT_INF_P2_D_CRD_SWFT_CD',
      'CLNT_INF_P2_D_CRD_BK_ACC_NUM', 'CLNT_INF_P2_D_CHQ_CCY', 'CLNT_INF_P2_D_CHQ_OTHRS',
      'CLNT_INF_P2_D_CHQ_RES_ADD', 'CLNT_INF_P2_D_RES_CHQ_OTHERS', 'CLNT_INF_P2_D_CHQ_RES_REG_ADD', 'CLNT_INF_P2_D_CRD_BK_ACC_NAME']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_D_NA', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('OTHER', ['CLNT_INF_P2_D_CRD_OTHRS']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_D_CRD_CCY', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('CRD', ['CLNT_INF_P2_D_CRD_CCY', 'CLNT_INF_P2_D_CRD_OTHRS', 'CLNT_INF_P2_D_CRD_BK_NAME',
      'CLNT_INF_P2_D_CRD_SWFT_CD', 'CLNT_INF_P2_D_CRD_BK_ACC_NAME', 'CLNT_INF_P2_D_CRD_BK_ACC_NUM']);
    valMap.set('CHQ', ['CLNT_INF_P2_D_CHQ_CCY', 'CLNT_INF_P2_D_CHQ_OTHRS', 'CLNT_INF_P2_D_CHQ_RES_ADD',
      'CLNT_INF_P2_D_CHQ_RES_REG_ADD', 'CLNT_INF_P2_D_RES_CHQ_OTHERS']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_D_PAY', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('OTHER', ['CLNT_INF_P2_D_CHQ_OTHRS']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_D_CHQ_CCY', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('ADDR', ['CLNT_INF_P2_D_CHQ_RES_REG_ADD']);
    valMap.set('OTHER', ['CLNT_INF_P2_D_RES_CHQ_OTHERS']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_D_CHQ_RES_ADD', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_INF_P2_E_DVD_CRD_DIVIDEND', 'CLNT_INF_P2_E_DVD_CRD_DVD_BK_ACC_NAME', 'CLNT_INF_P2_E_DVD_CRD_DVD_CCY',
      'CLNT_INF_P2_E_CRD_OTHRS', 'CLNT_INF_P2_E_DVD_CRD_DVD_BK_NAME', 'CLNT_INF_P2_E_DVD_CRD_DVD_SWFT_CD',
      'CLNT_INF_P2_E_DVD_CRD_DVD_BK_ACC_NUM', 'CLNT_INF_P2_E_DVD_CRD_DVD_BK_A', 'CLNT_INF_P2_E_DVD_PAY',
      'CLNT_INF_P2_E_DVD_CHQ_CCY', 'CLNT_INF_P2_E_DVD_CHQ_OTHRS', 'CLNT_INF_P2_E_DVD_RES_CHQ_ADDRESS',
      'CLNT_INF_P2_E_DVD_CHQ_RES_ADD', 'CLNT_INF_P2_E_DVD_CHQ_RES_REG_ADD']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_E_DVD_NA', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('DIV_CRD_CRD', ['CLNT_INF_P2_E_DVD_CRD_DVD_CCY', 'CLNT_INF_P2_E_CRD_OTHRS', 'CLNT_INF_P2_E_DVD_CRD_DVD_BK_NAME',
      'CLNT_INF_P2_E_DVD_CRD_DVD_SWFT', 'CLNT_INF_P2_E_DVD_CRD_DVD_BK_A', 'CLNT_INF_P2_E_DVD_CRD_DVD_BK_ACC_NAME',
      'CLNT_INF_P2_E_DVD_CRD_DVD_SWFT_CD', 'CLNT_INF_P2_E_DVD_CRD_DVD_BK_ACC_NUM', 'CLNT_INF_P2_E_DVD_CRD_DVD_BK_ACC_NAME']);

    valMap.set('DVD_CHQ', ['CLNT_INF_P2_E_DVD_CHQ_CCY', 'CLNT_INF_P2_E_DVD_CHQ_OTHRS', 'CLNT_INF_P2_E_DVD_CHQ_RES_ADD',
      'CLNT_INF_P2_E_DVD_CHQ_RES_REG_ADD', 'CLNT_INF_P2_E_DVD_RES_CHQ_ADDRESS']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_E_DVD_PAY', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('OTHER', ['CLNT_INF_P2_E_CRD_OTHRS']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_E_DVD_CRD_DVD_CCY', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('OTHER', ['CLNT_INF_P2_E_DVD_CHQ_OTHRS']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_E_DVD_CHQ_CCY', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('ADDR', ['CLNT_INF_P2_E_DVD_CHQ_RES_REG_ADD']);
    valMap.set('OTHER', ['CLNT_INF_P2_E_DVD_RES_CHQ_ADDRESS']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_E_DVD_CHQ_RES_ADD', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('Yes', ['CLNT_INF_P2_CSOP_NAME', 'CLNT_INF_P2_CSOP_CE_NUM', 'CLNT_INF_P2_CSOP_DATE']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_CSOP_TRD_DER', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('Yes', ['CLNT_INF_P2_CSOP_NAME', 'CLNT_INF_P2_CSOP_CE_NUM', 'CLNT_INF_P2_CSOP_DATE']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P2_CSOP_DEC', valMap);

    // Part (3)
    valMap = new Map<string, Array<String>>();
    valMap.set('Y', ['CLNT_INF_P3_S1_US_TIN']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P3_S1_US_CTZN', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_INF_P3_S3_LGL_HAS_CNTRL_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P3_S3_LGL_HAS_CNTRL', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_INF_P3_S3_LGL_EXRC_CNTRL_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P3_S3_LGL_EXRC_CNTRL', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_INF_P3_S3_LGL_HLDS_POS_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P3_S3_LGL_HLDS_POS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_INF_P3_S3_TRST_STLR_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P3_S3_TRST_STLR_POS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_INF_P3_S3_TRST_PRTCTR_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P3_S3_TRST_PRTCTR_POS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_INF_P3_S3_TRST_BNFCRY_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P3_S3_TRST_BNFCRY_POS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_INF_P3_S3_TRST_OTHR_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P3_S3_TRST_OTHR_POS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_INF_P3_S3_LGL_ARNGMT_STLR_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P3_S3_LGL_ARNGMT_STLR_POS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_INF_P3_S3_LGL_ARNGMT_TRSTE_POS']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P3_S3_LGL_ARNGMT_TRSTE_POS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_INF_P3_S3_LGL_ARNGMT_PRTCTR_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P3_S3_LGL_ARNGMT_PRTCTR_POS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_INF_P3_S3_LGL_ARNGMT_BNFCRY_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P3_S3_LGL_ARNGMT_BNFCRY_POS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_INF_P3_S3_LGL_ARNGMT_OTHR_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P3_S3_LGL_ARNGMT_OTHR_POS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_INF_P3_S3_LGL_ARNGMT_TRSTE_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P3_S3_LGL_ARNGMT_TRSTE_POS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('', ['CLNT_INF_P3_S3_TRST_TRSTE_ENTY']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P3_S3_TRST_TRSTE_POS', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('O4', ['CLNT_INF_P5_EQTY_SEC_NO_OF_TRDS']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_EQTY_SEC_INV_EXP', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('O4', ['CLNT_INF_P5_FUT_OPT_NO_OF_TRDS']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_FUT_OPT_INV_EXP', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('O4', ['CLNT_INF_P5_WRRNT_STCK_NO_OF_TRDS']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_WRRNT_STCK_INV_EXP', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('O4', ['CLNT_INF_P5_FIX_INC_NO_OF_TRDS']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_FIX_INC_INV_EXP', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('O4', ['CLNT_INF_P5_MUT_FND_NO_OF_TRDS']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_MUT_FND_INV_EXP', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('O4', ['CLNT_INF_P5_STRUCT_PROD_NO_OF_TRDS']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_STRUCT_PROD_INV_EXP', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('O4', ['CLNT_INF_P5_OTHRS_NO_OF_TRDS']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_OTHRS_INV_EXP', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('Y', ['CLNT_INF_P5_EQTY_SEC_INV_EXP', 'CLNT_INF_P5_OTHRS_NO_OF_TRDS']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_EQTY_SEC_Q1', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('Y', ['CLNT_INF_P5_FUT_OPT_INV_EXP', 'CLNT_INF_P5_FUT_OPT_NO_OF_TRDS']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_FUT_OPT_Q1', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('Y', ['CLNT_INF_P5_WRRNT_STCK_INV_EXP', 'CLNT_INF_P5_WRRNT_STCK_NO_OF_TRDS']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_WRRNT_STCK_Q1', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('Y', ['CLNT_INF_P5_FIX_INC_INV_EXP', 'CLNT_INF_P5_FIX_INC_NO_OF_TRDS']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_FIX_INC_Q1', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('Y', ['CLNT_INF_P5_MUT_FND_NO_OF_TRDS', 'CLNT_INF_P5_MUT_FND_INV_EXP']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_MUT_FND_Q1', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('Y', ['CLNT_INF_P5_STRUCT_PROD_INV_EXP', 'CLNT_INF_P5_STRUCT_PROD_NO_OF_TRDS']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_STRUCT_PROD_Q1', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('Y', ['CLNT_INF_P5_OTHRS_INV_EXP', 'CLNT_INF_P5_OTHRS_NO_OF_TRDS']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_OTHRS_Q1', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('c', ['CLNT_INF_P5_EQTY_SEC_Q3_C']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_EQTY_SEC_Q3', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('c', ['CLNT_INF_P5_FUT_OPT_Q3_C']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_FUT_OPT_Q3', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('c', ['CLNT_INF_P5_WRRNT_STCK_Q3_C']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_WRRNT_STCK_Q3', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('c', ['CLNT_INF_P5_FIX_INC_Q3_C']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_FIX_INC_Q3', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('c', ['CLNT_INF_P5_MUT_FND_Q3_C']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_MUT_FND_Q3', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('c', ['CLNT_INF_P5_STRUCT_PROD_Q3_C']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_STRUCT_PROD_Q3', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('c', ['CLNT_INF_P5_OTHRS_Q3_C']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_OTHRS_Q3', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('Y', ['CLNT_INF_P5_ASST_ADQCY_PROOF']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_ASST_ADQCY', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('Y', ['CLNT_CRP_P8_L_C_NEG_NOTE_DET']);
    formFieldsToClearOnInpChng.set('CLNT_CRP_P8_L_C_NEG_NOTE', valMap);

    valMap = new Map<string, Array<String>>();
    valMap.set('O2', ['CLNT_INF_P5_CSOP_DEC_PRD_EQTY_REM', 'CLNT_INF_P5_CSOP_DEC_PRD_CHQ_OTHR', 'CLNT_INF_P5_CSOP_DEC_PRD_FUT_REM',
      'CLNT_INF_P5_CSOP_DEC_PRD_WRRNT_REM', 'CLNT_INF_P5_CSOP_DEC_PRD_FIX_INC_REM', 'CLNT_INF_P5_CSOP_DEC_PRD_MUT_FND_REM',
      'CLNT_INF_P5_CSOP_DEC_PRD_STRCT_PRD_REM', 'CLNT_INF_P5_CSOP_DEC_PRD_OTHRS_REM']);
    formFieldsToClearOnInpChng.set('CLNT_INF_P5_CSOP_DEC_CHQ', valMap);

    return formFieldsToClearOnInpChng;


  }

  static getMessageMap(): Map<string, Message> {
    const messageMap = new Map<string, Message>();
    messageMap.set('SUBMISSION_PENDING$SUBMIT FOR BACK OFFICE REVIEW', {
      header: 'SUBMITTED', isChecked: true,
      message: 'Case CASE_NAME has been submitted to Back Office Support Chief of staff for review.',
      auditMessage: 'RM Submitted'
    } as Message);
    messageMap.set('RM_REVIEW$SUBMIT FOR BACK OFFICE REVIEW', {
      header: 'SUBMITTED', isChecked: true,
      message: 'Case CASE_NAME has been submitted to Back Office Support Chief of staff for review.',
      auditMessage: 'RM Re-Submitted'
    } as Message);

    messageMap.set('ARM_CASE_APPROVAL_PENDING$APPROVE', {
      header: 'SUBMITTED', isChecked: true,
      message: 'Case CASE_NAME has been submitted to Back Office Support Chief of staff for review.',
      auditMessage: 'ARM Approved'
    } as Message);
    messageMap.set('ARM_CASE_APPROVAL_PENDING$RETURN', {
      header: 'RETURNED', isChecked: false,
      message: 'Case CASE_NAME has been sent back to RM with remarks.', auditMessage: 'ARM Returned'
    } as Message);

    messageMap.set('BO_CASE_APPROVAL_PENDING$ASSIGN', {
      header: 'ASSIGNED', isChecked: true,
      message: 'Case CASE_NAME has been assigned to ARM for review.', auditMessage: 'BO assigned to ARM'
    } as Message);
    messageMap.set('BO_CASE_APPROVAL_PENDING$APPROVE', {
      header: 'SUBMITTED', isChecked: true,
      message: 'Case CASE_NAME has been submitted to Team Head for review.', auditMessage: 'BO Approved'
    } as Message);
    messageMap.set('BO_CASE_APPROVAL_PENDING$RETURN', {
      header: 'RETURNED', isChecked: false,
      message: 'Case CASE_NAME has been sent back to RM for review with remarks.', auditMessage: 'BO Returned'
    } as Message);

    messageMap.set('TH_CASE_APPROVAL_PENDING$APPROVE', {
      header: 'SUBMITTED', isChecked: true,
      message: 'Case CASE_NAME has been submitted to next level team for review.', auditMessage: 'TH Approved'
    } as Message);
    messageMap.set('TH_CASE_APPROVAL_PENDING$RETURN', {
      header: 'RETURNED', isChecked: false,
      message: 'Case CASE_NAME has been submitted to Back Office Support chief of staff for review.',
      auditMessage: 'TH Returned'
    } as Message);
    messageMap.set('TH_CASE_APPROVAL_PENDING$TERMINATE/ARCHIVE', {
      header: 'TERMINATED', isChecked: false,
      message: 'Case CASE_NAME has been terminated with remarks.', auditMessage: 'TH Terminated'
    } as Message);

    messageMap.set('LC_CASE_APPROVAL_PENDING$APPROVE', {
      header: 'SUBMITTED', isChecked: true,
      message: 'Case CASE_NAME has been submitted to Team Head with approval to submit to HWM.', auditMessage: 'LC Approved'
    } as Message);
    messageMap.set('LC_CASE_APPROVAL_PENDING$RETURN', {
      header: 'RETURNED', isChecked: false,
      message: 'Case CASE_NAME has been sent back to Team Head for review with remarks.', auditMessage: 'LC Returned'
    } as Message);

    messageMap.set('HWM_CASE_APPROVAL_PENDING$APPROVE', {
      header: 'SUBMITTED', isChecked: true,
      message: 'Case CASE_NAME has been approved.', auditMessage: 'HWM Approved'
    } as Message);
    messageMap.set('HWM_CASE_APPROVAL_PENDING$RETURN', {
      header: 'RETURNED', isChecked: false,
      message: 'Case CASE_NAME has been sent back to Team Head for review with remarks.', auditMessage: 'HWM Returned'
    } as Message);

    messageMap.set('APPROVED$REQUEST TH TO OPEN THE CASE', {
      header: 'SUBMITTED', isChecked: true,
      message: 'RM has requested Team Head CASE_NAME to reopen .', auditMessage: 'RM requested to Re-Open'
    } as Message);

    messageMap.set('TH_OPEN_CLOSED_CASE_APPROVAL_PENDING$APPROVE', {
      header: 'SUBMITTED', isChecked: true,
      message: 'Team Head approved RM request to reopen CASE_NAME.', auditMessage: 'TH Approved to Re-Open'
    } as Message);
    messageMap.set('TH_OPEN_CLOSED_CASE_APPROVAL_PENDING$RETURN', {
      header: 'RETURNED', isChecked: false,
      message: 'Team Head rejected RM request to reopen CASE_NAME.', auditMessage: 'TH Rejected to Re-Open'
    } as Message);

    messageMap.set('TH_REVIEW_EDIT_CLOSED_CASE$APPROVE', {
      header: 'SUBMITTED', isChecked: true,
      message: 'Team Head approved CASE_NAME changes made by RM after reopen.', auditMessage: 'TH Aprroved Re-Opened changes'
    } as Message);
    messageMap.set('TH_REVIEW_EDIT_CLOSED_CASE$RETURN', {
      header: 'RETURNED', isChecked: false,
      message: 'Team Head sent back CASE_NAME to RM to review the changes made after reopen.',
      auditMessage: 'TH Returned Re-Opened Changes'
    } as Message);

    messageMap.set('EDIT_CLOSED_CASE$SUBMIT FOR TH REVIEW', {
      header: 'SUBMITTED', isChecked: true,
      message: 'RM submitted CASE_NAME to Team to review the changes made after reopen.',
      auditMessage: 'RM edited Re-Opened Case and Submitted'
    } as Message);

    return messageMap;
  }


  static getFormGroupMap(): Map<string, FormGroup> {
    const formGroupMap = new Map<string, FormGroup>();
    // Doc 1
    formGroupMap.set('EAM', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('EAM')));

    // Doc 2
    formGroupMap.set('INV_MAND', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('INV_MAND')));
    formGroupMap.set('DISC_MAND', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('DISC_MAND')));

    // Doc 3
    formGroupMap.set('INV_KNW_ASSMT', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('INV_KNW_ASSMT')));
    formGroupMap.set('CUST_RSK_PROF', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('CUST_RSK_PROF')));
    formGroupMap.set('GK_ON_DRVTS', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('GK_ON_DRVTS')));
    formGroupMap.set('RSK_DISCL', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('RSK_DISCL')));
    formGroupMap.set('TERM_N_COND', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('TERM_N_COND')));
    // Doc 4.1
    formGroupMap.set('CI_INDV_DOCS', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('CI_INDV_DOCS')));
    formGroupMap.set('CI_INDV_APPLCNT', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('CI_INDV_APPLCNT')));
    formGroupMap.set('CI_INDV_SELF_CERTIF', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('CI_INDV_SELF_CERTIF')));
    formGroupMap.set('CI_INDV_CLNT_DECL', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('CI_INDV_CLNT_DECL')));
    formGroupMap.set('CI_INDV_ASSMT', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('CI_INDV_ASSMT')));
    formGroupMap.set('CI_INDV_CNSNT_IPV', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('CI_INDV_CNSNT_IPV')));
    formGroupMap.set('CI_INDV_CNSNT_DDM', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('CI_INDV_CNSNT_DDM')));
    formGroupMap.set('CI_INDV_IRA', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('CI_INDV_IRA')));
    formGroupMap.set('CI_CORP_LC_DPRT', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('CI_CORP_LC_DPRT')));
    // Doc 4.2
    formGroupMap.set('CORP_DOCS', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('CORP_DOCS')));
    formGroupMap.set('CORPCLNTDCLTNFORM', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('CORPCLNTDCLTNFORM')));
    formGroupMap.set('CORPCSTINVFORM', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('CORPCSTINVFORM')));
    formGroupMap.set('CORPCSTMRKTNGFORM', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('CORPCSTMRKTNGFORM')));
    formGroupMap.set('INTERNALRISKCICORP', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('INTERNALRISKCICORP')));
    formGroupMap.set('CORPCOMPPROFFORM', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('CORPCOMPPROFFORM')));
    formGroupMap.set('CORPSELFFORM_A', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('CORPSELFFORM_A')));
    formGroupMap.set('CORPSELFFORM_B', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('CORPSELFFORM_B')));
    formGroupMap.set('CORPPROFINVFORM', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('CORPPROFINVFORM')));

    // Doc 4.2.1
    formGroupMap.set('BOARD_RESOL', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('BOARD_RESOL')));
    formGroupMap.set('CI_INDV_IRA', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('CI_INDV_IRA')));

    // OCR
    formGroupMap.set('OCR', UtilitiesService.getTheForm(UtilitiesService.getRespectiveFormFields('OCR')));

    return formGroupMap;
  }

  static getTheForm(fields: FormField[]): FormGroup {
    const group: any = {};
    fields.forEach(field => {
      group[field.key] = field.required ? new FormControl(field.value || '', Validators.required)
        : new FormControl(field.value || '');
    });
    return new FormGroup(group);
  }

  static getOCRFormFields(): FormField[] {
    const formFields = [] as FormField[];
    this.ocrFieldsMapDetails.forEach(ocrField => formFields.push({ key: ocrField.name, value: '' } as FormField));
    return formFields;
  }

  private static getTheCSSStyle(actionTitle): string {
    return '';
  }

  public static getMeTheMessage(statusNTitle: string, caseName: string) {
    const messageToReturn = this.messageMap.get(statusNTitle);
    messageToReturn.message = messageToReturn.message.replace('CASE_NAME', '"' + caseName + '"');
    return messageToReturn;
  }
  static getRespectiveFormFields(formName: string) {
    switch (formName) {
      // Doc 1 EAM Business Client confirmation
      case 'EAM': return FormsUtilitiesService.getEAMFormFields();

      // Doc 2 DISCRETIONARY & INDVIDUAL ADVISORY MANDATE
      case 'INV_MAND': return FormsUtilitiesService.getINVMANDFormFields();
      case 'DISC_MAND': return FormsUtilitiesService.getDISCMANDFormFields();

      // Doc 3 Client Profiling forms
      case 'INV_KNW_ASSMT': return FormsUtilitiesService.getINVKNWASSMTFormFields();
      case 'CUST_RSK_PROF': return FormsUtilitiesService.getCUSTRSKPROFFormFields();
      case 'GK_ON_DRVTS': return FormsUtilitiesService.getGKONDRVTSFormFields();
      case 'RSK_DISCL': return FormsUtilitiesService.getRSKDISCLFormFields();
      case 'TERM_N_COND': return FormsUtilitiesService.getTERMSANDCONDITIONSFormFields();

      // Doc 4.1 INDIVIDUAL Client Information forms
      case 'CI_INDV_DOCS': return FormsUtilitiesService.getCIINDVDOCSFormFields();
      case 'CI_INDV_APPLCNT': return FormsUtilitiesService.getCIINDVAPPLCNTFormFields();
      case 'CI_INDV_SELF_CERTIF': return FormsUtilitiesService.getCIINDVSELFCERTIFFormFields();
      case 'CI_INDV_CLNT_DECL': return FormsUtilitiesService.getCIINDVCLNTDECLFormFields();
      case 'CI_INDV_ASSMT': return FormsUtilitiesService.getCIINDVASSMTFormFields();
      case 'CI_INDV_CNSNT_IPV': return FormsUtilitiesService.getCIINDVCSNSNTIPVFormFields();
      case 'CI_INDV_CNSNT_DDM': return FormsUtilitiesService.getCIINDVCSNSNTDDMFormFields();
      case 'CI_INDV_IRA': return FormsUtilitiesService.getCIINDVIRAFormFields();
      case 'CI_CORP_LC_DPRT': return FormsUtilitiesService.getCICORPLegalAndComplianceFields();

      // Doc 4.2 CORPORATE CLIENT INFORMATION
      case 'CORP_DOCS': return FormsUtilitiesService.getCICORPDOCSFormFields();
      case 'CORPCOMPPROFFORM': return FormsUtilitiesService.getCICORPCOMPPROFFormFields();
      case 'CORPSELFFORM_A': return FormsUtilitiesService.getCICORPSELFAFormFields();
      case 'CORPSELFFORM_B': return FormsUtilitiesService.getCICORPSELFBFormFields();
      case 'CORPCLNTDCLTNFORM': return FormsUtilitiesService.getCICORPCLNTDCLTNFormFields();
      case 'CORPCSTINVFORM': return FormsUtilitiesService.getCICORPCSTINVFormFields();
      case 'CORPCSTMRKTNGFORM': return FormsUtilitiesService.getCICORPCSTMRKTNGFormFields();
      case 'CORPPROFINVFORM': return FormsUtilitiesService.getCICORPPROFINVFormFields();
      case 'INTERNALRISKCICORP': return FormsUtilitiesService.getCICORPINTERNALRISKFields();

      // Doc 4.2.1 BOARD RESOLUTION TEMPLATE
      case 'BOARD_RESOL': return FormsUtilitiesService.getBRDRESOLFormFields();

      // OCR Fields
      case 'OCR': return this.getOCRFormFields();

    }
  }
  public static setTheCSSStyleForTheCase(processCase: any) {
    switch (processCase['status']) {
      case 'SUBMISSION_PENDING':
        processCase['statusDescription'] = 'Draft';
        processCase['type'] = 'Draft';
        break;
      case 'RM_REVIEW':
        processCase['statusDescription'] = 'RM Review';
        processCase['type'] = 'rmReview';
        break;
      case 'ARM_CASE_APPROVAL_PENDING':
        processCase['statusDescription'] = 'ARM Review';
        processCase['type'] = 'armReview';
        break;
      case 'BO_CASE_APPROVAL_PENDING':
        processCase['statusDescription'] = 'Back Office Review';
        processCase['type'] = 'backOfcReview';
        break;
      case 'TH_CASE_APPROVAL_PENDING':
        processCase['statusDescription'] = 'Team Head Review';
        processCase['type'] = 'teamHeadReview';
        break;
      case 'TERMINATED':
        processCase['statusDescription'] = 'Terminated';
        processCase['type'] = 'terminated';
        break;
      case 'LC_CASE_APPROVAL_PENDING':
        processCase['statusDescription'] = 'L&C Review';
        processCase['type'] = 'lcReview';
        break;
      case 'HWM_CASE_APPROVAL_PENDING':
        processCase['statusDescription'] = 'HWM Review';
        processCase['type'] = 'hwmReview';
        break;
      case 'APPROVED':
        processCase['statusDescription'] = 'Approved';
        processCase['type'] = 'approved';
        break;
      case 'TH_OPEN_CLOSED_CASE_APPROVAL_PENDING':
        processCase['statusDescription'] = 'RM Reopen Req';
        processCase['type'] = 'rmReopen';
        break;
      case 'EDIT_CLOSED_CASE':
        processCase['statusDescription'] = 'RM Reopened Review';
        processCase['type'] = 'rmReOpenedReview';
        break;
      case 'TH_REVIEW_EDIT_CLOSED_CASE':
        processCase['statusDescription'] = 'TH Reopened Review';
        processCase['type'] = 'thReOpenedReview';
        break;
      default:
        break;
    }
  }

  public static canEditingPermitted(processStatus: any, loggedInRole: string): boolean {
    const canEditCaseFormsPriv = { 'screenName': 'CaseDetails', 'subModule': 'canEditCaseForms' };
    switch (processStatus) {
      case 'SUBMISSION_PENDING':
      case 'RM_REVIEW':
      case 'EDIT_CLOSED_CASE':
        if (this.privilegeService.isAuthorized(canEditCaseFormsPriv)) { return true; }
        break;
      case 'ARM_CASE_APPROVAL_PENDING':
        if (this.privilegeService.isAuthorized(canEditCaseFormsPriv)) { return true; }
        break;
      case 'BO_CASE_APPROVAL_PENDING':
        if (this.privilegeService.isAuthorized(canEditCaseFormsPriv)) { return true; }
        break;
      // FOR FUTURE PURPOSE RETURN BASED ON ROLE AND PROCESS STATE.
      // case 'TERMINATED':
      //   return true;
      // case 'LC_CASE_APPROVAL_PENDING':
      //   return true;
      // case 'HWM_CASE_APPROVAL_PENDING':
      //   return true;
      // case 'APPROVED':
      //   return true;
      // case 'TH_OPEN_CLOSED_CASE_APPROVAL_PENDING':
      //   return true;
      // case 'TH_REVIEW_EDIT_CLOSED_CASE':
      //   return true;
    }
    return false;
  }

  /**
   *

   -- COMMENTED AS A PART OF REFACTORING


    public static canActionsPermitted(processStatus: any, loggedInRole: string): boolean {
      const canEditCaseFormsPriv = {'screenName': 'CaseDetails', 'subModule': 'canEditCaseForms'};
      switch (processStatus) {
        case 'SUBMISSION_PENDING':
          if (this.privilegeService.isAuthorized(canEditCaseFormsPriv)) { return false; }
          break;
        case 'EDIT_CLOSED_CASE':
          if (this.privilegeService.isAuthorized(canEditCaseFormsPriv)) { return false; }
          break;
        case 'RM_REVIEW':
          if (this.privilegeService.isAuthorized(canEditCaseFormsPriv)) { return false; }
          break;
      }
      return true;
    }

     */

  public static canEditingPermittedBasedOnForm(formName: string, processStatus: any, loggedInRole: string): boolean {
    const canEditCICorpLCFormPriv = { 'screenName': 'CaseCICorpLCForm', 'subModule': 'canEditCICorpLCForm' };
    switch (formName) {
      case 'CI_CORP_LC_DPRT':
        if (processStatus === 'LC_CASE_APPROVAL_PENDING' && this.privilegeService.isAuthorized(canEditCICorpLCFormPriv)) { return true; }
    }

    return false;
  }

  public static createAuditForActivity(defaultService: DefaultService, message: Message, id: string) {
    const auditMessage = {};
    const user = JSON.parse(localStorage.getItem('currentUser'));
    auditMessage['batchRec'] = 'Y';
    auditMessage['role'] = user['roleCodes'][0];
    auditMessage['user'] = user['firstName'] + user['lastName'];
    if (message['logFor'] === 'risk') {
      auditMessage['objectID'] = id;
      auditMessage['action'] = message.auditMessage;
      defaultService.create(auditMessage, 'auditlog');
      return;
    }
    auditMessage['caseID'] = id;
    auditMessage['action'] = message.auditMessage;
    defaultService.create(auditMessage, 'auditlog');
  }

  public static createAuditForFundActivity(defaultService: DefaultService, message: string, fundID: string) {
    const auditMessage = {};
    const user = JSON.parse(localStorage.getItem('currentUser'));
    auditMessage['role'] = user['roleCodes'][0];
    auditMessage['caseID'] = fundID;
    auditMessage['action'] = message;
    auditMessage['user'] = user['firstName'];
    defaultService.create(auditMessage, 'auditlog');
  }

  public static createAuditForActivityAsPerNewSchema(defaultService: DefaultService, messageDetails: any, entityDetails: any) {
    const auditMessage = {};
    const detailedMessage = {};
    const user = JSON.parse(localStorage.getItem('currentUser'));
    auditMessage['role'] = user['roleCodes'][0];
    auditMessage['userID'] = user['_id'];
    auditMessage['user'] = user['firstName'] + user['lastName'];
    auditMessage['entityID'] = entityDetails['entityID'];
    auditMessage['entityName'] = entityDetails['entityName'];
    auditMessage['action'] = messageDetails['action'];
    auditMessage['module'] = messageDetails['module'];

    if (messageDetails['propertyName']) {
      detailedMessage['role'] = user['roleCodes'][0];
      detailedMessage['userID'] = user['_id'];
      auditMessage['user'] = user['firstName'] + user['lastName'];
      detailedMessage['entityID'] = entityDetails['entityID'];
      detailedMessage['entityName'] = entityDetails['entityName'];
      detailedMessage['action'] = messageDetails['action'];
      detailedMessage['module'] = messageDetails['module'];
      detailedMessage['propertyName'] = messageDetails['propertyName'];
      detailedMessage['prevValue'] = messageDetails['prevValue'];
      detailedMessage['updatedVal'] = messageDetails['updatedVal'];
    }
    defaultService.createCommons({ 'event': auditMessage, 'eventDetails': detailedMessage }, 'audit/create');
  }

  public static clearFormFieldsOnInpValChng(formkey: string, casedetails: any) {
    const valMap = UtilitiesService.formFieldsToClearOnInpChng.get(formkey);
    if (valMap === undefined || casedetails['data'][formkey] === undefined) { return; }
    valMap.forEach((value: Array<String>, key: string) => {
      if (casedetails['data'][formkey].indexOf(key) < 0) {
        value.forEach(keysToClear => { casedetails['data']['' + keysToClear] = ''; });
      }
    });
  }
  public static clearCRSFormFieldsOnInpValChng(formkey: string, casedetails: any, index: number) {
    const valMap = UtilitiesService.formFieldsToClearOnInpChng.get(formkey);
    if (valMap === undefined || casedetails['data']['CRS'].length === 0 ||
      casedetails['data']['CRS'][index][formkey] === undefined) { return; }
    valMap.forEach((value: Array<String>, key: string) => {
      if (casedetails['data']['CRS'][index][formkey].indexOf(key) < 0) {
        value.forEach(keysToClear => { casedetails['data']['CRS'][index]['' + keysToClear] = ''; });
      }
    });
  }
  public static setTheDateFields(casedetails: any) {
    if (casedetails.data.CRS && casedetails.data.CRS.length > 0) {
      casedetails.data.CRS.forEach(element => {
        Object.keys(element).forEach(function (key) {
          try {
            if (key.indexOf('DATE') >= 0 || key.indexOf('_DIC') >= 0 || key.indexOf('_DOB') >= 0) {
              if (element[key] === undefined || element[key] === null ||
                isNaN(Date.parse(element[key]))) {
                delete element[key];
              } else {
                element[key] = new Date(element[key]);
              }
            }
          } catch (err) {
            
          }
        });
      });
    }
    if (casedetails['data'] !== undefined) {
      Object.keys(casedetails['data']).forEach(function (key) {
        try {
          if (key.indexOf('DATE') >= 0 || key.indexOf('_DIC') >= 0 || key.indexOf('_DOB') >= 0) {
            if (casedetails['data'][key] === undefined || casedetails['data'][key] === null ||
              isNaN(Date.parse(casedetails['data'][key]))) {
              delete casedetails['data'][key];
            } else {
              casedetails['data'][key] = new Date(casedetails['data'][key]);
            }
          }
        } catch (err) {
          
        }
      });
    }
    if (casedetails['ocr_data'] !== undefined) {
      Object.keys(casedetails['ocr_data']).forEach(function (key) {
        try {
          if (key.indexOf('DOE') >= 0 || key.indexOf('DOB') >= 0) {
            if (casedetails['ocr_data'][key] === undefined || isNaN(Date.parse(casedetails['ocr_data'][key]))) {
              // -- No Default date commented for now. casedetails['ocr_data'][key] = new Date();
            } else {
              casedetails['ocr_data'][key] = new Date(casedetails['ocr_data'][key]);
            }
          }
        } catch (err) {
          
        }
      });
    }
  }

  public static updateDateFields(updatingObj: any) {
    if (updatingObj['ocr_data']) {
      Object.keys(updatingObj['ocr_data']).forEach(function (key) {
        try {
          if (key.indexOf('DOE') >= 0 || key.indexOf('DOB') >= 0) {
            if (updatingObj['ocr_data'][key] === undefined || updatingObj['ocr_data'][key] === null ||
              isNaN(Date.parse(updatingObj['ocr_data'][key]))) {
              delete updatingObj['ocr_data'][key];
            } else {
              const dateFieldValue = updatingObj['ocr_data'][key];
              const utcDate = new Date(Date.UTC(dateFieldValue.getFullYear(), dateFieldValue.getMonth(),
                dateFieldValue.getDate(), dateFieldValue.getHours(), dateFieldValue.getMinutes()));
              updatingObj['ocr_data'][key] = JSON.stringify(utcDate).replace(/"/g, '');
            }
          }
        } catch (err) {
          
        }
      });
    } else if (updatingObj['codes'] && updatingObj['codes'].length >= 0) {
      updatingObj['codes'].forEach(element => {
        Object.keys(element).forEach(function (key) {
          try {
            if (key.indexOf('DATE') >= 0 || key.indexOf('_DIC') >= 0 || key.indexOf('_DOB') >= 0) {
              if (element[key] === undefined || element[key] === null ||
                isNaN(Date.parse(element[key]))) {
                delete element[key];
              } else {
                const dateFieldValue = element[key];
                const utcDate = new Date(Date.UTC(dateFieldValue.getFullYear(), dateFieldValue.getMonth(),
                  dateFieldValue.getDate(), dateFieldValue.getHours(), dateFieldValue.getMinutes()));
                element[key] = JSON.stringify(utcDate).replace(/"/g, '');
              }
            }
          } catch (err) {
            
          }
        });
      });
    }
  }

  public static setOCRFields(casedetails: any) {
    if (casedetails['ocr_data'] === undefined) { return; }
    if (casedetails['data'] === undefined) { casedetails['data'] = {}; }
    for (const ocrField of this.ocrFieldsMapDetails) {
      if (casedetails['ocr_data'][ocrField.name] === undefined) { casedetails['ocr_data'][ocrField.name] = ''; }
      ocrField.dataFields.forEach(field => casedetails['data'][field] = casedetails['ocr_data'][ocrField.name]);
      ocrField.dataFields.forEach(field => casedetails['data'][field] = casedetails['ocr_data'][ocrField.name] ?
        casedetails['ocr_data'][ocrField.name] : '');
      if (ocrField.oprType !== 'NA') { this.performNSetOprOnOCRFields(ocrField, casedetails); }
    }
  }

  static performNSetOprOnOCRFields(ocrField: OCRField, casedetails: any) {
    switch (ocrField.oprType) {
      case 'CONCAT':
        let concatStr = casedetails['ocr_data'][ocrField.name];
        ocrField.oprFields.forEach(field => concatStr += (casedetails['ocr_data'][field] || ''));
        ocrField.dataFiledsWithOpr.forEach(field => casedetails['data'][field] = concatStr);
        break;
      case 'ADDRESS': this.setOCRAddrFields(ocrField, casedetails);
        break;
    }
  }
  static setOCRAddrFields(ocrField: OCRField, casedetails: any) {
    const address = casedetails['ocr_data'][ocrField.name];
    const addressParts = address.split(',');
    for (const addressField of ocrField.dataFiledsWithOpr) {
      const addressFieldParts = addressField.split(',');
      let lngthToIterate = 0;
      if (addressFieldParts.length > addressParts.length) {
        lngthToIterate = addressParts.length;
      } else {
        lngthToIterate = addressFieldParts.length;
      }
      let start = 0;
      while (start < lngthToIterate) {
        casedetails['data'][addressFieldParts[start]] = addressParts[start];
        ++start;
      }
      while (start < addressFieldParts.length) {
        casedetails['data'][addressFieldParts[start]] = '';
        ++start;
      }
    }
  }
  // Risk Services

  public static setLoginAndCaseUpdatedTime(caseUpdatedTime, currntTime) {
    const caseUpdateTime = caseUpdatedTime;
    const currenttime = currntTime;
    let noOfDays;
    let noOfHours;
    let noOfMinutes;
    const diffDays = currenttime.getDate() - caseUpdateTime.getDate();
    const hourDiff = currenttime - caseUpdateTime;
    if (diffDays > 0) {
      const Days = diffDays;
      if (diffDays > 1) {
        noOfDays = Days + ' days';
      } else {
        noOfDays = Days + ' day';
      }
    } else {
      noOfDays = '';
    }
    const diffHrs = Math.round((hourDiff % 86400000) / 3600000);
    if (diffHrs > 0) {
      const Hrs = diffHrs;
      if (diffHrs > 1) {
        noOfHours = Hrs + ' hrs';
      } else {
        noOfHours = Hrs + ' hr';
      }
    } else {
      noOfHours = '';
    }
    const diffMins = Math.round(((hourDiff % 86400000) % 3600000) / 60000);
    if (diffMins > 0) {
      const Mins = diffMins;
      noOfMinutes = Mins + ' mins';
    } else {
      noOfMinutes = 'Less than a minute';
    }
    if (diffDays >= 1) {
      return noOfDays;
    } else {
      return noOfDays + ' ' + noOfHours + ' ' + noOfMinutes;
    }
  }
  static generateID(): string {
    return Math.random().toString(36).substr(2, 9);
  }

  static isValidFile(file): boolean {
    const fileName = file.toUpperCase();
    const re = /(\.(MP4|MP3|WAV|M4P|AU ))$/i;
    if (re.exec(fileName)) {
      return true;
    }
    return false;
  }

  static getStartDate(date) {
    const startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    startDate.setHours(5);
    startDate.setMinutes(30);
    const stDate = JSON.stringify(startDate);
    return stDate.replace(/"/g, '');
  }

  static getEndDate(date) {
    const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    endDate.setHours(29);
    endDate.setMinutes(29);
    endDate.setSeconds(59);
    const enDate = JSON.stringify(endDate);
    return enDate.replace(/"/g, '');
  }

  static isObjEmpty(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  public static setEditorsConfig(editorsConfig) {
    editorsConfig.toolbarGroups = [
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
      { name: 'forms', groups: ['forms'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'colors', groups: ['colors'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
      { name: 'links', groups: ['links'] },
      { name: 'insert', groups: ['insert'] },
      { name: 'styles', groups: ['styles'] },
      { name: 'tools', groups: ['tools'] },
      { name: 'others', groups: ['others'] },
      { name: 'about', groups: ['about'] }
    ];
    editorsConfig.autoGrow_bottomSpace = 50;
    editorsConfig.resize_enabled = false;
    editorsConfig.removePlugins = 'elementspath,magicline';
    editorsConfig.removeButtons = 'Source,Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Scayt,Find,Replace,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,SelectAll,Strike,Subscript,Superscript,Outdent,Indent,Blockquote,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Unlink,Link,Image,Flash,Table,Smiley,SpecialChar,HorizontalRule,PageBreak,Iframe,ShowBlocks,Maximize,Format,Styles,Font,About,CopyFormatting,RemoveFormat';
  }

  dateEndsWith(date): string {
    const loacle = 'en-us';
    const format = date.getDate() < 11 || date.getDate() > 13 ? ['st', 'nd', 'rd', 'th'][Math.min((date.getDate() - 1) % 10, 3)] : 'th';
    return date.getDate() + format + ' ' + date.toLocaleString(loacle, { month: 'short' }) + '  ' + date.getUTCFullYear();
  }
}


