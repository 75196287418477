import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DataService } from '../../../data.service';
import { ApiService } from '../../../shared/api.service';
import { DefaultService } from '../../../utils/default.service';
import { noSpace } from '../../../utils/utilities.function';
import { CcassService } from '../../ccass.service';

@Component({
  selector: 'app-add-salesperson-mapping',
  templateUrl: './add-salesperson-mapping.component.html',
  styleUrls: ['./add-salesperson-mapping.component.scss']
})
export class AddSalespersonMappingComponent implements OnInit {

  noSpace = noSpace

  @Input() isDisplay = false;

  @Input() selectedRow: any;
  @Input() dialogName: any;
  @Input() btnName: any;

  @Output() isDisplayChange = new EventEmitter<boolean>()
  @Output() shoepopupInParent = new EventEmitter();
  @Output() getMappingData = new EventEmitter();

  loading: boolean;
  mappingForm: FormGroup;
  mapping: any = {}

  salespersons: any = []
  listOfSalespersons: any = []
  salespersonsList: any = []
  salesTeam: any = []
  listOfSalesteam: any = []
  salesteamsList: any = []

  constructor(
    private ccassService: CcassService,
    private apiService: ApiService,
    private defaultService: DefaultService,
    private messageService: MessageService,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.loadMappingForm()
    this.getSalespersonData()
    this.getSalesTeamData()
    if(this.selectedRow) {
      this.mapping['salesperson'] = this.selectedRow.salespersonName
      this.mapping['salespersonID'] = this.selectedRow.salespersonId
      this.mapping['team'] = this.selectedRow.teamName
      this.mapping['teamId'] = this.selectedRow.teamId
    }
  }

  loadMappingForm() {
    this.mappingForm = new FormGroup({
      "salesperson": new FormControl('', Validators.required),
      "salespersonID": new FormControl(''),
      "team": new FormControl('', Validators.required),
    })
  }

  getSalespersonData() {
    this.ccassService.ccassfindOne('', 'v1/salespersons').then(response => {
      if (response['status'] && response['status'] === 'success') {
        if(response['salesPersons'] && response['salesPersons'] !== null) {
          this.salespersons = response['salesPersons']
          response['salesPersons'].forEach((obj: any) => {
            this.listOfSalespersons.push({ label: obj['salespersonName'], value: obj['salespersonName'] });
            this.salespersonsList = this.listOfSalespersons
          });
        } else {
        }
      } else {
        // handle failure
      }
    }).catch(err => {
      // handle error
    });
  }

  getSalesTeamData() {
    const url = 'v1/teams'
    this.ccassService.ccassfindOne('', url).then(response => {
      if (response['status'] && response['status'] === 'success') {
        if(response['teams'] && response['teams'] !== null) {
          this.salesTeam = response['teams']
          response['teams'].forEach((obj: any) => {
            this.listOfSalesteam.push({ label: obj['teamName'], value: obj['teamName'] });
            this.salesteamsList = this.listOfSalesteam
          });
        } else {

        }
      } else {
        // this.loading = false;
        // handle failure
      }
    }).catch(err => {
      // handle error
    });
  }

  resetNclose() {
    this.isDisplay = false;
    this.isDisplayChange.emit(this.isDisplay);
  }

  resetNcloseFRSuccess(message: any) {
    this.isDisplay = false;
    this.shoepopupInParent.emit({ message });
    this.isDisplayChange.emit(this.isDisplay);
  }

  onChangeSalesperson(event:any) {
    this.salespersons.forEach((obj: any) => {
      if(obj.salespersonName == event.value) {
        this.mapping.salespersonID = obj.id
        // this.mapping.participantId = obj.id
      }
    })
  }

  addMapping() {
    const formData = { ...this.mappingForm.value };
    const form = {}
    form['salespersonName'] = formData['salesperson']
    form['salespersonId'] = formData['salespersonID']
    form['teamId'] = this.mapping['teamId']

    if (this.btnName === 'SAVE') {
      const url = 'v1/salesperson-mapping'
      this.ccassService.ccassPost(form,url).then(data => {
        if(data['status'] === 'success') {
          this.resetNcloseFRSuccess(data['message']);
        } else {
          this.messageService.add({ severity: 'error', detail: data['message'] ? data['message'] : data['status'] });
          this.loading = false;
        }
      }).catch(err => {
        this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
        this.resetNclose();
        this.loading = false;
      })
    } else if(this.btnName === 'UPDATE')  {
      const url = 'v1/salesperson-mapping/update'
      form['mappingId'] = this.selectedRow.mappingId
      form['teamName'] =  formData['team']
      this.ccassService.ccassPost(form,url).then(data => {
        if(data['status'] === 'success') {
          this.resetNcloseFRSuccess(data['message']);
        } else {
          this.messageService.add({ severity: 'error', detail: data['message'] ? data['message'] : data['status'] });
          this.loading = false;
        }
      }).catch(err => {
        this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
        this.resetNclose();
        this.loading = false;
      })
    }
  }

  onChangeSalesteam(event: any) {
    this.salesTeam.forEach((obj: any) => {
      if(obj.teamName == event.value) {
        this.mapping.teamId = obj.id
        // this.mapping.participantId = obj.id
      }
    })
  }


}
