import { Component, Output, EventEmitter } from '@angular/core';
import { PrivilegeService } from '../../../shared/privilege.service';
import { SIDE_NAVE_TITLES } from '../util-constants';
import { convertToSnakeCase } from '../util-functions';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent {

  @Output() selectedMenu = new EventEmitter<string>();

  isMenuOpen = false;
  selectedItem = localStorage.getItem('selectedItem') ?
  localStorage.getItem('selectedItem') : convertToSnakeCase(SIDE_NAVE_TITLES.salesOverview);

  shareholdingAnalysisKey = convertToSnakeCase(SIDE_NAVE_TITLES.shareholdingAnalysis);
  salesOverviewKey = convertToSnakeCase(SIDE_NAVE_TITLES.salesOverview);
  aumAnalysisKey = convertToSnakeCase(SIDE_NAVE_TITLES.aumAnalysis);
  topCreatorsRedeemersKey = convertToSnakeCase(SIDE_NAVE_TITLES.topCreatorsReedemers);
  salespersonMappingKey = convertToSnakeCase(SIDE_NAVE_TITLES.salespersonMapping);
  clientMappingKey = convertToSnakeCase(SIDE_NAVE_TITLES.clientMapping);
  // CDP changes
  uploadShareHolingKey =  convertToSnakeCase(SIDE_NAVE_TITLES.uploadShareHoling)
  salesPersonKey = convertToSnakeCase(SIDE_NAVE_TITLES.salesPerson);
  salesTeamKey = convertToSnakeCase(SIDE_NAVE_TITLES.salesTeam);
  participantDealerKey =  convertToSnakeCase(SIDE_NAVE_TITLES.participantDealer);
  fundsKey =  convertToSnakeCase(SIDE_NAVE_TITLES.funds)

  canViewSalesPerson = { 'screenName': 'CCASS', 'subModule': 'canViewSalesPerson' };
  canViewSalesTeam = { 'screenName': 'CCASS', 'subModule': 'canViewSalesTeam' };
  canViewParticipantDealer = { 'screenName': 'CCASS', 'subModule': 'canViewParticipantDealer' };
  canViewUploadShareHoldings = { 'screenName': 'CCASS', 'subModule': 'canViewUploadShareHoldings' };
  canViewFunds = { 'screenName': 'CCASS', 'subModule': 'canViewFunds' };

  constructor(
    public privilegeService: PrivilegeService,
  ) { }

  toggleSideMenu(flag) {
    this.isMenuOpen = !flag;

    if (this.isMenuOpen) {
      document.getElementById('side_nav-id').style.width = '4%';
      document.getElementById('init-width').style.width = '4%';
      document.getElementById('container-Id').style.width = '96%';

    } else {
      document.getElementById('init-width').style.width = '17%';
      document.getElementById('side_nav-id').style.width = '17%';
      document.getElementById('container-Id').style.width = '82%';
    }

  }

  onClickTab(index) {
    this.selectedItem = index;
    this.selectedMenu.emit(index);
  }
}
