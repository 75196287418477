import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FilterMatchMode, PrimeNGConfig } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { PrivilegeService } from '../shared/privilege.service';
import { thousands_separators } from '../utils/utilities.function';

@Component({
	selector: 'app-dynamic-table',
	templateUrl: './dynamic-table.component.html',
	styleUrls: ['./dynamic-table.component.scss']
})
export class DynamicTableComponent implements OnInit {
	date: any;
	tableType: any;

	@Input() tableSchema: any;
	@Input() selectedRows: any;
	@Input() actionClick:any

	@Input() emptyMessage: any;
	@Input() cols: any;
	@Input() value: any;
	@Input() dmpdata: any[]
	@Input() scroll: any;
	@Input() resizableColumns: any;
	@Input() filterDropdown: any;
	@Input() filterDateRanges: any;
	@Input() inlineEdit: any;
	@Input() themes: any;
	@Input() disableDelete: any;
	@Input() headerSelectOptions: any;
	@Input() isLoading: boolean;
	@Input() disableDlt: any
	@Input() editEmailConfig = new EventEmitter();

	@Input() fundList: any;
	@Output() getMeTheFundName = new EventEmitter();
	@Output() showDialog = new EventEmitter();
	@Output() summary = new EventEmitter();
	@Output() getDataBasedOnRange = new EventEmitter();
	@Output() getDataBasedOnDate = new EventEmitter();
	@Output() getDataBasedOnHeader = new EventEmitter();
	@Output() geteventData = new EventEmitter()
	@Output() closeCalender = new EventEmitter();
	@Output() editInParent = new EventEmitter();
	@Output() stopInParent = new EventEmitter();
	@Output() viewInParent = new EventEmitter()
	@Output() viewMore = new EventEmitter()
	@Output() viewParams = new EventEmitter()
	@Output() viewLogo = new EventEmitter();
	@Output() updateInParent = new EventEmitter();
	@Output() downloadInParent = new EventEmitter();
	@Output() cancelInParent = new EventEmitter();
	@Output() deleteFromParent = new EventEmitter();
	@Output() UndoFromParent = new EventEmitter();
	@Output() exportExcelInParent = new EventEmitter();
	@Output() exportPdfInParent = new EventEmitter();
	@Output() changeIPSwitch = new EventEmitter();
	@Output() exportInParent = new EventEmitter();
	@Output() editCell = new EventEmitter();
	@Input() canDisplay: any;
	@Input() canExport: any;
	@Input() canHideExportPdf: any;
	@Input() exportCSVFileName: any;
	audio: any;
	clickPlay: boolean;
	audioEnd: boolean;
	playIndex: string;
	@ViewChild(Calendar, { static: false }) calendar: Calendar;
	checked: boolean;
	@Input() canEdit: boolean;
	@Input() type: any

	canEditEnumConfigDetails = { 'screenName': 'OPS', 'subModule': 'canEditEnumConfigDetails' };
	canEditCodeConfigDetails = { 'screenName': 'RISK', 'subModule': 'canEditCodeConfigDetails' };
	canDeleteAssetClassConfig = { 'screenName': 'RISK', 'subModule': 'canDeleteAssetClassConfig' }
	canEditAssetClassConfig = { 'screenName': 'RISK', 'subModule': 'canEditAssetClassConfig' }
	canEditCreditRating = { 'screenName': 'RISK', 'subModule': 'canEditCreditRating' }
	canDeleteCreditRating = { 'screenName': 'RISK', 'subModule': 'canDeleteCreditRating' }
	canEditDcConfigDetails = { 'screenName': 'OPS', 'subModule': 'canEditDcConfigDetails' };
	canEditHolidayCalendar = { 'screenName': 'OPS', 'subModule': 'canEditHolidayCalendar' };
	canEditCAConfigDetails = { 'screenName': 'RISK', 'subModule': 'canEditCAConfigDetails' };
	canViewCAConfigDetails = { 'screenName': 'RISK', 'subModule': 'canViewCAConfigDetails' };
	canEditNewsletterConfigDetails = { 'screenName': 'NewsLetters', 'subModule': 'canEditNewsletterConfigDetails' };
	canEditNewsletterMappingDetails = { 'screenName': 'NewsLetters', 'subModule': 'canEditNewsletterMappingDetails' };
	// Edit Liquidity Configs Privilages
	canAccessEditLiquidityProfileNonMmfThresholdConfig = { 'screenName': 'Liquidity', 'subModule': 'canAccessEditLiquidityProfileNonMmfThresholdConfig' };
	canAccessEditLiquidityProfileMmfThresholdConfig = { 'screenName': 'Liquidity', 'subModule': 'canAccessEditLiquidityProfileMmfThresholdConfig' };
	canAccessEditLiquidationCostThresholdConfig = { 'screenName': 'Liquidity', 'subModule': 'canAccessEditLiquidationCostThresholdConfig' };
	// Delete Liquidity Configs Privilages
	canAccessDeleteLiquidityProfileNonMmfThresholdConfig = { 'screenName': 'Liquidity', 'subModule': 'canAccessDeleteLiquidityProfileNonMmfThresholdConfig' };
	canAccessDeleteLiquidityProfileMmfThresholdConfig = { 'screenName': 'Liquidity', 'subModule': 'canAccessDeleteLiquidityProfileMmfThresholdConfig' };
	canAccessDeleteLiquidationCostThresholdConfig = { 'screenName': 'Liquidity', 'subModule': 'canAccessDeleteLiquidationCostThresholdConfig' };
	// Edit Fund
	canEditFund = { 'screenName': 'ManageFund', 'subModule': 'canEditFund' };
	// Salesperson Screens
	canEditSalesPerson = { 'screenName': 'CCASS', 'subModule': 'canEditSalesPerson' };
	canDeleteSalesPerson = { 'screenName': 'CCASS', 'subModule': 'canDeleteSalesPerson' };
	// Sales Team Screens
	canEditSalesTeam = { 'screenName': 'CCASS', 'subModule': 'canEditSalesTeam' };
	canDeleteSalesTeam = { 'screenName': 'CCASS', 'subModule': 'canDeleteSalesTeam' };
	// CCASS PD Screens
	canEditParticipantDealer = { 'screenName': 'CCASS', 'subModule': 'canEditParticipantDealer' };
	canDeleteParticipantDealer = { 'screenName': 'CCASS', 'subModule': 'canDeleteParticipantDealer' };
	// CCASS fund Screen
	canEditCcassFund = { 'screenName': 'CCASS', 'subModule': 'canEditFund' };

	checkBoxSelection: any;
	pageIndex: any;
	isChecked: boolean
	target: any;
	check: any;



	constructor(public privilegeService: PrivilegeService,
		private config: PrimeNGConfig) { }

	ngOnInit() {
		//this.isLoading =true
		this.audio = new Audio();
		this.audioEnd = false;
		this.tableSchema['rowsCount'] = this.tableSchema?.rowsCount || 10;
		if (this.canDisplay === undefined) {
			this.canDisplay = true;
		}
		this.isChecked = false
		this.checked = false
	}

	changeInputSwitch(event, id, rowData) {
		this.changeIPSwitch.emit({ event: event, id: id, rowData: rowData });
	}

	exportExcel(data: any) {
		this.exportExcelInParent.emit(data);
	}

	getFilteredOutput(event, dt) {
		this.geteventData.emit(event)
	}

	exportPdf(columns: any, data: any) {
		this.exportPdfInParent.emit({ columns: columns, data: data });
	}

	getStyleClass(col, rowData) {
		let className;
		col.styleProps.forEach(element => {
			if (element.checkValue === rowData[col.field]) {
				className = element.class;
			}
		});
		return className;
	}

	onSelectDateRange(dates) {
		if (dates[0] && dates[1]) {
			this.getDataBasedOnRange.emit({ dates: dates });
			this.calendar.hideOverlay();
		}
	}

	onSelectDate(date) {
		if (date) {
			this.getDataBasedOnDate.emit(date);
			this.calendar.hideOverlay();
		}
	}

	onClickClose() {
		this.closeCalender.emit();
	}

	onSelectRow() {
		let newList = []
		if (this.type === 'Trading_Holding' || this.type === 'Ccy_Settlement_Holding' || this.type === 'OPS_Holiday') {
			this.selectedRows.forEach(element => {
				this.value.forEach(element1 => {
					if (element.holiday_id === element1.holiday_id) {
						newList.push(element1);
					}
				});
			});
		}
		else if (this.type === 'Odetails') {
			this.selectedRows.forEach(element => {
				this.value.forEach(element1 => {
					if (element.order_id === element1.order_id) {
						newList.push(element1);
					}
				});
			});
		} else if(this.type === 'newsletter_report') {
			this.selectedRows.forEach(element => {
				this.value.forEach(element1 => {
					if(element.reportID == element1.reportID) {
						newList.push(element1)
					}
				})
			})
		}
		else {
			this.selectedRows.forEach(element => {
				this.value.forEach(element1 => {
					if (element.fund_id === element1.fund_id) {
						newList.push(element1);
					}
				});
			});
		}

		// this.selectedRows = [...newList];
		this.updateInParent.emit([...newList]);
		if (this.type === 'Odetails') {
			localStorage.setItem('dcSelectedRows', JSON.stringify([...newList]))
		}
		else {
			localStorage.setItem('selectedRows', JSON.stringify([...newList]));
		}
	}

	thousandSeparators(value) {
		return thousands_separators(value);
	}
	onRowUnselect(row) {
		let list = []
		if (this.type === 'Odetails') {
			this.selectedRows = JSON.parse(localStorage.getItem('dcSelectedRows'));
			list = this.selectedRows.filter(obj => row.data.order_id !== obj.order_id);
			this.selectedRows = [...list];
			localStorage.setItem('dcSelectedRows', JSON.stringify(this.selectedRows));
		}
		else {
			this.selectedRows = JSON.parse(localStorage.getItem('selectedRows'));
			if (this.type === 'Trading_Holding' || this.type === 'Ccy_Settlement_Holding' || this.type === 'OPS_Holiday') {
				list = this.selectedRows.filter(obj => row.data.holiday_id !== obj.holiday_id);
			} else if(this.type == 'newsletter_report') {
				list = this.selectedRows.filter(obj => row.data.reportID !== obj.reportID);
			}
			else {
				list = this.selectedRows.filter(obj => row.data.tradar_code !== obj.tradar_code);
			}

			this.selectedRows = [...list];


			localStorage.setItem('selectedRows', JSON.stringify(this.selectedRows));
		}
		this.updateInParent.emit(this.selectedRows);
	}
	selectAllRows() {
		if (this.type === 'Odetails') {
			localStorage.setItem('dcSelectedRows', JSON.stringify(this.selectedRows));
		}
		else {
			localStorage.setItem('selectedRows', JSON.stringify(this.selectedRows));
		}
		this.updateInParent.emit(this.selectedRows);
	}
	onClickEdit(row, index) {
		this.editInParent.emit({ row: row, index: index });
	}
	onClickStop(row,field) {
		this.stopInParent.emit({ row: row, field: field });
	}
	onClickView(row, index) {
		let selectedFund = [];
		selectedFund.push(row);
		if (this.type === 'Odetails') {
			localStorage.setItem('dcSelectedRows', JSON.stringify(selectedFund));
		}
		else {
			localStorage.setItem('selectedRows', JSON.stringify(selectedFund));
		}
		this.viewInParent.emit({ row: row, index: index });
	}
	onClickViewMore(row, index, field) {
		this.viewMore.emit({ row: row, index: index, field: field });
	}

	onClickViewParams(row, index) {
		this.viewParams.emit({ row: row, index: index });
	}

	onClickViewLogo(row, index) {
		this.viewLogo.emit({ row: row, index: index });
	}

	onClickUpdate(data) {
		this.updateInParent.emit(data);
	}

	onClickCancel() {
		this.cancelInParent.emit();
	}

	onClickDelete(data) {


		this.deleteFromParent.emit(data);
	}

	onDeleteClick(data: any, index: any) {
		// if(this.privilegeService.isAuthorized(this.canDeleteReconConfigDetails))
		this.deleteFromParent.emit({ row: data, index: index });
	}
	onUndoeClick(data: any, index: any) {
		this.UndoFromParent.emit({ row: data, index: index });
	}

	onClickLog(data) {
		this.summary.emit(data);
	}
	test(data) {
		this.showDialog.emit(data);
	}

	getFundName(data) {
		const fund = this.fundList.filter(obj => obj['_id'] === data);
		return fund.length > 0 ? fund[0]['name'] : '';
	}

	convertLang(language: any) {
		let lang: any;
		if (language && language.length > 0) {
			language.forEach(obj => {
				switch (obj) {
					case 'EN':
						lang = 'English';
						break;
					case 'TCH':
						lang = lang
							? lang + ',' + 'Traditional Chinese'
							: 'Traditional Chinese';
						break;
				}
			});
		}
		return lang;
	}

	showFundNames(funds: any) {
		let fund:any
		fund = funds.map(fund => fund.label)
		return fund
	}
	
	download(document) {
		window.open(document['url'], '_blank');
	}

	onClickDownload(data) {
		this.downloadInParent.emit(data);
	}

	onClickPlay(audioDoc, index) {
		this.audioEnd = false;
		this.playIndex = index;
		this.audio.src = audioDoc.url;
		this.audio.load();
		this.audio.play();
		const method = this;
		this.audio.addEventListener('ended', function () {
			method.callMethod();
		}, false);
	}

	onClickPause(audioDoc, index) {
		this.audio.pause();
		this.audioEnd = true;
	}

	callMethod() {
		this.audioEnd = true;
	}

	showStop(index) {
		if (index === this.playIndex && !this.audioEnd) {
			return true;
		}
		return false;
	}

	canView(col) {
		return ((this.canDisplay && col['display']) || col['display'] || this.canDisplay);
	}
	getMeToFileInfo(file: any) {
		if (file.tradar_code !== 'CO-A50F') {
			return;
		}
		this.editInParent.emit(file);
	}
	onClickExport(row, label) {
		this.exportInParent.emit({ row: row, label: label });
	}



	onEditInit(event): void {
		this.check = null
		if (event.field == 'value') {
			this.check = event.data.value
		}
		else if (event.field == 'fxrate') {
			this.check = event.data.fxRate
		}

	}

	onEditCancel(): void {

	}

	onEditComplete(event: any) {
		this.target = event.originalEvent.target.toString()
		if (event.field === 'value') {
			if (this.check !== event.data.value) {
				this.editCell.emit({ data: event.data })
			}

		}
		else if (event.field === 'fxrate') {
			if (this.check !== event.data.fxRate) {
				this.editCell.emit({ data: event.data })
			}
		}
	}


}