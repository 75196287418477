import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DataService } from '../../../data.service';
import { ApiService } from '../../../shared/api.service';
import { DefaultService } from '../../../utils/default.service';
import { noSpace } from '../../../utils/utilities.function';
import { CcassService } from '../../ccass.service';

@Component({
  selector: 'app-add-sales-person',
  templateUrl: './add-sales-person.component.html',
  styleUrls: ['./add-sales-person.component.scss']
})
export class AddSalesPersonComponent implements OnInit {

  noSpace = noSpace

  @Input() isDisplay = false;

  @Input() selectedRow: any;
  @Input() dialogName: any;
  @Input() btnName: any;
  @Output() isDisplayChange = new EventEmitter<boolean>()
  @Output() getSalespersonData = new EventEmitter();
  @Output() shoepopupInParent = new EventEmitter();

  loading: boolean;
  salesPersonForm: FormGroup;

  salesPerson:any = {}

  userListOpts: any = []
  userlist: any = []
  message: any;

  constructor(
    private ccassService: CcassService,
    private apiService: ApiService,
    private defaultService: DefaultService,
    private messageService: MessageService,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.loadsalesForm()
    this.getUses()
    if(this.selectedRow) {
      this.salesPerson['salesperson'] = this.selectedRow.salespersonName
      this.salesPerson['user'] = (this.selectedRow.username && this.selectedRow.username !== '' && this.selectedRow.username !== undefined) ?  this.selectedRow.username : null;
    }
  }

  resetNclose() {
    this.isDisplay = false;
    this.isDisplayChange.emit(this.isDisplay); 
  }

  resetNcloseFRSuccess(message:any) {
    this.isDisplay = false;
    this.shoepopupInParent.emit({message});
    this.isDisplayChange.emit(this.isDisplay); 
  }
  loadsalesForm() {
    this.salesPersonForm = new FormGroup({
      "salesperson": new FormControl('',Validators.required),
      "user": new FormControl(''),
    })
  }

  addSalesPerson() {
    const formData = { ...this.salesPersonForm.value };
    const form = {}
    form['salespersonName'] = formData['salesperson']
    form['username'] = formData['user']
    // if (form['user'] == null || form['user'] == undefined) delete form['user'];
    if (this.btnName === 'SAVE') {
      const url = 'v1/salespersons/create'
      this.ccassService.ccassPost(form,url).then(data => {
          if(data['status'] === 'success') {
            this.resetNcloseFRSuccess(data['message']);
          } else {
            this.messageService.add({ severity: 'error', detail: data['message'] ? data['message'] : data['status'] });
            this.loading = false;
          }
        }).catch(err => {
          this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
          this.resetNclose();
          this.loading = false;
        })
    } else if(this.btnName === 'UPDATE') {
      const url = 'v1/salespersons/update'
      form['id'] =  this.selectedRow['id']
      this.ccassService.ccassUpdateTeam(form,url).then(data => {
        if(data['status'] == 'success') {
          this.resetNcloseFRSuccess(data['message']);
        } else {
          this.messageService.add({ severity: 'error', detail: data['message'] ? data['message'] : data['status'] });
          this.loading = false;
        }
      }).catch(err => {
        this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
        // this.resetNclose();
        this.loading = false;
      })
    }
  }

  getUses() {
    const roleCodes = 'SALES'
    const url = 'users'
    this.ccassService.ccasfindSerice({roleCodes}, 1, 1000, url).then(response => {
      if (response['status'] && response['status'] === 'success') {
        response['rows'].forEach((obj: any) => {
            this.userListOpts.push({ label: obj['loginName'], value: obj['loginName'] });
            this.userlist = this.userListOpts
      });  
        this.loading = false;
      } else {
        this.loading = false;
        // handle failure
      }
    }).catch(err => {
        this.loading = false;
      // handle error
    });
  }

}
