import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DefaultService } from '../../../utils/default.service';
import { ApiService } from '../../../shared/api.service';
import { TableSchema } from '../../../utils/table-schema';
import { CcassService } from '../../ccass.service';
import { SIDE_NAVE_TITLES } from '../../utils/util-constants';
import { convertToSnakeCase } from '../../utils/util-functions';
import { DataService } from '../../../data.service';
import { PrivilegeService } from '../../../shared/privilege.service';

@Component({
  selector: 'app-upload-share-holdings',
  templateUrl: './upload-share-holdings.component.html',
  styleUrls: ['./upload-share-holdings.component.scss']
})
export class UploadShareHoldingsComponent implements OnInit {

  tableColumns = TableSchema['upload-Holding'];

  @Output() titleHeading = new EventEmitter<string>();
  @Output() shouldDisplayTitle = new EventEmitter<boolean>();

  pageTitle: string = SIDE_NAVE_TITLES.uploadShareHoling;
  loading = false;

  uploadShareHolingKey = convertToSnakeCase(SIDE_NAVE_TITLES.uploadShareHoling);
  listOfHolidings: any = []
  viewAddHolding: boolean;
  headerSelectOptions: any = {}

  canUploadShareHoldings = { 'screenName': 'CCASS', 'subModule': 'canUploadShareHoldings' };

  constructor(
    private ccassService: CcassService,
    private apiService: ApiService,
    private defaultService: DefaultService,
    private messageService: MessageService,
    private dataService: DataService,
    public privilegeService: PrivilegeService,
    ) { }

  ngOnInit(): void {
    this.loading = true;
    this.titleHeading.emit(this.pageTitle);
    this.shouldDisplayTitle.emit(false);
    this.getshareHolidingsData()
  }

  getshareHolidingsData() {
    const url = 'v1/get/manualupload/info'
    this.ccassService.ccassfindOne('',url).then(response => {
      if (response['status'] && response['status'] === 'success') {
        if(response['data'] && response['data'] !== null) {
          this.listOfHolidings = response['data'];
          this.setHeaderSelectOptions(response['data']) 
        } else {
          this.listOfHolidings = []
          this.setHeaderSelectOptions([]) 
        }
      } else {
        this.messageService.add({ severity: 'error', detail: response['status'] });
        this.loading = false;
      }
    }).catch(err => {
      if (err.status == 500) {
        this.messageService.add({ severity: 'error', detail: err['statusText'] ? err['statusText'] : 'Internal server error' });
      }
      else if (err.status == 502) {
        this.messageService.add({ severity: 'error', detail: err.status ? err.status + 'Bad Gateway' : '502 Bad Gateway' });
      } else {
        this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
      }
      this.loading = false;
      // handle error
    });
  }


  onUploadClick() {
    this.viewAddHolding = true;
  }

  setHeaderSelectOptions(data:any) {
    let filenameOptions = [{ label: 'All', value: null }]
    let statusOptions = [{ label: 'All', value: null }]
    if (data.length > 0) {
      data?.forEach((d:any) => {
        if(d.FileName) { filenameOptions.push({ label: d.FileName, value: d.FileName });}
        if(d.JobStatus) { statusOptions.push({ label: d.JobStatus, value: d.JobStatus });}
      })
      const uniqueFilenameOptions = [...new Set(filenameOptions.map(item => item.label))];
      const uniqueStatusOptions = [...new Set(statusOptions.map(item => item.label))];
      filenameOptions = []; statusOptions = []
      uniqueFilenameOptions?.forEach((element: any) => filenameOptions.push({ label: element, value: element == 'All' ? null : element }));
      uniqueStatusOptions?.forEach((element: any) => statusOptions.push({ label: element, value: element == 'All' ? null : element }));
      this.headerSelectOptions['FileName'] = filenameOptions;
      this.headerSelectOptions['JobStatus'] = statusOptions;
      this.loading = false
    } else {
      this.headerSelectOptions['FileName'] = filenameOptions;
      this.headerSelectOptions['JobStatus'] = statusOptions;
      this.loading = false
    }
  }

  onRefresh() {
    this.loading = true;
    this.getshareHolidingsData()
  }

}
