export const Privileges = {
	'AddUser': {
		'canAddUserGroup': 'USER_ROLE_USER_GROUP'
	},
	
	'Admin': {
		'encryptParams': 'ENCRYPT_PARAMS'
	},

	'ProfileBar': {
		'canPickTheDate': 'PROFILE_DATE_PICKER'
	},

	'CaseCreate': {
		'canCreateNewCase': 'CASE_CREATE'
	},
	'CaseApproval': {
		'canUploadLCSign': 'CASE_UPLOAD_LC_SIGNATURE'
	},
	'CaseSupportDoc': {
		'canViewLCSupportDoc': 'CASE_LC_SUPPORT_DOCS',
		'canManageSupportDoc': 'CASE_MANAGE_SUPPORT_DOC'
	},

	'CaseCICorpLCForm': {
		'canEditCICorpLCForm': 'CI_CORP_LC_DPRT_EDIT'
	},

	'CaseDashboard': {
		'canViewTheOwnerName': 'CASE_OWNER_DISPLAY',
		'canManageTheOwner': 'CASE_MANAGE_OWNER',
		'canViewKycDashboard': 'KYC_DASHBOARD'
	},

	'CaseDetails': {
		'canViewCaseDetails': 'CASE_DETAILS_VIEW',
		'canARMAssignCase': 'CASE_ARM_ASSIGN',
		'canEditCaseName': 'CASE_NAME_EDIT',
		'canEditCaseID': 'CASE_ID_EDIT',
		'canEditCaseForms': 'CASE_FORMS_EDIT',
		'canRMBrowseCaseByURL': 'CASE_RM_BROWSE_BY_URL',
		'canARMBrowseCaseByURL': 'CASE_ARM_BROWSE_BY_URL',
		'canBOBrowseCaseByURL': 'CASE_BO_BROWSE_BY_URL',
		'canTHBrowseCaseByURL': 'CASE_TH_BROWSE_BY_URL',
		'canLCBrowseCaseByURL': 'CASE_LC_BROWSE_BY_URL',
		'canLCRBrowseCaseByURL': 'CASE_LCR_BROWSE_BY_URL',
		'canHWMBrowseCaseByURL': 'CASE_HWM_BROWSE_BY_URL'
	},

	'AuditTrail': {
		'canViewAuditTrail': 'AUDIT_TRAIL',
		'canViewNewsletterAuditTrail': 'NEWSLETTER_AUDIT_TRAIL',
		'canViewKycAuditTrail': 'KYC_AUDIT_TRAIL',
		'canViewRiskAuditTrail': 'RISK_AUDIT_TRAIL',
		'canViewOperationsAuditTrail': 'OPS_AUDIT_TRAIL'
	},

	'FundDetails': {
		'canViewFundInf': 'FUND_INFO_VIEW',
		'canViewFundPerf': 'FUND_PERFORMANCE_VIEW',
		'canViewFundTDTE': 'FUND_TDTE_VIEW',
		'canViewFundHoldings': 'FUND_HOLDINGS_VIEW',
		'canViewFundTop10Holdings': 'FUND_TOP10_HOLDINGS_VIEW',
		'canViewEEEquityHoldings': 'EE_EQUITY_HOLDINGS_VIEW',
		'canViewEMEquityHoldings': 'EM_EQUITY_HOLDINGS_VIEW',
		'canViewCEEquityHoldings': 'CE_EQUITY_HOLDINGS_VIEW',
		'canViewBEEquityHoldings': 'BE_BOND_HOLDINGS_VIEW',
		'canViewBMEquityHoldings': 'BM_BOND_HOLDINGS_VIEW',
		'canViewFundFutContractsHoldings': 'FUND_FUT_CONTR_HOLDINGS_VIEW',
		'canViewFundLiq': 'FUND_LIQUIDATION_VIEW',
		'canViewFundFairValue': 'FUND_FAIR_VALUE_VIEW',
		'canViewFundHistRep': 'FUND_HIST_REPORT_VIEW',
		'canViewFundStopLoss': 'FUND_STOP_LOSS_VIEW',
		'canViewFundRetreatFromPeak': 'FUND_RETREAT_FROM_PEAK_VIEW',
		'canViewFundStressTest': 'FUND_STRESS_REPORT_VIEW',
		'canViewFundAnalytics': 'FUND_ANALYTICS_VIEW',
		'canViewFundFinance': 'FUND_FINANCE_VIEW',
		'canManagePortfolio': 'PORTFOLIO_MANAGEMENT',
		'canViewPeerFundReport': 'PEER_FUND_VIEW',
	},
	'ManageFund': {
		'canEditFund': 'EDIT_FUND',
		'canAccessOpsAttributes': 'MNG_FUND_OPS_ATTRIBUTES',
		'canAccessStaticNote': 'MNG_FUND_RISK_ATTRIBUTES',
		'canSkipSignOffCutOffCheck': 'SKIP_SIGN_OFF_CUT_OFF_CHECK',
		'canDeleteShareClass': 'DELETE_SHARE_CLASS',
		'canAccessNewsLetterAttribute':'SHARE_CLASS_NEWSLETTER_ATTRIBUTES',
		'canDeleteIndexInfo': 'DELETE_INDEX_INFO'
	},

	'Quant': {
		'canViewTradeDates': 'QUANT_TRADE_DATES',
		'canViewFxRate': 'QUANT_FX_RATE',
		'canAdjustFairValue': 'QUANT_ADJUST_FAIR_VALUE',
		'canViewAccMap': 'QUANT_ACC_MAPPING',
		'canViewBkIntrstMntnce': 'QUANT_BK_INTRST_MNTNCE',
		'canViewFeeMntnce': 'QUANT_FEE_MNTNCE',
		'canViewExpFilesLog': 'QUANT_EXP_FILES_LOG',
	},

	'Users': {
		'canDeleteUser': 'USER_DELETE',
		'canUpdateUser': 'USER_UPDATE',
		'canUpdateUserRole': 'USER_UPDATE_ROLE'
	},
	'NewsLetters': {
		'canCreateAndModifyNewsletter': 'NEWSLETTER_CREATE',
		'canEditNewsletterModifyContent': 'NEWSLETTER_MODIFY_CONTENT',
		'canDeleteNewsletter': 'NEWSLETTER_DELETE',
		'canRequestForNewsletterApproval': 'NEWSLETTER_REQUEST_FOR_APPROVAL',
		'canApproveNewsletter': 'NEWSLETTER_APPROVE',
		'canReturnNewsletter': 'NEWSLETTER_RETURN',
		'canAccessNewsletterConfig': 'NEWSLETTER_CONFIG',
		'canEditNewsletterConfigDetails':'EDIT_NEWSLETTER_CONFIG',
		'canAddNewsletterConfigDetails':'ADD_NEWSLETTER_CONFIG',
		'canEditNewsletterMappingDetails':'EDIT_NEWSLETTER_MAPPING',
		'canAddNewsletterMappingDetails':'ADD_NEWSLETTER_MAPPING',
	},
	'Liquidity': {
		'canViewLiquidityChart':'LIQUIDITY_CARD',
		// Liquidity Profile Non-Mmf Threshold Config
		'canAccessLiquidityProfileNonMmfThresholdConfig': 'LIQUIDITY_PROFILE_NON_MMF_THRESHOLD_CONFIG',
		'canAccessAddLiquidityProfileNonMmfThresholdConfig': 'ADD_LIQUIDITY_PROFILE_NON_MMF_THRESHOLD_CONFIG',
		'canAccessEditLiquidityProfileNonMmfThresholdConfig': 'EDIT_LIQUIDITY_PROFILE_NON_MMF_THRESHOLD_CONFIG',
		'canAccessDeleteLiquidityProfileNonMmfThresholdConfig': 'DELETE_LIQUIDITY_PROFILE_NON_MMF_THRESHOLD_CONFIG',
		// Liquidity Profile Mmf Threshold Config
		'canAccessLiquidityProfileMmfThresholdConfig': 'LIQUIDITY_PROFILE_MMF_THRESHOLD_CONFIG',
		'canAccessAddLiquidityProfileMmfThresholdConfig': 'ADD_LIQUIDITY_PROFILE_MMF_THRESHOLD_CONFIG',
		'canAccessEditLiquidityProfileMmfThresholdConfig': 'EDIT_LIQUIDITY_PROFILE_MMF_THRESHOLD_CONFIG',
		'canAccessDeleteLiquidityProfileMmfThresholdConfig': 'DELETE_LIQUIDITY_PROFILE_MMF_THRESHOLD_CONFIG',
		// Liquidation Cost Threshold Config
		'canAccessLiquidationCostThresholdConfig': 'LIQUIDATION_COST_THRESHOLD_CONFIG',
		'canAccessAddLiquidationCostThresholdConfig': 'ADD_LIQUIDATION_COST_THRESHOLD_CONFIG',
		'canAccessEditLiquidationCostThresholdConfig': 'EDIT_LIQUIDATION_COST_THRESHOLD_CONFIG',
		'canAccessDeleteLiquidationCostThresholdConfig': 'DELETE_LIQUIDATION_COST_THRESHOLD_CONFIG',


	},
	'Dashboard': {
		'canViewFLLByExposure': 'DASHBOARD_FLL_BY_EXPOSURE',
		'canViewFLLByAssetClass': 'DASHBOARD_FLL_BY_ASSETCLASS',
		'canViewFirmLevelAUM': 'DASHBOARD_FIRMLEVEL_AUM',
		'canViewFirmLevelSuspendedStocks': 'DASHBOARD_FIRMLEVEL_SUSPENDED_STOCKS',
		'canViewByAUMFundType': 'DASHBOARD_AUM_BY_FUNDTYPE',
		'canViewFundList': 'DASHBOARD_FUND_LIST',
		'canViewCCiFrame': 'DASHBOARD_CC_IFRAME'
	},
	'OPS': {
		'canAccessOpsDashboard': 'OPS_DASHBOARD',
		'canAccessSecurityTypes': 'ACCOUNT_MAPPING_SEC_TYPES',
		'canAccessLevelAndCategory': 'ACCOUNT_MAPPING_LVL_CATEGORY',
		'canAccessAccountMapping': 'ACCOUNT_MAPPING_ACCOUNT_MAPPING',
		'canAccessNAVOneReconWidget': 'NAV_ONE_RECONCILIATION',
		'canAccessNAVTwoReconWidget': 'NAV_TWO_RECONCILIATION',
		'canAccessPDCCReconWidget': 'PD_CC',
		'canAccessUnsignOff': 'UNSIGN_OFF',
		'canAccessSignOff': 'SIGN_OFF',
		'canAccessRerun': 'RERUN',
		'canAccessNAVOneReconConfig': 'NAV_ONE_CONFIG',
		'canAccessNAVTwoReconConfig': 'NAV_TWO_CONFIG',
		'canAccessOpsPrices': 'PRICES',
		'canAccessOpsFairValue': 'FAIR_VALUE',
		'canAccessOpsFxRates': 'OPS_FX_RATES_EXTRA_COLS',
		'canEditAccountMapping': 'ACCOUNT_MAPPING_EDIT',
		'canDeleteAccountMapping': 'ACCOUNT_MAPPING_DELETE',
		'canDeleteReconConfigDetails':'RECON_CONFIG_DETAILS_DELETE',
		'canViewEnumConfigDetails':'VIEW_ENUM_CONFIG',
		'canEditEnumConfigDetails':'EDIT_ENUM_CONFIG',
		'canAddEnumConfigDetails':'ADD_ENUM_CONFIG',
		'canViewDcConfigDetails':'VIEW_DC_CONFIG',
		'canEditDcConfigDetails':'EDIT_DC_CONFIG',
		'canAddDcConfigDetails':'ADD_DC_CONFIG',
        'canEditHolidayCalendar': 'EDIT_HOLIDAY_CALENDER',
        'canAddHolidayCalendar': 'ADD_HOLIDAY_CALENDER',
		'canAccessDcCalculation': 'D&C_CALCULATION',
		'canAccessDaySignOff': 'DAY_SIGN_OFF',
		'canAccessEndSignOff': 'END_SIGN_OFF',
		'canAccessDcUnSignOff': 'DC_UN_SIGN_OFF',
		'canAccessRun': 'RUN',
	},
	'RISK': {
		'canAddCAConfigDetails':'ADD_CA_CONFIG',
		'canViewCAConfigDetails':'VIEW_CA_CONFIG',
		'canEditCAConfigDetails':'EDIT_CA_CONFIG',
		'canViewCodeConfigDetails':'VIEW_CODE_CONFIG',
		'canEditCodeConfigDetails':'EDIT_CODE_CONFIG',
		'canAddCodeConfigDetails':'ADD_CODE_CONFIG',
		'canDeleteAssetClassConfig':'DELETE_ASSET_CLASS_CONFIG',
		'canAddAssetClassConfig':'ADD_ASSET_CLASS_CONFIG',
		'canEditAssetClassConfig':'EDIT_ASSET_CLASS_CONFIG',
		'canDeleteCodeConfigDetails':'DELETE_CODE_CONFIG',
		'canViewTdTeChart':'TD_TE_CARD',
		'canAccessTdTeSignOff': 'TD_TE_SIGN_OFF',
		'canAccessTdTeUnSignOff': 'TD_TE_UN_SIGN_OFF',
		'canDeleteCreditRating':'DELETE_CREDIT_RATING',
		'canAddCreditRating':'ADD_CREDIT_RATING',
		'canEditCreditRating':'EDIT_CREDIT_RATING',
	},
	'CCASS' : {
		'canViewSalesPerson': 'CCASS_SALES_PERSON',
		'canViewSalesTeam': 'CCASS_SALES_TEAM',
		'canViewParticipantDealer': 'CCASS_PARTICIPANT_DEALER',
		'canViewUploadShareHoldings': 'CCASS_UPLOAD_SHARE_HOLDINGS',
		'canViewFunds': 'CCASS_FUNDS',
		// Sales Person Screens
		'canAddSalesPerson': 'ADD_CCASS_SALES_PERSON',
		'canEditSalesPerson': 'EDIT_CCASS_SALES_PERSON',
		'canDeleteSalesPerson': 'DELETE_CCASS_SALES_PERSON',
		// Sales Team Screens
		'canAddSalesTeam': 'ADD_CCASS_SALES_TEAM',
		'canEditSalesTeam': 'EDIT_CCASS_SALES_TEAM',
		'canDeleteSalesTeam': 'DELETE_CCASS_SALES_TEAM',
		// Upload Share Holdings Screens
		'canUploadShareHoldings': 'CCASS_UPLOAD_NEW_SHARE_HOLDINGS',
		// participant Dealer Screens
		'canEditParticipantDealer': 'EDIT_CCASS_PARTICIPANT_DEALER',
		'canDeleteParticipantDealer': 'DELETE_CCASS_PARTICIPANT_DEALER',
		// Fund Screens
		'canEditFund': 'EDIT_CCASS_FUNDS',
	}

};


/*'canAccessTradingCalendar': 'TRADING_CALENDAR',
        'canAccessSettlementCalendar': 'SETTLEMENT_CALENDAR',
        'canAccessCcyCalendarCode': 'CCY_CAL_CODE_MAPPING',
        'canEditHolidayCalendar': 'EDIT_HOLIDAY_CALENDER',
        'canAddHolidayCalendar': 'ADD_HOLIDAY_CALENDER',
-- NEED TO INCLUDE THIS IN RM, ARM, BO ROLE IN CONFIG_V2
{
"name" : "USER_ROLE_USER_GROUP",
"type" : "SUB_MODULE",
"enumType" : "PRIVILEGE",
"companyID" : "CSOP",
"value" : "Y"
}

 -- NEED TO INCLUDE THIS IN RM, ARM, BO ROLE IN CONFIG_V2
{
"name" : "CASE_FORMS_EDIT",
"type" : "SUB_MODULE",
"enumType" : "PRIVILEGE",
"companyID" : "CSOP",
"value" : "Y"
}

 -- NEED TO INCLUDE THIS IN LC & LC REVIEWER ROLE IN CONFIG_V2
{
"name" : "CASE_UPLOAD_LC_SIGNATURE",
"type" : "SUB_MODULE",
"enumType" : "PRIVILEGE",
"companyID" : "CSOP",
"value" : "Y"
}
-- NEED TO INCLUDE THIS IN LC & LC REVIEWER ROLE IN CONFIG_V2
{
"name" : "CASE_LC_SUPPORT_DOCS",
"type" : "SUB_MODULE",
"enumType" : "PRIVILEGE",
"companyID" : "CSOP",
"value" : "Y"
}

-- NEED TO INCLUDE THIS IN LC & LC REVIEWER ROLE IN CONFIG_V2
{
"name" : "CI_CORP_LC_DPRT_EDIT",
"type" : "SUB_MODULE",
"enumType" : "PRIVILEGE",
"companyID" : "CSOP",
"value" : "Y"
}

-- NEED TO INCLUDE THIS IN RM & BO ROLES IN CONFIG_V2
{
"name" : "CASE_NAME_EDIT",
"type" : "SUB_MODULE",
"enumType" : "PRIVILEGE",
"companyID" : "CSOP",
"value" : "Y"
}


-- NEED TO INCLUDE THIS IN BO ROLES IN CONFIG_V2
{
"name" : "CASE_ID_EDIT",
"type" : "SUB_MODULE",
"enumType" : "PRIVILEGE",
"companyID" : "CSOP",
"value" : "Y"
}

-- NEED TO INCLUDE THIS IN RM & BO ROLES IN CONFIG_V2
{
"name" : "CASE_MANAGE_SUPPORT_DOC",
"type" : "SUB_MODULE",
"enumType" : "PRIVILEGE",
"companyID" : "CSOP",
"value" : "Y"
}


-- NEED TO INCLUDE THIS IN RM ROLES IN CONFIG_V2
{
"name" : "CASE_RM_BROWSE_BY_URL",
"type" : "SUB_MODULE",
"enumType" : "PRIVILEGE",
"companyID" : "CSOP",
"value" : "Y"
}

-- NEED TO INCLUDE THIS IN ARM ROLES IN CONFIG_V2
{
"name" : "CASE_ARM_BROWSE_BY_URL",
"type" : "SUB_MODULE",
"enumType" : "PRIVILEGE",
"companyID" : "CSOP",
"value" : "Y"
}

-- NEED TO INCLUDE THIS IN BO ROLES IN CONFIG_V2
{
"name" : "CASE_BO_BROWSE_BY_URL",
"type" : "SUB_MODULE",
"enumType" : "PRIVILEGE",
"companyID" : "CSOP",
"value" : "Y"
}

-- NEED TO INCLUDE THIS IN TH ROLES IN CONFIG_V2
{
"name" : "CASE_TH_BROWSE_BY_URL",
"type" : "SUB_MODULE",
"enumType" : "PRIVILEGE",
"companyID" : "CSOP",
"value" : "Y"
}

-- NEED TO INCLUDE THIS IN LC ROLES IN CONFIG_V2
{
"name" : "CASE_LC_BROWSE_BY_URL",
"type" : "SUB_MODULE",
"enumType" : "PRIVILEGE",
"companyID" : "CSOP",
"value" : "Y"
}

-- NEED TO INCLUDE THIS IN LCR ROLES IN CONFIG_V2
{
"name" : "CASE_LCR_BROWSE_BY_URL",
"type" : "SUB_MODULE",
"enumType" : "PRIVILEGE",
"companyID" : "CSOP",
"value" : "Y"
}

-- NEED TO INCLUDE THIS IN HWM ROLES IN CONFIG_V2
{
"name" : "CASE_HWM_BROWSE_BY_URL",
"type" : "SUB_MODULE",
"enumType" : "PRIVILEGE",
"companyID" : "CSOP",
"value" : "Y"
}

-- NEED TO INCLUDE THIS IN ARM, BO, LC, LCR, HWM ROLES IN CONFIG_V2
{
"name" : "CASE_OWNER_DISPLAY",
"type" : "SUB_MODULE",
"enumType" : "PRIVILEGE",
"companyID" : "CSOP",
"value" : "Y"
}

-- NEED TO INCLUDE THIS IN TH ROLES IN CONFIG_V2
{
"name" : "CASE_MANAGE_OWNER",
"type" : "SUB_MODULE",
"enumType" : "PRIVILEGE",
"companyID" : "CSOP",
"value" : "Y"
}


-- NEED TO INCLUDE THIS IN RM, ARM, BO,TH, LC, LCR, HWM ROLES IN CONFIG_V2
{
"name" : "CASE_DETAILS_VIEW",
"type" : "SUB_MODULE",
"enumType" : "PRIVILEGE",
"companyID" : "CSOP",
"value" : "Y"
}

-- NEED TO INCLUDE THIS IN ADMIN, RA, PM,SU, QU ROLES IN CONFIG_V2
{
"name" : "PROFILE_DATE_PICKER",
"type" : "SUB_MODULE",
"enumType" : "PRIVILEGE",
"companyID" : "CSOP",
"value" : "Y"
}

-- NEED TO INCLUDE THIS IN RA

{
	"name" : "FUND_PERFORMANCE_VIEW",
	"type" : "MODULE_ACCESS",
	"enumType" : "PRIVILEGE",
	"companyID" : "CSOP",
	"value" : "Y",
	"label" : "Fund Performance Details",
	"moduleType" : "RISK"
}

{
	"name" : "FUND_TDTE_VIEW",
	"type" : "MODULE_ACCESS",
	"enumType" : "PRIVILEGE",
	"companyID" : "CSOP",
	"value" : "Y",
	"label" : "Fund TDTE Details",
	"moduleType" : "RISK"
}

{
	"name" : "FUND_HOLDINGS_VIEW",
	"type" : "MODULE_ACCESS",
	"enumType" : "PRIVILEGE",
	"companyID" : "CSOP",
	"value" : "Y",
	"label" : "Fund Holding Details",
	"moduleType" : "RISK"
}

{
	"name" : "FUND_TOP10_HOLDING_VIEW",
	"type" : "MODULE_ACCESS",
	"enumType" : "PRIVILEGE",
	"companyID" : "CSOP",
	"value" : "Y",
	"label" : "Fund Top 10 Holdings Details",
	"moduleType" : "RISK"
}

{
	"name" : "FUND_LIQUIDATION_VIEW",
	"type" : "MODULE_ACCESS",
	"enumType" : "PRIVILEGE",
	"companyID" : "CSOP",
	"value" : "Y",
	"label" : "Fund Liquidation Report",
	"moduleType" : "RISK"
}

{
	"name" : "FUND_FAIR_VALUE_VIEW",
	"type" : "MODULE_ACCESS",
	"enumType" : "PRIVILEGE",
	"companyID" : "CSOP",
	"value" : "Y",
	"label" : "Fund Fair Value Reports",
	"moduleType" : "RISK"
}

{
	"name" : "FUND_HIST_REPORT_VIEW",
	"type" : "MODULE_ACCESS",
	"enumType" : "PRIVILEGE",
	"companyID" : "CSOP",
	"value" : "Y",
	"label" : "Fund Historical Reports Details",
	"moduleType" : "RISK"
}

{
	"name" : "FUND_STRESS_REPORT_VIEW",
	"type" : "MODULE_ACCESS",
	"enumType" : "PRIVILEGE",
	"companyID" : "CSOP",
	"value" : "Y",
	"label" : "Fund Stress Testing Report",
	"moduleType" : "RISK"
}

{
	"name" : "FUND_ANALYTICS_VIEW",
	"type" : "MODULE_ACCESS",
	"enumType" : "PRIVILEGE",
	"companyID" : "CSOP",
	"value" : "Y",
	"label" : "Fund Analytics Details",
	"moduleType" : "RISK"
}
*/