import { Injectable } from '@angular/core';
import { ApiService, PagedRecords } from '../shared/api.service';

@Injectable()
export class CcassService {
	serviceName = 'cass';
	authServiceName = 'auth'
	constructor(private apiService: ApiService) { }

	ccassPost(data: Object, table: string): Promise<Object> {
		const url = `${this.serviceName}/${table}`;
		return this.apiService.post(url, data);
	}

	ccassfindOne(id: string, table: string): Promise<Object> {
		const url = `${this.serviceName}/${table}`;
		return this.apiService.get(url);
	}

	ccassUpdate(code: string, data: Object, table: string): Promise<Object> {
		const url = `${this.serviceName}/${table}`;
		return this.apiService.ccassput(url, data);
	}

	ccassUpdateTeam(data: Object, table: string): Promise<Object> {
		const url = `${this.serviceName}/${table}`;
		return this.apiService.put(url, data);
	}

	delete(code: string, table: string): Promise<Object> {
		const url = `${this.serviceName}/${table}/${code}`;
		return this.apiService.post(url);
	}

	ccassDelete(code: string, table: string): Promise<Object> {
		const url = `${this.serviceName}/${table}/${code}`;
		return this.apiService.delete(url);
	}

	ccassUpload(data: Object, table: string): Promise<Object> {
		const url = `${this.serviceName}/${table}`;
		return this.apiService.post(url, data);
	}

	ccasfindSerice(filter: Object, page: number = 1, size: number = 10, table: string): Promise<PagedRecords> {
		const url = `${this.authServiceName}/${table}`;
		return this.apiService.getWithFilter(url, filter, page, size)
			.then(response => response as PagedRecords);
	}
}
