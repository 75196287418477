import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from './alert.service';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpResponseBase } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { PrivilegeService } from './privilege.service';

declare let snowplow: Function;
const encryptParams = { 'screenName': 'Admin', 'subModule': 'encryptParams' };
const hostName = window.location.hostname;

const BASE_API_URL = environment[hostName].apiUrl;
const DC_API_URL = environment[hostName].serverUrl;
const DC_DA_URL = environment[hostName].dcserveUrl;
const BASE_WS_URL = environment[hostName].wsUrl;
const COMPANY_ID = environment.companyID;

const KeyConfig ={
    key:CryptoJS.enc.Utf8.parse('e9SCA5GZWfy8k6W6n1zM4kVrcSOOYl5L'),
    iv : CryptoJS.enc.Utf8.parse("1011121314151617")
}

export class Pager {
	constructor() { }
	public total: number; // total records
	public page: number; // current page
	public size: number; // page size
}

export class FormQueryEncoder {
	encodeKey(k: string): string { return encodeURIComponent(k); }
	encodeValue(v: string): string { return encodeURIComponent(v); }
}
export class PagedRecords {
	public status: string;
	public total: number;
	public totalRecords: number;
	public rows: Object[];
}

@Injectable()
export class ApiService {
	constructor(private http: HttpClient, private router: Router, private alertService: AlertService,private privilegeService: PrivilegeService) {
	}

	serviceName = 'cop';
	dcfundService = 'cdb';
	holidayService = 'mrd';
	riskService = 'risk';

	getBaseAPIUrl(): string {
		const hostList = Object.keys(environment);
		if (hostList.filter(host => host === hostName).length === 0) {
			return hostName + '/api';
		}
		if (hostName === 'localhost') {
			return BASE_API_URL + 'api/';
			// return BASE_API_URL;
		}
		return 'api/';
	}

	getDCAPIUrl(): string {
		const hostList = Object.keys(environment);
		if (hostList.filter(host => host === hostName).length === 0) {
			return hostName;
		}
		if (hostName === 'localhost') {
			return DC_API_URL;
			// return BASE_API_URL;
		}

		// return 'api/';
	}
	getACAPIUrl(): string {
		const hostList = Object.keys(environment);
		if (hostList.filter(host => host === hostName).length === 0) {
			return hostName;
		}
		if (hostName === 'localhost') {
			return DC_DA_URL;
			// return BASE_API_URL;
		}
		// return 'api/';
	}

	get(path: string, params?: HttpParams | any): Promise<any> {
		let fullUrl = this.getBaseAPIUrl() + path;
		const options = { 'headers': this.makeHeaders() };
		if (params instanceof HttpParams) {
			params[''] = new FormQueryEncoder();
			options['params'] = params;
		} else if (params instanceof String) {
			fullUrl += '?' + params;
		}
		return this.http.get(fullUrl, options)
			.toPromise()
			.then(this.handleResponse.bind(this))
			.catch(this.handleError.bind(this));
	}

	commonExportFiles(path: string, filter: Object, page: number = 1, size: number = 100): Promise<any> {
		let fullUrl = this.getBaseAPIUrl() + path;
		const options = { 'headers': this.makeHeaders(), 'responseType': 'blob' as 'blob' };
		const params: any = this.getQueryParams(filter, page, size);
		if (params instanceof HttpParams) {
			options['params'] = params;
			params[''] = new FormQueryEncoder();
		} else if (params instanceof String) {
			fullUrl += '?' + params;
		}
		return this.http.get(fullUrl, options)
			.toPromise()
			.then(this.handleResponse.bind(this))
			.catch(this.handleError.bind(this));
	}

	commonExportFilesXL(path: string, body: HttpParams | any = {}, headers: HttpHeaders | any = {}) {
		let fullUrl = this.getBaseAPIUrl() + path;
		return this.http.post(fullUrl, body, { headers: this.makeHeaders(headers), responseType: 'blob' as 'blob' })
			.toPromise()
			.then(this.handleResponse.bind(this))
			.catch(this.handleError.bind(this));
	}

	pdfDownload(path: string, contentType: string, fileName?: string | 'TESTFILE') {
		const fullUrl = this.getBaseAPIUrl() + path;
		// const offset = -new Date().getTimezoneOffset();
		const options = { 'headers': this.makeHeaders(), 'responseType': 'blob' as 'blob' };
		// options.headers.append('timeZone', offset.toString());
		this.http.get(fullUrl, options).subscribe((response) => {
			// const contentDispositionHeader: string = response.headers.get('Content-Disposition');
			const a = document.createElement('a');
			const blob = new Blob([response], { 'type': contentType });
			a.href = URL.createObjectURL(blob);
			a.download = fileName + '.pdf';
			document.body.appendChild(a);
			a.click();
		});
	}

	getFileDownload(path: string, contentType: string, params?: HttpParams | any, fileName?: string | 'TESTFILE') {
		let fullUrl = this.getBaseAPIUrl() + path;
		const options = { 'headers': this.makeHeaders(), 'responseType': 'blob' as 'blob' };
		fullUrl += '?' + params;
		this.http.get(fullUrl, options).subscribe((response) => {
			// const contentDispositionHeader: string = response.headers.get('Content-Disposition');
			const a = document.createElement('a');
			const blob = new Blob([response], {
				'type': contentType
			});
			a.href = URL.createObjectURL(blob);
			a.download = fileName + '.xlsx';
			document.body.appendChild(a);
			a.click();
			a.remove();
		});
	}

	commonExportEn(path: string, filter?: Object,headers: HttpHeaders | any = {}) {
		const params: any = this.getExportParamsEn(filter);
		const data = this.encryptAES(params)
		let pata = {data:data}
		const fullUrl = this.getBaseAPIUrl() + path;
			return this.http.post(fullUrl, pata, { headers: this.makeHeadersEn(headers) })
				.toPromise()
				.then(this.handleResponse.bind(this))
				.catch(this.handleError.bind(this));
	}

	commonExport(path: string, filter?: HttpParams | any) {
		let fullUrl = this.getBaseAPIUrl() + path;
		const options = { 'headers': this.makeHeaders(), 'responseType': 'blob' as 'blob' };
		const params: any = this.getExportParams(filter);
		if (params instanceof HttpParams) {
			params[''] = new FormQueryEncoder();
			options['params'] = params;
		} else if (params instanceof String) {
			fullUrl += '?' + params;
		}
		return this.http.get(fullUrl, options)
			.toPromise()
			.then(this.handleResponse.bind(this))
			.catch(this.handleError.bind(this));
	}

	commonExportDC(path: string, body: HttpParams | any = {}, headers: HttpHeaders | any = {}) {
		const fullUrl = this.getBaseAPIUrl() + path;
		return this.http.post(fullUrl, body, { headers: this.makeHeaders(headers) })
			.toPromise()
			.then(this.handleResponse.bind(this))
			.catch(this.handleError.bind(this))
	}

	getFinanceAggregateDownload(path: string, contentType: string, filter?: HttpParams | any, fileName?: string | 'TESTFILE') {
		let fullUrl = this.getBaseAPIUrl() + path;
		const options = { 'headers': this.makeHeaders(), 'responseType': 'blob' as 'blob' };
		const params: any = this.getQueryParams(filter);
		if (params instanceof HttpParams) {
			options['search'] = params;
			params[''] = new FormQueryEncoder();
		} else if (params instanceof String) {
			fullUrl += '?' + params;
		}
		this.http.get(fullUrl, options).subscribe((response) => {
			// const contentDispositionHeader: string = response.headers.get('Content-Disposition');
			const a = document.createElement('a');
			const blob = new Blob([response], {
				'type': contentType
			});
			a.href = URL.createObjectURL(blob);
			a.download = fileName + '.xlsx';
			document.body.appendChild(a);
			a.click();
			a.remove();
		});
	}
	csvFileDownload(path: string, contentType: string, params?: HttpParams | any, fileName?: string | 'TESTFILE') {
		let fullUrl = this.getBaseAPIUrl() + path;
		const options = { 'headers': this.makeHeaders(), 'responseType': 'blob' as 'blob' };
		fullUrl += '?' + params;
		this.http.get(fullUrl, options).subscribe((response) => {
			// const contentDispositionHeader: string = response.headers.get('Content-Disposition');

			const a = document.createElement('a');
			const blob = new Blob([response['_body']], {
				'type': contentType
			});
			a.href = URL.createObjectURL(blob);
			a.download = fileName + '.csv';
			document.body.appendChild(a);
			a.click();
		});
	}

	getQueryParams(filter: Object, page: number = 1, size: number = 10) {
		let query = new HttpParams();
		query = query.set('page', page.toString());
		query = query.set('limit', size.toString());
		if (Object.keys(filter).length !== 0) {
			for (const key of Object.keys(filter)) {
				query = query.set(key, filter[key]);
				if (typeof (filter[key]) === 'object') {
					query = query.set(key, JSON.stringify(filter[key]));
				}
			}
		}
		return query;
	}
	getQueryParamsEn(filter: Object, page: number = 1, size: number = 10) {
		let query = {}
		query['page'] = page.toString()
		query['limit'] = size.toString()
		
		if (Object.keys(filter).length !== 0) {
			for (const key of Object.keys(filter)) {
				query[key] = filter[key]
			}
		}
		return query;
	}

	getExportParamsEn(filter: Object) {
		let query = {}
		if (Object.keys(filter).length !== 0) {
			for (const key of Object.keys(filter)) {
				query[key] = filter[key]
			}
		}
		return query;
	}

	getQueryParamsreq(filter: Object) {
		let query = new HttpParams();
		// query = query.set('page', page.toString());
		// query = query.set('limit', size.toString());
		if (Object.keys(filter).length !== 0) {
			for (const key of Object.keys(filter)) {
				query = query.set(key, filter[key]);
				if (typeof (filter[key]) === 'object') {
					query = query.set(key, JSON.stringify(filter[key]));
				}
			}
		}
		return query;
	}
	getExportParams(filter: Object) {
		let query = new HttpParams();
		// query = query.set('page', page.toString())
		// query = query.set('limit', size.toString());
		if (Object.keys(filter).length !== 0) {
			for (const key of Object.keys(filter)) {
				query = query.set(key, filter[key]);
				if (typeof (filter[key]) === 'object') {
					query = query.set(key, JSON.stringify(filter[key]));
				}
			}
		}
		return query;
	}
	getSummaryQueryParams(filter: Object) {
		let query = new HttpParams();
		if (Object.keys(filter).length !== 0) {
			for (const key of Object.keys(filter)) {
				query = query.set(key, filter[key]);
				if (typeof (filter[key]) === 'object') {
					query = query.set(key, JSON.stringify(filter[key]));
				}
			}
		}
		return query;
	}

	getWithFilter(path: string, filter: Object, page: number = 1, size: number = 100): Promise<any> {
		let fullUrl = this.getBaseAPIUrl() + path;
		const options = { 'headers': this.makeHeaders() };
		const params: any = this.getQueryParams(filter, page, size);
		if (params instanceof HttpParams) {
			options['params'] = params;
			params[''] = new FormQueryEncoder();
		} else if (params instanceof String) {
			fullUrl += '?' + params;
		}
		return this.http.get(fullUrl, options)
			.toPromise()
			.then(this.handleResponse.bind(this))
			.catch(this.handleError.bind(this));
	}

	getWithFilterEn(path: string, filter: Object, page: number = 1, size: number = 100,headers: HttpHeaders | any = {}): Promise<any> {
		const ENCRYPT_PARAM = this.privilegeService.isAuthorized(encryptParams)
		const params: any = this.getQueryParamsEn(filter, page, size);
		const data = ENCRYPT_PARAM ? this.encryptAES(params) : {}
		let pata = ENCRYPT_PARAM ? {data:data} : params
		const fullUrl = this.getBaseAPIUrl() + path;
			return this.http.post(fullUrl, pata, { headers: this.makeHeadersEn(headers) })
				.toPromise()
				.then(this.handleResponse.bind(this))
				.catch(this.handleError.bind(this));
	}

	encryptAES(data: any): string {
		data = JSON.stringify(data);
		const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), KeyConfig.key,
			{
				iv: KeyConfig.iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.ZeroPadding
			});
		return encrypted.toString();
	}
	
	commonExportWithFilter(path: string, filter: Object, page: number = 1, size: number = 100): Promise<any> {
		let fullUrl = this.getBaseAPIUrl() + path;
		const options: { headers: HttpHeaders; responseType: 'blob'; observe: 'response' } = {
			headers: this.makeHeaders(),
			responseType: 'blob',
			observe: 'response'
		  };
		const params: any = this.getQueryParams(filter, page, size);
		if (params instanceof HttpParams) {
			params[''] = new FormQueryEncoder();
			options['params'] = params;
		} else if (params instanceof String) {
			fullUrl += '?' + params;
		}
		return this.http.get(fullUrl, options)
			.toPromise()
			.then(this.handleResponse.bind(this))
			.catch(this.handleError.bind(this));
	}

	getWithFilterTD(path: string, filter: Object, page: number = 1, size: number = 100): Promise<any> {
		let fullUrl = this.getBaseAPIUrl() + path;
		const options = { 'headers': this.makeHeaders() };
		const params: any = this.getQueryParams(filter, page, size);
		if (params instanceof HttpParams) {
			options['params'] = params;
			params[''] = new FormQueryEncoder();
		} else if (params instanceof String) {
			fullUrl += '?' + params;
		}
		return this.http.post(fullUrl, options).toPromise()
			.then(this.handleResponse.bind(this))
			.catch(this.handleError.bind(this));
	}
	getEXecutionWithFilter(path: string): Promise<any> {
		let fullUrl = this.getBaseAPIUrl() + path;
		const options = { 'headers': this.makeHeaders() };
		return this.http.get(fullUrl, options)
			.toPromise()
			.then(this.handleResponse.bind(this))
			.catch(this.handleError.bind(this));
	}

	getSummaries(path: string, filter: Object): Promise<any> {
		let fullUrl = this.getBaseAPIUrl() + path;
		const options = { 'headers': this.makeHeaders() };
		const params: any = this.getSummaryQueryParams(filter);
		if (params instanceof HttpParams) {
			options['params'] = params;
			params[''] = new FormQueryEncoder();
		} else if (params instanceof String) {
			fullUrl += '?' + params;
		}
		return this.http.get(fullUrl, options)
			.toPromise()
			.then(this.handleResponse.bind(this))
			.catch(this.handleError.bind(this));
	}

	post(path: string, body: HttpParams | any = {}, headers: HttpHeaders | any = {}): Promise<any> {
		if (path.includes('gateway/cdb/orders/save-order')) {
			path = 'gateway/cdb/orders/save-order'
			const fullUrl = this.getBaseAPIUrl() + path;
			return this.http.post(fullUrl, body, { headers: this.makeHeaders(headers) })
				.toPromise()
				.then(this.handleResponse.bind(this))
				.catch(this.handleError.bind(this));
		} else {
			const fullUrl = this.getBaseAPIUrl() + path;
			return this.http.post(fullUrl, body, { headers: this.makeHeaders(headers) })
				.toPromise()
				.then(this.handleResponse.bind(this))
				.catch(this.handleError.bind(this));
		}
	}

	post_(path: string, body: HttpParams | any = {}, headers: HttpHeaders | any = {}): Promise<any> {
		const fullUrl = this.getBaseAPIUrl() + path;
		return this.http.post(fullUrl, body, { headers: this.makeHeaders(headers) })
			.toPromise()
			.then(this.handleResponse.bind(this))
			.catch(this.handleError.bind(this));
	}

	postLogout_(path: string, body: HttpParams | any = {}, headers: HttpHeaders | any = {}): Promise<any> {
		const fullUrl = this.getBaseAPIUrl() + path;
		return this.http.post(fullUrl, body, { headers: this.makeHeadersLogout(headers) })
			.toPromise()
			.then(this.handleResponse.bind(this))
			.catch(this.handleError.bind(this));
	}

	delete_(path: string): Promise<any> {
		const fullUrl = this.getBaseAPIUrl() + path;
		return this.http.post(fullUrl, { headers: this.makeHeaders() })
			.toPromise()
			.then(this.handleResponse.bind(this))
			.catch(this.handleError.bind(this));
	}

	put(path: string, body: HttpParams | any = {}): Promise<any> {
		const fullUrl = this.getBaseAPIUrl() + path;
		return this.http.post(fullUrl, body, { headers: this.makeHeaders() })
			.toPromise()
			.then(this.handleResponse.bind(this))
			.catch(this.handleError.bind(this));
	}

	ccassput(path: string, body: HttpParams | any = {}): Promise<any> {
		const fullUrl = this.getBaseAPIUrl() + path;
		return this.http.put(fullUrl, body, { headers: this.makeHeaders() })
			.toPromise()
			.then(this.handleResponse.bind(this))
			.catch(this.handleError.bind(this));
	}

	putMethod(path: string, body: HttpParams | any = {}): Promise<any> {
		const fullUrl = this.getBaseAPIUrl() + path;
		return this.http.put(fullUrl, body, { headers: this.makeHeaders() })
			.toPromise()
			.then(this.handleResponse.bind(this))
			.catch(this.handleError.bind(this));
	}
	put_(path: string, body: HttpParams | any = {}): Promise<any> {
		const fullUrl = this.getBaseAPIUrl() + path;
		return this.http.post(fullUrl, body, { headers: this.makeHeaders() })
			.toPromise()
			.then(this.handleResponse.bind(this))
			.catch(this.handleError.bind(this));
	}

	logoUpload(path: string, body: HttpParams | any = {}): Promise<any> {
		const url =`${this.serviceName}/${this.riskService}/${path}`
		const fullUrl = this.getBaseAPIUrl() + url;
		return this.http.post(fullUrl, body, { headers: this.makeHeaders() })
			.toPromise()
			.then(this.handleResponse.bind(this))
			.catch(this.handleError.bind(this));
	}

	delete(path: string): Promise<any> {
		// if(path.includes('cop/cdb/orders/delete-order')){

		//    const fullUrl = this.getDCAPIUrl() + path;
		//    return this.http.delete(fullUrl)
		// 	.toPromise()
		// 	.then(this.handleResponse.bind(this))
		// 	.catch(this.handleError.bind(this));
		// }
		// else {
		const fullUrl = this.getBaseAPIUrl() + path;
		return this.http.delete(fullUrl, { headers: this.makeHeaders() })
			.toPromise()
			.then(this.handleResponse.bind(this))
			.catch(this.handleError.bind(this));
		//  }			
	}

	update(path: string): Promise<any> {
		// if(path.includes('cop/cdb/orders/delete-order')){

		//    const fullUrl = this.getDCAPIUrl() + path;
		//    return this.http.delete(fullUrl)
		// 	.toPromise()
		// 	.then(this.handleResponse.bind(this))
		// 	.catch(this.handleError.bind(this));
		// }
		// else {
		const fullUrl = this.getBaseAPIUrl() + path;
		return this.http.delete(fullUrl, { headers: this.makeHeaders() })
			.toPromise()
			.then(this.handleResponse.bind(this))
			.catch(this.handleError.bind(this));
		//  }			
	}

	private handleResponse(resp: HttpResponseBase): Promise<any> {
		const msg = resp;
		if (msg['success'] === false) {
			return Promise.reject(resp);
		}

		return Promise.resolve(msg);
	}

	private handleError(resp: HttpResponseBase | any): Promise<any> {
		if (resp.status === 200) {
			this.alertService.error(resp.json().message);
			return Promise.reject(resp.json());
		}

		if (resp.status === 400) {
			const errMsg = resp.statusText || 'Bad request';
			this.alertService.error(errMsg);
			return Promise.reject(resp);
		}

		if (resp.status === 401) {
			this.logout();
			resp.statusText = 'Unauthorized' ? 'Your session has expired, Please login again.' : resp.statusText;
			return Promise.reject(resp);
		}

		if (resp.status === 403) {
			const errMsg = resp.statusText || 'Invalid session';
			this.alertService.error(errMsg);
			this.logout();
			return Promise.reject(resp);
		}

		if (resp.status === 500) {
			const errMsg = resp.statusText || 'Server error';
			this.alertService.error(errMsg);
			return Promise.reject(resp);
		}

		if (resp.status === 0) {
			this.alertService.error('Something wrong. Failed to connect to the api server.');
			return Promise.reject(resp);
		}
	}


	makeHeaders(customHeaders: HttpHeaders | any = {}) {
		let headers = new HttpHeaders();
		if (Object.keys(customHeaders).length !== 0) {
			for (const key of customHeaders.keys()) {
				headers = headers.set(key, customHeaders.get(key));
			}
		}
		headers = headers.append('companyID', COMPANY_ID);
		const currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			const user = JSON.parse(currentUser);
			headers = headers.append('Authorization', 'Token ' + user['token']);
			headers = headers.append('sessionEnabled', 'true');
			headers = headers.append('X-SESSION-TOKEN', user['sessionToken']);
			headers = headers.append('Timestamp', new Date()?.getTime()?.toString());
		}
		return headers;
	}

	makeHeadersEn(customHeaders: HttpHeaders | any = {}) {
		const ENCRYPT_PARAM = this.privilegeService.isAuthorized(encryptParams)
		let headers = new HttpHeaders();
		if (Object.keys(customHeaders).length !== 0) {
			for (const key of customHeaders.keys()) {
				headers = headers.set(key, customHeaders.get(key));
			}
		}
		headers = headers.append('companyID', COMPANY_ID);
		const currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			const user = JSON.parse(currentUser);
			headers = headers.append('Authorization', 'Token ' + user['token']);
			headers = headers.append('sessionEnabled', 'true');
			headers = headers.append('X-SESSION-TOKEN', user['sessionToken']);
			headers = headers.append('Timestamp', new Date()?.getTime()?.toString());
			headers = headers.append('X-encrypted',ENCRYPT_PARAM  ? 'Y' : 'N');
		}
		return headers;
	}
	
	makeHeadersLogout(customHeaders: HttpHeaders | any = {}) {
		let headers = new HttpHeaders();
		if (Object.keys(customHeaders).length !== 0) {
			for (const key of customHeaders.keys()) {
				headers = headers.set(key, customHeaders.get(key));
			}
		}
		headers = headers.append('companyID', COMPANY_ID);
		const currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			const user = JSON.parse(currentUser);
			headers = headers.append('Authorization', 'Token ' + user['token']);
			headers = headers.append('sessionEnabled', 'true');
			headers = headers.append('X-SESSION-TOKEN', user['sessionToken']);
			headers = headers.append('Timestamp', new Date()?.getTime()?.toString());
		}
		localStorage.removeItem('currentUser')
		localStorage.clear()
		return headers;
	}

	makeHeadersDC(customHeaders: HttpHeaders | any = {}) {
		let headers = new HttpHeaders();
		if (Object.keys(customHeaders).length !== 0) {
			for (const key of customHeaders.keys()) {
				headers = headers.set(key, customHeaders.get(key));
			}
		}

		headers = headers.append('companyID', COMPANY_ID);
		headers = headers.append('Access-Control-Allow-Origin', '*');
		headers = headers.append('Access-Control-Allow-Credentials', 'true');
		headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, Accept, X-Custom-Header, Upgrade-Insecure-Requests');
		headers = headers.append('Access-Control-Allow-Methods', 'POST, PUT, GET, OPTIONS, DELETE');
		const currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			const user = JSON.parse(currentUser);
			headers = headers.append('Authorization', 'Token ' + user['token']);

		}
		return headers;
	}


	logout() {
		const sessionToken = JSON.parse(localStorage.getItem('currentUser'))?.['sessionToken'];
		snowplow('trackStructEvent', 'user_session', 'logout', sessionToken, 'SESSION_TOKEN', sessionToken);
		localStorage.removeItem('currentUser')
		localStorage.clear()
		this.router.navigate(['/login']);
	}

	getCurrentPage(event: any): number {
		let currentPage: number;
		currentPage = event.first / event.rows + 1;
		return currentPage;
	}

	ccassFileDownload(path: string, contentType: string, request: any, fileName: string = 'TESTFILE', callback: () => void, errorCallback: () => void) {
		const fullUrl = this.getBaseAPIUrl() + path;
		const options = { 'headers': this.makeHeaders(), 'responseType': 'blob' as 'blob' };

		this.http.post(fullUrl, request, options).subscribe((response) => {

			const link = document.createElement('a');
			const blob = new Blob([response], {
				'type': contentType
			});
			link.href = URL.createObjectURL(blob);
			link.download = fileName + '.xlsx';
			document.body.appendChild(link);
			link.click();
			link.remove();
			// document.body.removeChild(link);
			callback();
		},
			(error) => {
				errorCallback();
			}
		);
	}

}