import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DataService } from '../../../data.service';
import { ApiService } from '../../../shared/api.service';
import { DefaultService } from '../../../utils/default.service';
import { noSpace } from '../../../utils/utilities.function';
import { CcassService } from '../../ccass.service';

@Component({
  selector: 'app-add-pd',
  templateUrl: './add-pd.component.html',
  styleUrls: ['./add-pd.component.scss']
})
export class AddPdComponent implements OnInit {

  noSpace = noSpace

  @Input() isDisplay = false;

  @Input() selectedRow: any;
  @Input() dialogName: any;
  @Input() btnName: any;
  @Output() isDisplayChange = new EventEmitter<boolean>()
  @Output() getPdData = new EventEmitter();
  @Output() shoepopupInParent = new EventEmitter();


  loading: boolean;
  pd:any = {}
  pdForm: FormGroup;
  nationalityOpts: any = []
  nationalityOptions: any = []
  message: any;


  constructor(
    private ccassService: CcassService,
    private apiService: ApiService,
    private defaultService: DefaultService,
    private messageService: MessageService,
    private dataService: DataService,
  ) { }

  ngOnInit(): void {
    this.loadpdForm()
    this.getCodes()
    if(this.selectedRow) {
      this.pd['participant'] = this.selectedRow['participantName']
      this.pd['p_Id'] = this.selectedRow['pId']
      this.pd['nationality_'] = (this.selectedRow['nationality'] && this.selectedRow['nationality'] !== '' && this.selectedRow['nationality'] !== undefined) ? this.selectedRow['nationality'] : null
    }
  }

  loadpdForm() {
    this.pdForm = new FormGroup({
      "participant": new FormControl('',Validators.required),
      "p_Id": new FormControl(''),
      "nationality_": new FormControl(''),
    })
  }

  resetNclose() {
    this.isDisplay = false;
    // this.getPdData.emit();
    this.isDisplayChange.emit(this.isDisplay); 
  }

  resetNcloseFRSuccess(message:any) {
    this.isDisplay = false;
    this.shoepopupInParent.emit({message});
    this.isDisplayChange.emit(this.isDisplay); 
  }

  addPd() {
    const url = 'v1/participants/update'
    const formData = { ...this.pdForm.value }
    const form = {}
    form['participantId'] = this.selectedRow['id']
    form['participantName'] = formData['participant']
    form['pId'] = formData['p_Id']
    form['nationality'] = formData['nationality_']
    // if (form['pId'] == null || form['pId'] == undefined || form['pId'] == '') delete form['pId'];
    // if (form['nationality'] == null || form['nationality'] == undefined) delete form['nationality'];
    this.ccassService.ccassUpdateTeam(form,url).then(data => {
      if(data['status'] == 'success') {
        this.resetNcloseFRSuccess(data['message']);
      } else {
        this.messageService.add({ severity: 'error', detail: data['message'] ? data['message'] : data['status'] });
        this.loading = false;
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
      // this.resetNclose();
      this.loading = false;
    })
  }

  getCodes() {
    this.defaultService.mrdfind({ queryParams: {class_type:{ equals:'NATIONALITY'}}, orderByParams: { class_type: 'asc', code: 'asc' } }, 1, 1000, `common/codes`).then((data: any) => {
      if (data && data.status === 'success') {
        data.data.forEach((obj: any) => {
              this.nationalityOpts.push({ label: obj['code_desc'], value: obj['code'] });
              this.nationalityOptions = this.nationalityOpts
        });  
        this.loading = false;
      }
      else {
        this.messageService.add({ severity: 'error', detail: data['status'] });
        this.loading = false;
      }
    }).catch((err: any) => {
     this.loading = false;
    //  this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
    });
  }

}
