import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DefaultService } from '../../../utils/default.service';
import { ApiService } from '../../../shared/api.service';
import { TableSchema } from '../../../utils/table-schema';
import { CcassService } from '../../ccass.service';
import { SIDE_NAVE_TITLES } from '../../utils/util-constants';
import { MessageService } from 'primeng/api';
import { DataService } from '../../../data.service';

@Component({
  selector: 'app-client-mapping',
  templateUrl: './client-mapping.component.html',
  styleUrls: ['./client-mapping.component.scss']
})
export class ClientMappingComponent implements OnInit {

  pageTitle: string = SIDE_NAVE_TITLES.clientMapping;

  @Output() titleHeading = new EventEmitter<string>();
  @Output() shouldDisplayTitle = new EventEmitter<boolean>();

  tableColumns = TableSchema['clienr-mapping'];
  loading = false;
  clientMappingData: any = [];
  selectedRow: any;
  setDialogName: string;
  btnName: string;
  viewAddClientMapping: boolean;
  headerSelectOptions: any = {}
  currentRow: any;
  onClickDelete: boolean;
  setIndex: any;
  showAlertPopup = false
  message: string;

  constructor(
    private ccassService: CcassService,
    private apiService: ApiService,
    private defaultService: DefaultService,
    private messageService: MessageService,
    private dataService: DataService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.titleHeading.emit(this.pageTitle);
    this.shouldDisplayTitle.emit(false);
    this.getClientMappingData()
  }

  getClientMappingData() {
    this.ccassService.ccassfindOne('', 'v1/client-mapping').then(response => {
      if (response['status'] && response['status'] === 'success') {
        if(response['pdMapping'] && response['pdMapping'] !== null) {
          this.clientMappingData = response['pdMapping'];
          this.setHeaderSelectOptions(response['pdMapping'])
        } else {
          this.clientMappingData = []
          this.setHeaderSelectOptions([])
        }
      } else {
        this.messageService.add({ severity: 'error', detail: response['status'] });
        this.loading = false;
        // handle failure
      }
    }).catch(err => {
      if (err.status == 500) {
        this.messageService.add({ severity: 'error', detail: err['statusText'] ? err['statusText'] : 'Internal server error' });
      }
      else if (err.status == 502) {
        this.messageService.add({ severity: 'error', detail: err.status ? err.status + 'Bad Gateway' : '502 Bad Gateway' });
      } else {
        this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
      }
      this.loading = false;
      // handle error
    });
  }

  setHeaderSelectOptions(data: any) {
    let nameOptions = [{ label: 'All', value: null }]
    if (data.length > 0) {
      data?.forEach((d: any) => {
        if (d.participantName) { nameOptions.push({ label: d.participantName, value: d.participantName }); }
      })
      const uniqueFilenameOptions = [...new Set(nameOptions.map(item => item.label))];
      nameOptions = []; 
      uniqueFilenameOptions?.forEach((element: any) => nameOptions.push({ label: element, value: element == 'All' ? null : element }));
      this.headerSelectOptions['participantName'] = nameOptions;
      this.loading = false
    } else {
      this.headerSelectOptions['participantName'] = nameOptions;
      this.loading = false
    }
  }

  onAddMapping() {
    this.selectedRow = undefined;
    this.setDialogName = 'ADD';
    this.btnName = 'SAVE';
    this.viewAddClientMapping = true;
  }

  editMapping(event: any) {
    this.selectedRow = event['row'];
    this.setDialogName = 'EDIT';
    this.btnName = 'UPDATE';
    this.viewAddClientMapping = true;
  }

  deleteMapping(event: any) {
    this.currentRow = event.row;
    this.onClickDelete = true;
    this.selectedRow = event.row;
    this.setIndex = event.index;
  }

  shoeerrorpopup(event:any) {
    this.showAlertPopup = true
    this.message = event.message
  }

  onClickYes() {
    const url = 'v1/client-mapping'
    this.ccassService.delete(this.selectedRow['mappingId'], url).then(data => {
      if (data['status'] === 'success') {
        const message = data['message']
        this.shoeerrorpopup({message})
      } else {
        this.messageService.add({ severity: 'error', detail: data['message'] ? data['message'] : data['status'] });
        this.loading = false;
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
      this.loading = false;
    })
  }


  onClickok() {
    this.getClientMappingData()
  }



}
