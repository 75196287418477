import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DataService } from '../../../data.service';
import { DefaultService } from '../../../utils/default.service';
import { ApiService } from '../../../shared/api.service';
import { CcassService } from '../../ccass.service';
import { SIDE_NAVE_TITLES } from '../../utils/util-constants';
import { TableSchema } from '../../../utils/table-schema';

@Component({
  selector: 'app-fund',
  templateUrl: './fund.component.html',
  styleUrls: ['./fund.component.scss']
})
export class FundComponent implements OnInit {

  pageTitle: string = SIDE_NAVE_TITLES.funds;

  @Output() titleHeading = new EventEmitter<string>();
  @Output() shouldDisplayTitle = new EventEmitter<boolean>();

  tableColumns = TableSchema['ccassfund'];

  loading = false;

  fundData: any = [];
  headerSelectOptions: any = {}
  selectedRow: any;
  setDialogName: string;
  btnName: string;
  viewAddFund: boolean;
  currentRow: any;
  onClickDelete: boolean;
  setIndex: any;
  showAlertPopup = false
  message: string;

  constructor(
    private ccassService: CcassService,
    private apiService: ApiService,
    private defaultService: DefaultService,
    private messageService: MessageService,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.titleHeading.emit(this.pageTitle);
    this.shouldDisplayTitle.emit(false);
    this.getFundData()
  }

  getFundData() {
    const url = 'v1/getFunds'
    const response = this.dataService.getCcassFubdData()
    this.ccassService.ccassfindOne('', url).then(response => {
      if (response['status'] && response['status'] === 'success') {
        if(response['data'] && response['data'] !== null) {
          this.fundData = response['data']; 
          response['data'].forEach((item, index) => {
            item['status'] = item.isActive ? 'YES' : 'NO';
          });
          this.setHeaderSelectOptions(response['data'])
        } else {
          this.fundData = []  
          this.setHeaderSelectOptions([])
        }
      } else {
        this.loading = false;
        // handle failure
      }
    }).catch(err => {
      this.loading = false;
      // handle error
    });
  }

  setHeaderSelectOptions(data: any) {
    let fundnameOptions = [{ label: 'All', value: null }]
    let assetclassOptions = [{ label: 'All', value: null }]
    let currencyOptions = [{ label: 'All', value: null }]
    let issuernameOptions = [{ label: 'All', value: null }]
    let listedatOptions = [{ label: 'All', value: null }]
    let activeOptions = [{ label: 'All', value: null }]
    if (data.length > 0) {
      data?.forEach((d: any) => {
        if (d.longName) { fundnameOptions.push({ label: d.longName, value: d.longName }); }
        if (d.assetClassDesc) { assetclassOptions.push({ label: d.assetClassDesc, value: d.assetClassDesc }); }
        if (d.currencyName) { currencyOptions.push({ label: d.currencyName, value: d.currencyName }); }
        if (d.issuerName) { issuernameOptions.push({ label: d.issuerName, value: d.issuerName }); }
        if (d.listedAt) { listedatOptions.push({ label: d.listedAt, value: d.listedAt }); }
        if (d.status) { activeOptions.push({ label: d.status, value: d.status }); }
      })
      const uniquefundnameOptions = [...new Set(fundnameOptions.map(item => item.label))];
      const uniqueassetclassOptions = [...new Set(assetclassOptions.map(item => item.label))];
      const uniquecurrencyOptions = [...new Set(currencyOptions.map(item => item.label))];
      const uniqueissuernameOptions = [...new Set(issuernameOptions.map(item => item.label))];
      const uniquelistedatOptions = [...new Set(listedatOptions.map(item => item.label))];
      const uniqueactiveOptions = [...new Set(activeOptions.map(item => item.label))];
      fundnameOptions = []; assetclassOptions = []; currencyOptions = []; issuernameOptions = []; listedatOptions = []; activeOptions = [];
      uniquefundnameOptions?.forEach((element: any) => fundnameOptions.push({ label: element, value: element == 'All' ? null : element }));
      uniqueassetclassOptions?.forEach((element: any) => assetclassOptions.push({ label: element, value: element == 'All' ? null : element }));
      uniquecurrencyOptions?.forEach((element: any) => currencyOptions.push({ label: element, value: element == 'All' ? null : element }));
      uniqueissuernameOptions?.forEach((element: any) => issuernameOptions.push({ label: element, value: element == 'All' ? null : element }));
      uniquelistedatOptions?.forEach((element: any) => listedatOptions.push({ label: element, value: element == 'All' ? null : element }));
      uniqueactiveOptions?.forEach((element: any) => activeOptions.push({ label: element, value: element == 'All' ? null : element }));
      this.headerSelectOptions['longName'] = fundnameOptions;
      this.headerSelectOptions['assetClassDesc'] = assetclassOptions;
      this.headerSelectOptions['currencyName'] = currencyOptions;
      this.headerSelectOptions['issuerName'] = issuernameOptions;
      this.headerSelectOptions['listedAt'] = listedatOptions;
      this.headerSelectOptions['status'] = activeOptions;
      this.loading = false;
    } else {
      this.headerSelectOptions['longName'] = fundnameOptions;
      this.headerSelectOptions['assetClassDesc'] = assetclassOptions;
      this.headerSelectOptions['currencyName'] = currencyOptions;
      this.headerSelectOptions['issuerName'] = issuernameOptions;
      this.headerSelectOptions['listedAt'] = listedatOptions;
      this.headerSelectOptions['status'] = activeOptions;
      this.loading = false;
    }
  }
  editFund(event: any) {
    this.selectedRow = event['row'];
    this.setDialogName = 'EDIT';
    this.btnName = 'UPDATE';
    this.viewAddFund = true;
  }

  deleteFund(event: any) {
    this.currentRow = event.row;
    this.onClickDelete = true;
    this.selectedRow = event.row;
    this.setIndex = event.index;
  }

  onClickYes() {
    const url = 'v1/salespersons/delete'
    this.ccassService.ccassDelete(this.selectedRow['id'], url).then(data => {
      if (data['status'] === 'success') {
        this.messageService.add({ severity: 'success', detail: data['message'] });
        this.getFundData()
      } else {
        this.messageService.add({ severity: 'error', detail: data['message'] ? data['message'] : data['status'] });
        this.loading = false;
      }
    }).catch(err => {
      if (err.status == 500) {
        this.messageService.add({ severity: 'error', detail: err['statusText'] ? err['statusText'] : 'Internal server error' });
      }
      else if (err.status == 502) {
        this.messageService.add({ severity: 'error', detail: err.status ? err.status + 'Bad Gateway' : '502 Bad Gateway' });
      } else {
        this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
      }
      this.loading = false;
    })
  }

  shoeerrorpopup(event:any) {
    this.showAlertPopup = true
    this.message = event.message
  }

  onClickok() {
    this.getFundData()
  }

}
