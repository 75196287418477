import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageConfig } from '../../assets/pages';
// import 'rxjs/add/operator/map';

import { ApiService } from './api.service';
import { PageService } from '../shared/pages.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


// Auth service
@Injectable()
export class AuthService {
	updatePwd = 'auth/myaccount/password/reset';
	constructor(private http: HttpClient, private apiService: ApiService, private router: Router, private pageService: PageService) {
	}

	isLoggedIn(): boolean {
		return this.currentUser() != null;
	}

	currentUser(): any {
		const user = localStorage.getItem('currentUser');
		if (user) {
			return JSON.parse(user);
		}
	}

	updateimgUrl(imageUrl): any {
		const user = localStorage.getItem('currentUser');
		try {
			const userObj = JSON.parse(user);
			userObj['imgUrl'] = imageUrl;
			localStorage.setItem('currentUser', JSON.stringify(userObj));
			return userObj;
		} catch (err) {
			
		}
	}

	updateUserData(userData): any {
		const user = localStorage.getItem('currentUser');
		try {
			const userObj = JSON.parse(user);
			if (userData != null) {
				userObj['firstName'] = userData.firstName;
				userObj['lastName'] = userData.lastName;
				localStorage.setItem('currentUser', JSON.stringify(userObj));
			}
			return userObj;
		} catch (err) {
			
		}
	}

	makeHeaders(username: string, password: string): HttpHeaders {
		const base64Credentials: string = window.btoa(username + ':' + password);
		const headers = new HttpHeaders().set('Authorization', 'Basic ' + base64Credentials);
		return headers;
	}

	login(username: string, password: string): Promise<any> {
		const data = new HttpParams();
		return this.apiService.post(`auth/myaccount/login`, data, this.makeHeaders(username, password))
			.then(response => {
				return response;
				// localStorage.setItem('notifUrl', environment[window.location.hostname].notifUrl + '?room=' + response['_id']);
			}).catch(err => {
				return Promise.reject(err);
				// this.isLoaded = true
			});
	}
	
	logoutS(): Promise<any> {
        return this.apiService.postLogout_(`auth/myaccount/logout`)
            .then(response => {
				this.router.navigate(['/login']);
				localStorage.removeItem('currentUser')
				localStorage.clear()
            }).catch(err => {
				this.router.navigate(['/login']);
                return Promise.reject(err);
            });
			

    }

	reset(data: Object): Promise<any> {
		return this.apiService.post_(`auth/myaccount/password/reset`, data)
			.then(response => {
				localStorage.setItem('currentUser', JSON.stringify(response));
			}).catch(err => {
				return Promise.reject(err);
				// this.isLoaded = true
			});
	}
	update(data: Object): Promise<any> {
		return this.apiService.post_(`auth/myprofile`, data)
			.then(response => {
			}).catch(err => {
				return Promise.reject(err);
			});
	}
	updatePassword(data: Object): Promise<any> {
		return this.apiService.post_(`auth/myaccount/password/update`, data)
			.then(response => response);
	}
	updatePaswrd(data: Object): Promise<Object> {
		const url = `${this.updatePwd}`;
		let body = new HttpParams();
		body = body.set('body', JSON.stringify(data));
		return this.apiService.post_(url, data);
	}
	logout(): void {
		localStorage.clear();
		this.router.navigate(['/login']);
	}
	myProfile(): Promise<any> {
		return this.apiService.get(`auth/myprofile`)
			.then(response => response);
	}
	forgotPassword(data: Object): Promise<any> {
		return this.apiService.post_(`auth/myaccount/password`, data)
			.then(response => response);
	}
}

// To protect angular routes
@Injectable()
export class AuthGuard implements CanActivate {

	constructor(private authService: AuthService) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (!this.authService.isLoggedIn()) {
			this.authService.logout();
			// return false
		}

		const user = JSON.parse(localStorage.getItem('currentUser'));
		if (!user) {
			return false;
		}

		const privs = user.privileges;
		const obj = PageConfig.pages.find(function (node) {
			if (node['routerLink']) {
				return node['routerLink'] === state.url;
			} else if (node['submenu']) {
				node['submenu'].find(function (subList) {
					return subList['routerLink'] === state.url;
				});
			}
		});

		const check = privs.find(function (privilige) {
			if (obj && obj['priv'] === 'RESET_PWD') {
				return obj['priv'];
			} else {
				return privilige.name === obj['priv'];
			}
		});

		if (check) {
			return true;
		}
		return false;
	}
}
