import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DefaultService } from '../../../utils/default.service';
import { ApiService } from '../../../shared/api.service';
import { noSpace } from '../../../utils/utilities.function';
import { CcassService } from '../../ccass.service';
import { MessageService } from 'primeng/api';
import { DataService } from '../../../data.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-fund',
  templateUrl: './add-fund.component.html',
  styleUrls: ['./add-fund.component.scss']
})
export class AddFundComponent implements OnInit {

  noSpace = noSpace

  @Input() isDisplay = false;

  @Input() selectedRow: any;
  @Input() dialogName: any;
  @Input() btnName: any;
  @Output() isDisplayChange = new EventEmitter<boolean>()
  @Output() getFundData = new EventEmitter();
  @Output() shoepopupInParent = new EventEmitter();

  loading: boolean;
  fund:any = {}
  funfForm: FormGroup;
  assetClassOpts: any = []
  assetClassOptions: any = []
  productTypeOpts: any = []
  products: any = []
  listedatOpts: any = []
  lists: any = []
  currencyOpts: any = []
  currencies: any = []
  message: any;

  constructor(
    private ccassService: CcassService,
    private apiService: ApiService,
    private defaultService: DefaultService,
    private messageService: MessageService,
    private dataService: DataService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.loadfundForm()
    this.getCodes()
    this.getCurrencies()
    if(this.selectedRow) {
      this.fund = this.selectedRow
      this.fund['longame'] = this.selectedRow['longName']
      this.fund['listedat'] = (this.selectedRow['listedAt'] && this.selectedRow['listedAt'] !== undefined && this.selectedRow['listedAt'] !== '') ? this.selectedRow['listedAt'] : null
      this.fund['assetClass'] = (this.selectedRow['assetClass'] && this.selectedRow['assetClass'] !== undefined && this.selectedRow['assetClass'] !== '') ? this.selectedRow['assetClass'] : null
      this.fund['productType'] = (this.selectedRow['productType'] && this.selectedRow['productType'] !== undefined && this.selectedRow['productType'] !== '') ? this.selectedRow['productType'] : null
      this.fund['currencyId'] = (this.selectedRow['currencyId'] && this.selectedRow['currencyId'] !== undefined && this.selectedRow['currencyId'] !== '') ? this.selectedRow['currencyId'] : null
      this.fund.terminatedDate = this.selectedRow['terminatedDate'] ? this.datePipe.transform(this.selectedRow['terminatedDate'], 'dd MMM YYYY '):null
    }
  }


  loadfundForm() {
    this.funfForm = new FormGroup({
      "longame": new FormControl(''),
      "assetClass": new FormControl(''),
      "productType": new FormControl(''),
      "listedat": new FormControl(''),
      "currencyId": new FormControl(''),
      "tickerName": new FormControl(''),
      "issuerName": new FormControl(''),
      "isActive": new FormControl('',Validators.required),
      "terminatedDate": new FormControl(''),
    })
    if(this.fund.isActive == true) {
      this.funfForm.controls['terminatedDate'].setValidators(Validators.required);
      this.funfForm.controls['terminatedDate'].updateValueAndValidity()
    } else {
      this.funfForm.controls['terminatedDate'].clearValidators();
      this.funfForm.controls['terminatedDate'].updateValueAndValidity()
    }
  }

  getCodes() {
    this.defaultService.mrdfind({ queryParams: {class_type:{in:['CCASS_ASSET_CLASS','CCASS_PRODUCT_TYPES','STOCK_MARKETS']}}, orderByParams: { class_type: 'asc', code: 'asc' } }, 1, 1000, `common/codes`).then((data: any) => {
      if (data && data.status === 'success') {
        data.data.forEach((obj: any) => {
            if(obj.class_type == 'CCASS_ASSET_CLASS') {
              this.assetClassOpts.push({ label: obj['code_desc'], value: obj['code'] });
              this.assetClassOptions = this.assetClassOpts
            }
            else if(obj.class_type == 'CCASS_PRODUCT_TYPES') {
              this.productTypeOpts.push({ label: obj['code_desc'], value: obj['code'] });
              this.products = this.productTypeOpts
            } else if(obj.class_type == 'STOCK_MARKETS') {
              this.listedatOpts.push({ label: obj['code_desc'], value: obj['code'] });
              this.lists = this.listedatOpts
            } 
        });  
        this.loading = false;
      }
      else {
        this.messageService.add({ severity: 'error', detail: data['status'] });
        this.loading = false;
      }
    }).catch((err: any) => {
     this.loading = false;
    //  this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
    });
  }

  getCurrencies() {
    const url = 'v1/currencies'
    this.ccassService.ccassfindOne('',url).then(response => {
      if (response['status'] && response['status'] === 'success') {
        response['data'].forEach((obj:any) => {
          this.currencyOpts.push({ label: obj['currencyName'], value: obj['id'] });
          this.currencies = this.currencyOpts  
        })
        this.loading = false;
      } else {
        this.loading = false;
        // handle failure
      }
    }).catch(err => {
        this.loading = false;
      // handle error
    }); 
  }

  resetNclose() {
    this.isDisplay = false;
    this.getFundData.emit();
    this.isDisplayChange.emit(this.isDisplay); 
  }

  resetNcloseFRSuccess(message:any) {
    this.isDisplay = false;
    this.shoepopupInParent.emit({message});
    this.isDisplayChange.emit(this.isDisplay); 
  }

  addFund() {
    const formData = { ...this.funfForm.value };
    const form = formData
    form['longName'] = formData['longame']
    form['listedAt'] = formData['listedat']
    form['ccassTickerId'] = this.selectedRow.ccassTickerId
    form['fundId'] = this.selectedRow.fundId
    form['issuerId'] = this.selectedRow.issuerId
    delete form['longame']
    delete form['listedat']
    form['terminatedDate'] = form['terminatedDate'] ? this.datePipe.transform(form['terminatedDate'], 'yyyy-MM-dd') + 'T00:00:00.000Z' : null;
    // if (form['listedAt'] == null || form['listedAt'] == undefined) delete form['listedAt'];
    // if (form['assetClass'] == null || form['assetClass'] == undefined) delete form['assetClass'];
    // if (form['productType'] == null || form['productType'] == undefined) delete form['productType'];
    // if (form['currencyId'] == null || form['currencyId'] == undefined) delete form['currencyId'];
    // if (this.fund['terminatedDate'] == null || this.fund['terminatedDate'] == undefined) delete form['terminatedDate'];
    if(this.btnName === 'UPDATE') {
      const url = 'v1/fund/update/' + this.selectedRow['fundId']
      this.ccassService.ccassUpdateTeam(formData,url).then(data => {
        if(data['status'] == 'success') {
          this.resetNcloseFRSuccess(data['message']);
        } else {
          this.messageService.add({ severity: 'error', detail: data['message'] ? data['message'] : data['status'] });
          this.loading = false;
        }
      }).catch(err => {
        this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
        // this.resetNclose();
        this.loading = false;
      })
    }
  }
  activeClick(event:any,value:any) {
    if(value == true) {
      this.funfForm.controls['terminatedDate'].setValue(null);
      this.funfForm.controls['terminatedDate'].clearValidators();
    } else if(value == false) {
      this.funfForm.controls['terminatedDate'].setValidators(Validators.required);
    }
    this.funfForm.controls['terminatedDate'].updateValueAndValidity()
  }

}
