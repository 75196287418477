import { Component, Input, OnInit, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import { DatePipe } from '@angular/common';
import { thousands_separators, thousands_separatorsd } from '../../utils/utilities.function';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { DefaultService } from '../../../app/utils/default.service';
import { MessageService } from 'primeng/api';

@Component({
   selector: 'app-merge-table',
   templateUrl: './merge-table.component.html',
   styleUrls: ['./merge-table.component.scss']
})
export class MergeTableComponent implements OnInit {
   @Input() isLoading: boolean;
   @Input() tableSchema: any;
   @Input() tableData: any;
   @Output() editInParent = new EventEmitter();
   @Input() headerSelectOptions: any;
   @Input() selectedRows
   rowGroupMetadata: any = {};
   @Output() updateInParent = new EventEmitter();
   @Output() updateParentData = new EventEmitter();
   @ViewChild(Calendar, { static: false }) calendar: Calendar;
   copyTableData: any = [];
   @Input() source: any;
   @Input() reconType: any;
   @Output() filteredFundsCount = new EventEmitter();
   @Output() showStatusReport = new EventEmitter();
   @Output() editInCash = new EventEmitter();
   @Output() editInParentRow = new EventEmitter();
   @Output() editCell = new EventEmitter();
   @Output() editDcCell = new EventEmitter();
   @Output() deleteFromParent = new EventEmitter();
   @Output() changeIPSwitch = new EventEmitter()
   editdc: boolean;
   check: any;
   inKindOptions: any=[]
   typeOptions: any=[]
   dropoptions: any=[]
   target: any;
   status: any;

   constructor(
      private datePipe: DatePipe,
      private router: Router,
      public defaultService: DefaultService,
      public messageService:MessageService
   ) { }

   opts = [{ label: 'hi', value: 'hi' }]


   ngOnInit() {
      this.selectedRows = []
      localStorage.removeItem('tablefilters');
      localStorage.removeItem('dateObj');
      localStorage.setItem('selectedRows', JSON.stringify([]));
      this.editdc = false
      this.copyTableData = [...this.tableData];
      if (this.tableData?.length > 0) {
         this.updateRowGroupMetaData(this.tableData);
      }
      this.getDropdownOptions()
   }

   

   onEditInit(event): void {
      this.check = null
      if(event.field == 'crCharges') {
         this.check = event.data.crCharges
         this.status = event.data.signOffStatus
      }
      else if(event.field == 'adjustmentFactor'){
         this.check =  event.data.adjustmentFactor
         this.status = event.data.signOffStatus
      }
      else if(event.field == 'threshold_breach_comments'){
         this.check = event.data.threshold_breach_comments
         this.status = event.data.sign_off_status == 'N' ? 'PEND' : ' SIGNED-OFF';
      }
      else if(event.field == 'dcCalcType') {
         this.check = event.data.dcCalcType
         this.status = event.data.signOffStatus
      } 
      else if(event.field == 'inKindDepo') {
         this.check = event.data.inKindDepo
         this.status = event.data.signOffStatus
      }

   }

   onEditCancel(): void {

   }

   onEditComplete(event: any) {
      if(this.status == 'PEND') {
      this.target = event.originalEvent.target.toString()
      if (event.field === 'inKindDepo') {
         if(this.check !== event.data.inKindDepo) {
            this.editCell.emit({ data: event.data })
         }
         else if(this.check == event.data.inKindDepo) {
            if(this.check == null || this.check == "") {
               if(this.target == '[object HTMLSpanElement]' ||this.target == ' [object HTMLLIElement] ') {
                  const reqValue = this.dropoptions[0].value
                  event.data['inKindDepo'] = reqValue
                  this.editCell.emit({ data: event.data })
               }
            }
         }
      }
      if (event.field === 'dcCalcType') {
         if(this.check !== event.data.dcCalcType) {
            this.editCell.emit({ data: event.data })
         }
         else if(this.check == event.data.dcCalcType) {
            if(this.check == null || this.check == "") {
               if(this.target == '[object HTMLSpanElement]' ||this.target == ' [object HTMLLIElement] ') {
                  const reqValue = event.data.dcCalcTypeArray[0].value
                  event.data['dcCalcType'] = reqValue
                  this.editCell.emit({ data: event.data })
               }
            }  
         }
      }
      else if(event.field === 'crCharges') {
         if(this.check !== event.data.crCharges) {
            this.editCell.emit({ data: event.data })
         }  
      }
      else if(event.field === 'adjustmentFactor'){
         if(this.check !== event.data.adjustmentFactor) {
            this.editCell.emit({ data: event.data })
         }  
      }
     }
      if(event.field === 'threshold_breach_comments'){
         if(this.check !== event.data.threshold_breach_comments){
            this.editCell.emit({data:event.data})
         }
      }
}

   ngOnChanges(tableData: any) {
      if(tableData.tableData) {
         this.copyTableData = [...tableData.tableData.currentValue];
      } 
      this.selectedRows = JSON.parse(localStorage.getItem('selectedRows')) || []
   }

   thousandSeparators(value) {
      return thousands_separators(value);
   }

   thousandSeparatorsDecimal(value) {
      return thousands_separatorsd(value);
   }

   onClickCashComp(file) {
      let selectedFund = [];
      selectedFund.push(file);
      localStorage.setItem('selectedRows', JSON.stringify(selectedFund));
      this.editInCash.emit(file);
   }

   onClickStatus(fileStatus) {
      if (fileStatus.length === 0) {
         return;
      }
      this.showStatusReport.emit(fileStatus);
   }

   calculateCount(list) {
      if (this.reconType == 'D&C') {
         const fList = [];
         list?.forEach((d: any) => {
            if (d.orderId) { fList.push(d.orderId); }
         });
         const finalList = [...new Set(fList.map(item => item))];
         return finalList.length;
      }
      else {
         const fList = [];
         list?.forEach((d: any) => {
            if (d.tradar_code) { fList.push(d.tradar_code); }
         });
         const finalList = [...new Set(fList.map(item => item))];
         return finalList.length;
      }
   }

   onFilter(e: any) {
      this.isLoading = true;
      this.filteredFundsCount.emit(this.calculateCount(e.filteredValue));
      this.updateRowGroupMetaData(e.filteredValue);
      this.updateInParent.emit(e.filteredValue);
   }

   tableOnfilter(e, val) {
      let tablefilters = JSON.parse(localStorage.getItem('tablefilters')) || {};
      let records;
      val = val.toString();
      tablefilters[val] = e;
      if (!tablefilters?.tradar_code && !tablefilters?.ticker) records = this.copyTableData;
      else if (tablefilters?.tradar_code && tablefilters?.ticker) records = this.copyTableData?.filter(v => v.tradar_code == tablefilters?.tradar_code && v.ticker == tablefilters?.ticker);
      else if (tablefilters?.tradar_code && !tablefilters?.ticker) records = this.copyTableData?.filter(v => v.tradar_code == tablefilters?.tradar_code);
      else if (!tablefilters?.tradar_code && tablefilters?.ticker) records = this.copyTableData?.filter(v => v.ticker == tablefilters?.ticker);
      this.tableData = [...records];
      if (this.tableData?.length > 0) this.updateRowGroupMetaData(this.tableData);
      localStorage.setItem('tablefilters', JSON.stringify(tablefilters));
   }

   updateRowGroupMetaData(data: any) {
      if (this.source == 'tdte' || this.source == 'tdthreshold') {
         this.rowGroupMetadata = {};
         if (data) {
            for (let i = 0; i < data.length; i++) {
               const rowData = data[i];
               const typeVal = rowData.fund_type;
               if (i == 0) {
                  this.rowGroupMetadata[typeVal] = { index: 0, size: 1 };
               } else {
                  const previousRowTypeVal = data[i - 1];
                  const previousRowGroupTypeVal = previousRowTypeVal.fund_type;
                  if (typeVal === previousRowGroupTypeVal) {
                     this.rowGroupMetadata[typeVal].size++;
                  } else {
                     this.rowGroupMetadata[typeVal] = { index: i, size: 1 };
                  }
               }
            }
         }
      } else if (this.reconType == 'D&C') {
         this.rowGroupMetadata = {};
         if (data) {
            for (let i = 0; i < data.length; i++) {
               const rowData = data[i];
               const fundId = rowData.orderId;
               if (i == 0) {
                  this.rowGroupMetadata[fundId] = { index: 0, size: 1 };
               } else {
                  const previousRowFundId = data[i - 1];
                  const previousRowGroupFundId = previousRowFundId.orderId;
                  if (fundId === previousRowGroupFundId) {
                     this.rowGroupMetadata[fundId].size++;
                  } else {
                     this.rowGroupMetadata[fundId] = { index: i, size: 1 };
                  }
               }
            }
         }
      } else {
         this.rowGroupMetadata = {};
         if (data) {
            for (let i = 0; i < data.length; i++) {
               const rowData = data[i];
               const fundId = rowData.fund_id;
               if (i == 0) {
                  this.rowGroupMetadata[fundId] = { index: 0, size: 1 };
               } else {
                  const previousRowFundId = data[i - 1];
                  const previousRowGroupFundId = previousRowFundId.fund_id;
                  if (fundId === previousRowGroupFundId) {
                     this.rowGroupMetadata[fundId].size++;
                  } else {
                     this.rowGroupMetadata[fundId] = { index: i, size: 1 };
                  }
               }
            }
         }
      }
      this.isLoading = false;
   }

   getMeToFileInfo(file: any) {
      let selectedFund = [];
      selectedFund.push(file);
      localStorage.setItem('selectedRows', JSON.stringify(selectedFund));
      this.editInParent.emit(file);
   }

   customSort(e: any) {
      if (this.source == 'tdte') {
         this.tableData.sort((a, b) => {
            if (a[e.field] < b[e.field]) {
               return -1 * e.order;
            } else if (a[e.field] > b[e.field]) {
               return 1 * e.order;
            } else {
               return 0;
            }
         });
         this.tableData.sort((a, b) => {
            if (a['fund_type'] < b['fund_type']) {
               return -1 * e.order;
            } else if (a['fund_type'] > b['fund_type']) {
               return 1 * e.order;
            } else {
               return 0;
            }
         });
         this.tableData = [...this.tableData];
         this.updateRowGroupMetaData(this.tableData);
      } else {
         this.tableData.sort((a, b) => {
            if (a[e.field] < b[e.field]) {
               return -1 * e.order;
            } else if (a[e.field] > b[e.field]) {
               return 1 * e.order;
            } else {
               return 0;
            }
         });
         this.tableData = [...this.tableData];
         this.updateRowGroupMetaData(this.tableData);
      }
   }

   onSelectRow() {
      let newList = []
      if (this.source == 'tdte') {
         this.selectedRows.forEach(element => {
            this.tableData.forEach(element1 => {
               if (element.account_summary_id === element1.account_summary_id) {
                  newList.push(element1)
               }
            })
         })
      } else if (this.reconType == 'D&C') {
         this.selectedRows.forEach(element => {
            this.tableData.forEach(element1 => {
               if (element.orderId === element1.orderId) {
                  newList.push(element1)
               }
            })
         })
      } else if (this.source == 'nav1' || this.source == 'nav2' || this.source == 'pdcc') {
         this.selectedRows.forEach(element => {
            this.tableData.forEach(element1 => {
               if (element.fund_id === element1.fund_id) {
                  newList.push(element1);
               }

            });
         });
      }
      this.updateInParent.emit([...newList]);
      localStorage.setItem('selectedRows', JSON.stringify([...newList]));
   }

   onRowUnselect(row) {
      let list = []
      this.selectedRows = JSON.parse(localStorage.getItem('selectedRows'));
      if (this.source == 'tdte') {
         list = this.selectedRows.filter(obj => row.data.account_summary_id !== obj.account_summary_id)
      } else if (this.reconType == 'D&C') {
         list = this.selectedRows.filter(obj => row.data.orderId !== obj.orderId)
      } else if (this.source == 'nav1' || this.source == 'nav2' || this.source == 'pdcc') {
         list = this.selectedRows.filter(obj => row.data.tradar_code !== obj.tradar_code);
      }
      this.selectedRows = [...list];
      localStorage.setItem('selectedRows', JSON.stringify(this.selectedRows));
      this.updateInParent.emit(this.selectedRows);
   }

   selectAllRows() {
      localStorage.setItem('selectedRows', JSON.stringify(this.selectedRows));
      this.updateInParent.emit(this.selectedRows);
   }

   onSelectDate(date, field) {
      let filteredDt = [];
      if (date) {
         const dt = this.datePipe.transform(date, 'dd MMM YYYY');
         filteredDt = this.copyTableData.filter((d: any) => {
            const signOffDate = this.datePipe.transform(d[field], 'dd MMM YYYY');
            if (signOffDate == dt) {
               return d;
            }
         });
         let dateObj = JSON.parse(localStorage.getItem('dateObj')) || {};
         dateObj[field] = dt;
         localStorage.setItem('dateObj', JSON.stringify(dateObj));
         this.tableData = filteredDt;
         this.updateRowGroupMetaData(this.tableData);
      }
   }

   onClickClose(field) {
      let dateObj = JSON.parse(localStorage.getItem('dateObj')) || {};
      delete dateObj[field]
      localStorage.setItem('dateObj', JSON.stringify(dateObj));
      this.updateParentData.emit();
      this.tableData = [...this.copyTableData];
      this.updateRowGroupMetaData(this.tableData);
   }

   onClickEdit(row, index) {
      this.editInParentRow.emit({ row: row, index: index });
   }

   onDeleteClick(data: any, index: any) {
      this.deleteFromParent.emit({ row: data, index: index });
   }

   changeInputSwitch(event, id, rowData) {
      this.changeIPSwitch.emit({ event: event, id: id, rowData: rowData });
   }
   getAbsValue(value) {
      const x = value  ? value * 100 : 0
      return x;
   }
   getDropdownOptions() {
      this.defaultService.dcfindEn({ queryParams: { "category": { "equals": ["Depo"] } }, orderByParams: { category: 'asc', code: 'asc' } }, 1, 1000, `common/enums/getall`).then((data: any) => {
        if (data && data.status === 'success') {
          const enumType = data.data;
          enumType.forEach(element => {
            this.inKindOptions.push(element)
            this.typeOptions.push({ label: element['code_desc'], value: element['code'] });
            this.dropoptions = this.typeOptions
          });
          this.dropoptions.unshift({ label: 'None', value: null })
        }
        else {
          this.messageService.add({ severity: 'error', detail: data.status });
         //  this.loading = false;
        }
      }).catch((err: any) => {
      //   this.loading = false;
        this.messageService.add({ severity: 'error', detail: err });
      }
      );
    }
}