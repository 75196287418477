import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AuthService } from '../../../shared/auth.service';
import { ApiService } from '../../../shared/api.service';
import { DefaultService } from '../../../utils/default.service';
import { CcassService } from '../../ccass.service';

@Component({
  selector: 'app-upload-share-holding',
  templateUrl: './upload-share-holding.component.html',
  styleUrls: ['./upload-share-holding.component.scss']
})
export class UploadShareHoldingComponent implements OnInit {

  @Input() isDisplay = false;
  @Output() isDisplayChange = new EventEmitter<boolean>()
  @Output() getshareHolidingsData = new EventEmitter();
  loading: boolean;
  fileName: any;
  formData: any;
  disableButton: boolean = false

  constructor(
    private ccassService: CcassService,
    private apiService: ApiService,
    private defaultService:DefaultService,
    private messageService: MessageService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
  }

  resetNclose() {
    this.isDisplay = false;
    this.isDisplayChange.emit(this.isDisplay); 
  }

  addHoliday() {
    this.disableButton = true
    const url = 'v1/upload'
    this.ccassService.ccassUpload(this.formData, url).then(response => {
      if (response['status'] === 'success') {
        this.messageService.add({ severity: 'success', detail: response['message'] });
        this.getshareHolidingsData.emit();
        this.disableButton = false
        this.resetNclose();
      } else {
        this.disableButton = false
        this.messageService.add({ severity: 'error', detail: response['message'] ? response['message'] : response['status'] });
        this.loading = false;
      }
    }).catch(err => {
      this.disableButton = false
      this.resetNclose();
      this.loading = false;
      this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
    });
    
  }

  uploadfile(event) { 
    const currentUser = this.authService.currentUser();
    const user = currentUser.loginName
    const aeFile = event.target.files[0];
    if (aeFile?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      const reader = new FileReader();
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.fileName = event.target.files[0].name;
        };
        const formData: any = new FormData();
        formData.append("file", aeFile);
        formData.append("versionName", user);
        this.formData = formData
      }
    } else {
      this.messageService.add({ severity: 'error', detail: "Unsupported file type.please upload .xlsx file " });
    }
  }

  removeFile() {
    this.fileName = null
  }
}
